import React from 'react';
import { Suspense } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import ReactErrorBoundary from 'components/common/ErrorBoundary';
import AppStateProvider from './context/AppStateProvider';
import containers from './context/index';
import Loader from 'components/Loader';
import Routing from 'Routing';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
        <div className=''>
            <BrowserRouter>
                <ReactErrorBoundary>
                    <AppStateProvider containers={containers}>
                        <Suspense fallback={<Loader isLoading={true} />}>
                            <Routing />
                            <ToastContainer autoClose={2000}
                                position="top-center" />
                        </Suspense>
                    </AppStateProvider>
                </ReactErrorBoundary>
            </BrowserRouter>
        </div>
    );
}

export default App;
