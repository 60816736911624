/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState,useMemo,useCallback,Suspense } from 'react';


import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import { usePagination } from 'hooks/paginate/usePagination';
import { Link } from 'react-router-dom';
import AddProjectModal from 'components/common/modals/AddProject';
import Popover from 'components/Popover';
import { fetchProjectList } from "services/project";
import InviteCollaborator from "components/common/modals/InviteCollabrators";
import SearchBar from 'components/common/SearchBar';
import CustomCheckBox from 'components/CustomCheckBox';
import Paginate from "components/Paginate";

function Projects() {
    const projectColumns = [
        {
            name: 'Project Name',
            ref: 'projectName',
            hide: false,
            position: 1,
        },
        {
            name: 'Type',
            ref: 'type',
            hide: false,
            position: 2,
        },
        {
            name: 'Claim Charts',
            ref: 'claimCharts',
            hide: false,
            position: 3,
        },
        {
            name: 'Last Update',
            ref: 'lastUpdate',
            hide: false,
            position: 4,
        },
        {
            name: 'Team',
            ref: 'team',
            hide: false,
            position: 5,
        },
        {
            name: 'Action',
            ref: 'action',
            hide: false,
            position: 6,
        },
    ];

    let projectData = [
        {
            id: 0,
            projectName: 'Device holder',
            type: 'Find Prior Art',
            claimCharts: null,
            lastUpdate: '04/05/2024',
            team: [
                { profile_image: 'https://randomuser.me/api/portraits/men/36.jpg',user_name: 'John Doe',user_email: '' },
                { profile_image: 'https://randomuser.me/api/portraits/men/34.jpg',user_name: 'Jane Smith',user_email: '' },
                { profile_image: 'https://randomuser.me/api/portraits/men/35.jpg',user_name: 'Mike Johnson',user_email: '' },
            ],
            description: 'Cover and grip for handheld devices for lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ',
        }

    ];
    let totalItems = projectData.length;
    const [showMenu,setshowMenu] = useState(false);
    const [sortOrder,setSortOrder] = useState('asc');
    const [arrowUp,setArrowUp] = useState(false);
    const [sortField,setSortField] = useState<any>(null);
    const [refreshData,setRefreshData] = useState(false);
    const [paginatedResponse,setPaginatedResponse] = useState({
        // page: "1",
        // perPage: "5",
        // total: 0,
        // totalPages: 1
    });


    // setOpenInviteCollaborators
    const [openInviteCollaborators,setOpenInviteCollaborators] = useState(false);
    const [openCreateProjectModal,setOpenCreateProjectModal] = useState(false);
    const [selectedProjectId,setSelectedProjectId] = useState(0);
    const [dataLength,setDataLength] = useState(1);

    const [search,setSearch] = useState('')
    const [toastConteiner,setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });

    const [paginatedData,setPaginatedData] = useState<any[]>([]);
    const [selectedItems,setSelectedItems] = useState<number[]>([]);
    const [selectAll,setSelectAll] = useState(false);
    const userData: any = JSON.parse(localStorage.getItem('userData') as string);
    let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);
    const Shimmer: React.FC = () => {
        return (
            <div className="h-10 w-[42px] flex gap-x-3">
                <div className="rounded-md w-[42px] bg-gray-100"></div>
            </div>
        );
    };
    const projectLimit = 10;
    const { currentPage,totalPages,startItem,endItem,nextPage,prevPage,currentData } = usePagination({
        totalItems: dataLength,
        itemsPerPage: projectLimit,
        initialPage: 1,
    });

    // Update paginatedData whenever currentPage changes
    useEffect(() => {

        setTimeout(() => {
            (async () => {
                selectedTeam = JSON.parse(localStorage.getItem('selectedTeam') as string);
                let apiData: any = await projectAPIdata();
                const newData = currentData(apiData);
                setPaginatedData(newData);
                setSelectedItems([]);
                setSelectAll(false);


            })();
        },1500);
    },[currentPage,refreshData]);

    const formatDate = (dateString: string) => {
        try {
            // Create a Date object from the input
            const date = new Date(dateString);

            // Check if the date is valid
            if (isNaN(date.getTime())) {
                return null;
            }

            // Pad single digits with leading zero
            const pad = (num: number) => num.toString().padStart(2,'0');

            // Format to dd/mm/yyyy
            return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
        } catch (error) {
            return null;
        }
    };

    const projectAPIdata = async () => {

        let projectAPIdata = await fetchProjectList(currentPage,projectLimit,selectedTeam?.currentTeamId);

        let apiProjectData: any = projectAPIdata.data.projects;

        if (projectAPIdata.data.pagination.total > 0) {
            setPaginatedResponse(projectAPIdata.data.pagination);
            setDataLength(projectAPIdata.data.pagination.total);

        }

        const myData = [{
            profile_image: userData.profile_image,
            user_email: userData.name,
            user_name: userData.name
        }]


        let filteredData = apiProjectData.map((data: any) => {
            return {
                id: data._id,
                projectName: data.name,
                type: data.type,
                claimCharts: data.claim_charts || 0,
                lastUpdate: formatDate(data.updatedAt) || data.updatedAt,
                lastUpdateBy: 'Andy Millar',
                team: [...myData,...data.teams],
                description: data.description
            }
        })

        return filteredData;


    }

    const handleSelectAll = useCallback(
        (isChecked: boolean) => {

            setSelectAll(isChecked);
            if (isChecked) {

                // Select all items in the current page
                const currentPageIds = paginatedData.map((_,index) => index);
                setSelectedItems(currentPageIds);
            } else {
                // Deselect all items
                setSelectedItems([]);
            }
        },
        [paginatedData]
    );

    const handleSelectItem = useCallback(
        (index: number) => {
            // if (event.target.checked) {
            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev,index];

                setSelectAll(newSelected.length === paginatedData.length);
                return newSelected;
                // Update selectAll state based on if all items in current page are selected
            });
            // }

        },
        [paginatedData]
    );

    const handlePrev = () => {
        prevPage();
    };

    const handleNext = () => {
        nextPage();
    };

    const handleSort = (type: string) => {
        const sortedData = [...paginatedData].sort((a,b) => {
            if (type === 'lastUpdate') {
                const dateA: any = new Date(a.lastUpdate);
                const dateB: any = new Date(b.lastUpdate);
                return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (type === 'claimCharts') {

                return sortOrder === 'asc' ? (a[type] + '' || '0').localeCompare(b[type] + '' || '0') : (b[type] + '' || '0').localeCompare(a[type] + '' || '0');
            } else {
                return sortOrder === 'asc' ? a[type].localeCompare(b[type]) : b[type].localeCompare(a[type]);
            }
        });

        setPaginatedData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setArrowUp(!arrowUp);
        setSortField(type);
    };

    const handleMenuClick = () => {
        setshowMenu(!showMenu);
    };

    const createColumnClassName = (field: string) => {
        if (field === 'projectName') {
            return 'w-[27%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
        if (field === 'type') {
            return 'w-[18%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
        if (field === 'claimCharts') {
            return 'w-[13%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
        if (field === 'lastUpdate') {
            return 'w-[12%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
        if (field === 'team') {
            return 'w-[15%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
        if (field === 'action') {
            return 'w-[15%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6';
        }
    };

    const openAddProjectModal = () => {
        setOpenCreateProjectModal(true);
    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
    }
    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />

            {/* dashboard main */}
            <div className='w-[calc(100%_-_71px)] ml-auto'>
                {/* Hedaer */}
                {/*  */}
                <Header title='Projects' count={dataLength || 0} isChildEle={false} />
                {/* paginatedResponse?.total || */}

                <div className=''>
                    <div className='bg-white w-full'>
                        <div className='flex justify-between px-4 py-3.5 items-center'>
                            <SearchBar
                                img_url={allImgPaths.searchIcon}
                                onChange={handleSearchChange}
                                width={'w-96'}
                                placeholder={'Search project with ID or description'}
                            />
                            <div className='flex items-center'>
                                <Suspense fallback={<Shimmer />}>
                                    <button className={`!text-sm text-white bg-[#1751D0] hover:bg-[#120693] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5`} onClick={openAddProjectModal}>
                                        <img className='w-4' src={allImgPaths.plusIconWhite} alt="" />
                                        Add New
                                    </button>
                                </Suspense>
                                {/* <span className='w-4 inline-block mr-0.5 mb-px'>
                                    <img className={`cursor-pointer ml-auto`} src={allImgPaths.threeVerticleDotsIcon} onClick={handleMenuClick} alt='' />
                                </span> */}
                                {
                                    false && (
                                        <div className='w-4 flex-shrink-0 flex items-center justify-center py-1.5 px-6 mr-0.5 mb-px'>
                                            <Popover
                                                classes='!p-0'
                                                trigger={
                                                    <span className='select-none hover:bg-status-brand/20 rounded-md cursor-pointer w-10 h-10 flex justify-center items-center duration-200 gap-x-1'>
                                                        <img className='hover:bg-[#f2f4f7] rounded w-7 h-7 p-1' src={allImgPaths.verticleDotsGrey} alt='' />
                                                    </span>
                                                }
                                                content={
                                                    // 
                                                    <></>
                                                }
                                            />
                                        </div>
                                    )
                                }
                                {showMenu && <div></div>}
                            </div>
                        </div>

                        <div className='w-full'>
                            <div className=''>
                                <div className='overflow-y-auto h-[calc(100vh_-_228px)] bg-white'>
                                    {/* Tabs Head */}
                                    <div className='flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]'>
                                        {projectColumns?.length > 0 && paginatedData.length > 0 &&
                                            projectColumns.map((column: any,columnIndex: number) => (
                                                <React.Fragment key={columnIndex}>
                                                    {
                                                        <div className={createColumnClassName(column.ref)}>
                                                            {/* To manage sort icon and column width */}
                                                            {/* ProjectName */}
                                                            {column.ref === 'projectName' && (
                                                                <div className='flex items-center justify-start gap-x-2 pt-0.5'>
                                                                    <div className="flex items-center gap-x-1 cursor-pointer" onClick={() => handleSort(column.ref)}>
                                                                        <span className='font-interMedium text-sm text-[#0A090B]'>
                                                                            {column.name}
                                                                        </span>

                                                                        <img
                                                                            className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                            onClick={() => handleSort(column.ref)}
                                                                            src={allImgPaths.arrowUp3}
                                                                            alt=''
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )}

                                                            {/* Type */}
                                                            {column.ref === 'type' && (
                                                                <div className='flex items-center justify-start gap-x-2 pt-0.5'>
                                                                    <span className='cursor-pointer font-interMedium text-sm text-[#0A090B]' onClick={() => handleSort(column.ref)}>
                                                                        {column.name}
                                                                    </span>
                                                                    <img
                                                                        className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                        onClick={() => handleSort(column.ref)}
                                                                        src={allImgPaths.arrowUp3}
                                                                        alt=''
                                                                    />
                                                                </div>
                                                            )}

                                                            {/* Claim Charts */}
                                                            {column.ref === 'claimCharts' && (
                                                                <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                                    <span className='cursor-pointer font-interMedium text-sm whitespace-nowrap text-[#0A090B]' onClick={() => handleSort(column.ref)}>
                                                                        {column.name}
                                                                    </span>
                                                                    <img
                                                                        className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                        onClick={() => handleSort(column.ref)}
                                                                        src={allImgPaths.arrowUp3}
                                                                        alt=''
                                                                    />

                                                                </div>
                                                            )}

                                                            {/* Last Update */}
                                                            {column.ref === 'lastUpdate' && (
                                                                <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                                    <span className='cursor-pointer font-interMedium whitespace-nowrap text-sm text-[#0A090B]' onClick={() => handleSort(column.ref)}>
                                                                        {column.name}
                                                                    </span>
                                                                    <img
                                                                        className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                        onClick={() => handleSort(column.ref)}
                                                                        src={allImgPaths.arrowUp3}
                                                                        alt=''
                                                                    />
                                                                </div>

                                                            )}

                                                            {/* Team */}
                                                            {column.ref === 'team' && <div className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</div>}

                                                            {/* Action */}
                                                            {column.ref === 'action' && <div className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</div>}
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            ))}
                                    </div>

                                    {/* Body */}
                                    {
                                        <div className={'h-auto'}>
                                            {paginatedData.length > 0 &&
                                                paginatedData.map((row: any,rowIndex: number) => (
                                                    <React.Fragment key={rowIndex}>
                                                        <div className={`flex`}>
                                                            {projectColumns?.length > 0 &&
                                                                projectColumns.map((column: any,columnIndex: number) => (
                                                                    <React.Fragment key={columnIndex}>

                                                                        {column.ref === 'projectName' && (
                                                                            <div className={`${createColumnClassName(column.ref)} table-row !justify-start`}>
                                                                                <div className=''>

                                                                                    <label htmlFor={'item' + rowIndex} className='flex items-center gap-x-2'>
                                                                                        <div className=''>
                                                                                            <Link className='font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block smoothness' to={'/project/' + row.id + '/find-covered-product'}>

                                                                                                <div className='text-[#0A090B] font-interMedium text-sm pb-0.5'>{row[column.ref]}</div>
                                                                                            </Link>
                                                                                            <div title={row.description} className='2xl:max-w-80 max-w-64 truncate text-[#7F7D83] font-interMedium text-sm'>{row.description}</div>
                                                                                        </div>
                                                                                    </label>

                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        {column.ref === 'type' && (
                                                                            <div className={`${createColumnClassName(column.ref)} flex items-center`}>
                                                                                <span title={row[column.ref]} className='text-sm text-[#4F4D55] font-interRegular truncate max-w-60'>
                                                                                    {row[column.ref]}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {column.ref === 'claimCharts' && (
                                                                            <div className={`${createColumnClassName(column.ref)} flex items-center pl-16`}>
                                                                                <span className="font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block smoothness">{row[column.ref]}</span>
                                                                                {/* <span className='text-sm text-[#4F4D55] font-interRegular'>
                                                                                    {!row[column.ref] && 'N/A'}
                                                                                </span> */}
                                                                            </div>
                                                                        )}

                                                                        {/* lastUpdate */}
                                                                        {column.ref === 'lastUpdate' && (
                                                                            <div className={`${createColumnClassName(column.ref)}  items-center`}>
                                                                                <span className='text-sm text-[#4F4D55] font-interRegular'> {row[column.ref]} </span>
                                                                                <span className='text-sm text-[#ADACB0] font-interRegular'> {row.lastUpdateBy} </span>

                                                                            </div>
                                                                        )}

                                                                        {column.ref === 'team' && (
                                                                            <div className={`${createColumnClassName(column.ref)} flex items-center`}>
                                                                                {row[column.ref].slice(0,3).map((item: any,index: number) => (
                                                                                    // <img src={item.profile_image || allImgPaths.defaultProfile} key={columnIndex + index + '-team'} alt='' height={28} width={28} className='rounded-full cursor-pointer on-hover-border-shadow -ml-2' />
                                                                                    <div key={index} className="w-8 h-8 rounded-full -ml-2 border-2 border-[#fff] first:ml-0 relative group">
                                                                                        <img key={index} className='w-full h-full rounded-full on-hover-border-shadow' src={item.profile_image || allImgPaths.defaultProfile} alt="" />
                                                                                        <div className="bg-[#0C111D] rounded-lg py-2 px-3 min-w-[122px] absolute z-10 top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block cursor-default">
                                                                                            <span className="arrow-top w-0 h-0 block absolute left-1/2 transform -translate-x-1/2 -top-2"></span>
                                                                                            <ul className="text-center">
                                                                                                <li className="text-xs text-white pb-1 last:pb-0" key={item?._id}>
                                                                                                    {item?.user_name || item?.user_email}
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                }
                                                                                {row[column.ref].length > 3 && (
                                                                                    <div className="w-7 h-7 rounded-full -ml-3 bg-[#dfe2e5] flex items-center justify-center text-sm relative group cursor-pointer">
                                                                                        +{row.assigned_to?.slice(3).length}
                                                                                    </div>
                                                                                )}
                                                                                <img
                                                                                    src={allImgPaths.plusIcon}
                                                                                    key={columnIndex + 'plus-team-'}
                                                                                    onClick={() => { setOpenInviteCollaborators(true); setSelectedProjectId(row.id) }}
                                                                                    alt=''
                                                                                    className=' p-1 rounded-full cursor-pointer !border !border-dashed !border-[#ccc] on-hover-border-shadow h-6 w-6 -ml-2 bg-white'
                                                                                />
                                                                            </div>
                                                                        )
                                                                        }
                                                                        {
                                                                            column.ref === 'action' && (
                                                                                <div className={`${createColumnClassName(column.ref)} flex items-center gap-x-3`}>
                                                                                    <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.eyeIcon} alt='' key={'eye-' + columnIndex} />
                                                                                    <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.editIcon} alt='' key={'edit-' + columnIndex} />
                                                                                    <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.trashIcon} alt='' key={'trash-' + columnIndex} />
                                                                                    <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.rightCurvedIcon} alt='' key={'right-' + columnIndex} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </React.Fragment >
                                                                ))}
                                                        </div>
                                                    </React.Fragment >
                                                ))}

                                            {paginatedData.length === 0 && <div className=''>
                                                <div className="flex justify-center items-center h-full mt-16">
                                                    <div className="text-center">
                                                        <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.noProjects} alt="" />
                                                        <p className="font-interSemiBold text-base text-[#455468] pb-0.5">No project found</p>
                                                        <p className="font-interRegular text-sm mb-4 text-[#475467]">Add new to get started.</p>
                                                        <button className={`mx-auto !text-sm text-white bg-[#1751D0] hover:bg-[#120693] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5`} onClick={openAddProjectModal}>
                                                            <img className='w-4' src={allImgPaths.plusIconWhite} alt="" />
                                                            Add New
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>}
                                        </div>
                                    }
                                </div>
                                {/* Paginate */}
                                {paginatedData.length > 0 &&
                                    <Paginate
                                        startItem={startItem}
                                        endItem={endItem}
                                        totalItems={dataLength}
                                        handlePrev={handlePrev}
                                        handleNext={handleNext}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                    />}

                                {/* {paginatedData.length > 0 && <div className={`flex items-center justify-between w-full p-[20px]`}>
                                    {
                                        <div className='text-base font-interRegular text-[#4F4D55]'>
                                            Showing <span className='text-[#0A090B] font-interMedium'>{startItem}</span> to <span className='text-[#0A090B] font-interMedium'>{endItem}</span> of <span className='text-[#0A090B] font-interMedium'>{dataLength}</span> Results
                                        </div>
                                    }

                                    <div className='flex items-center gap-2'>
                                        <button
                                            onClick={handlePrev}
                                            disabled={currentPage === 1}
                                            className={`px-3 py-2 text-sm rounded border ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-[#4F4D55] hover:bg-gray-50'}`}>
                                            Previous
                                        </button>

                                        <button
                                            onClick={handleNext}
                                            disabled={currentPage === totalPages}
                                            className={`px-4 py-2 text-sm rounded border ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                                            Next
                                        </button>
                                    </div>
                                </div>} */}
                            </div>
                        </div>
                    </div>
                    {openCreateProjectModal && <AddProjectModal isShow={openCreateProjectModal} setIsShow={setOpenCreateProjectModal} setToast={setToast} setRefreshData={setRefreshData} />}
                    {
                        openInviteCollaborators && <InviteCollaborator
                            setOpenInviteCollaborators={setOpenInviteCollaborators}
                            openInviteCollaborators={openInviteCollaborators}
                            selectedProjectId={selectedProjectId}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
export default Projects;

