import {useEffect, useRef, useState} from 'react';
import useAppState from '../../context/useAppState';
import {useNavigate} from 'react-router-dom';

import Slider from 'react-slick';
import {sliderSetting} from '../../utils/constant';

import allImgPaths from 'assets/images';
import {Link, Navigate} from 'react-router-dom';

function SelectTeams() {
    const {retrieveUserTeams, retrievePendingInvitations, pendingInvitations, acceptInvitation, setCurrentTeam, userTeams} = useAppState('selectTeam');

    const [userEmail, setUserEmail] = useState<any>(null);

    useEffect(() => {
        retrieveUserTeams();
        retrievePendingInvitations();
    }, []);

    useEffect(() => {
        const email = localStorage.getItem('userEmail');
        setUserEmail(email);
    });
    const navigate = useNavigate();

    const sliderRef = useRef<any>(null);

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };
    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    // const userTeams = [
    //     {
    //         team_id: '1',
    //         team_name: 'Marketing Team',
    //         logo: 'path/to/logo1.png',
    //         members: [{profile_image: 'path/to/member1.png'}, {profile_image: 'path/to/member2.png'}],
    //         total: 2,
    //     },
    //     {
    //         team_id: '2',
    //         team_name: 'Development Team',
    //         logo: 'path/to/logo2.png',
    //         members: [{profile_image: 'path/to/member3.png'}, {profile_image: 'path/to/member4.png'}, {profile_image: 'path/to/member5.png'}],
    //         total: 3,
    //     },
    // ];

    const handleImageError = (e: any) => {
        e.target.src = allImgPaths.defaultProfile;
    };

    const handleTeamImageError = (e: any) => {
        e.target.src = allImgPaths.defaultTeamProfileImage;
    };

    return (
        <div className='flex'>
            {/* SelectTeams Left */}
            <div className='w-1/2'>
                <div className='bg-[#F9FAFB] signup-slider-wrapper'>
                    <Slider {...sliderSetting} ref={sliderRef} {...sliderSetting} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto customHeight800:pt-8 customHeight700:pt-14'>
                    <div className='w-full max-w-[640px] mx-auto'>
                        {/* Logo */}
                        <div className='text-center pb-12 pt-0'>
                            <img className='inline-block' src={allImgPaths.mainLogo} alt='logo' />
                        </div>

                        {/* Content */}
                        <div className='w-full px-3'>
                            <h1 className='font-interSemiBold text-[30px] text-[#101828] pb-2 text-center leading-[1.3]'>It’s good to see you again!</h1>
                            <p className='text-[#475467] text-center pb-7'>Choose a team below to continue.</p>

                            {/* Teams Rows */}
                            {userTeams && userTeams.length > 0 && (
                                <div className='max-h-[456px]'>
                                    <div className='border border-[#D0D5DD] rounded-xl py-3 pl-6 pr-0 mb-4'>
                                        <h4 className='text-sm text-[#344054] font-interSemiBold pb-1'>My Teams</h4>
                                        <div className={`h-full overflow-y-auto pr-6 ${pendingInvitations.length > 0 ? 'max-h-[120px]' : 'max-h-[250px]'}`}>
                                            {userTeams &&
                                                userTeams.length > 0 &&
                                                userTeams.map((team: any, index: number) => (
                                                    <div className='team-row group hover:bg-[#F5F8FD] px-3 py-2 rounded-xl cursor-pointer' key={index}>
                                                        <div className='flex items-center gap-x-1'>
                                                            <img className='w-10 h-10 object-cover rounded-full' src={team?.logo ? team?.logo : allImgPaths.defaultTeamProfileImage} alt='team icon' onError={(e: any) => handleTeamImageError(e)} />

                                                            <div className='flex justify-between w-full pl-2' onClick={() => setCurrentTeam(team?.team_id)}>
                                                                <div className='flex items-center'>
                                                                    <h5 title={team.team_name} className='text-sm font-interSemiBold text-[#344054] group-hover:text-[#113B98] pb-0.5 truncate w-48'>
                                                                        {team.team_name}
                                                                    </h5>

                                                                    <div className='ml-16'>
                                                                        <div className='flex items-center pb-0.5'>
                                                                            {team.members.map((teamMember: any, index: number) => (
                                                                                <img
                                                                                    className='min-w-7 min-h-7 w-7 h-7 flex-shrink-0 -ml-2.5 rounded-full'
                                                                                    src={teamMember?.profile_image ? teamMember?.profile_image : allImgPaths.defaultProfile}
                                                                                    alt='team members'
                                                                                    key={index}
                                                                                    onError={(e: any) => handleImageError(e)}
                                                                                />
                                                                            ))}
                                                                        </div>

                                                                        <span className='block text-sm text-[#475467] group-hover:text-[#113B98] -ml-2'>{team.total > 1 ? `${team.total} members` : `${team.total} member`}</span>
                                                                    </div>
                                                                </div>
                                                                <img className='svg-grey' src={allImgPaths.arrowRightGrey} alt='right arrow' />
                                                                <img className='svg-blue' src={allImgPaths.arrowRightBlue} alt='right arrow' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div onClick={() => navigate('/team-setup')}>
                                <button className='border border-[#D2CEFD] hover:bg-[#F5F8FD] rounded-lg px-4 py-3 flex items-center gap-x-2 w-full shadow-customShadow3 mb-4 justify-center'>
                                    <img src={allImgPaths.plusCircleBlueIcon} alt='' />
                                    <span className='text-sm font-interSemiBold text-[#1751D0]'>Add new team</span>
                                </button>
                            </div>

                            {/* Pending Invitation */}

                            {pendingInvitations?.length > 0 && (
                                <div className={`h-full overflow-hidden`}>
                                    <div className='border border-[#D0D5DD] rounded-xl pl-6 pr-0 py-3'>
                                        <div className='flex items-center justify-between'>
                                            <div className=''>
                                                <h4 className='text-lg text-[#101828] font-interSemiBold pb-px'>Pending Invitations({pendingInvitations.length})</h4>
                                                <p className='text-sm text-[#475467] pb-3'>For {userEmail}</p>
                                            </div>
                                        </div>
                                        <div className='overflow-y-auto pr-3 max-h-[250px]'>
                                            {pendingInvitations.map((pendingInvitation: any, index: number) => (
                                                <div className='team-row group px-3 py-2 rounded-xl' key={index}>
                                                    <div className='flex items-center justify-between'>
                                                        <div className='flex gap-x-2.5 items-center '>
                                                            <img
                                                                className='w-10 h-10 rounded-full'
                                                                onError={handleTeamImageError}
                                                                src={pendingInvitation?.team_id?.logo ? pendingInvitation?.team_id?.logo : allImgPaths.defaultTeamProfileImage}
                                                                alt=''
                                                            />
                                                            <h6 className='font-interSemiBold text-sm text-[#344054]'>{pendingInvitation?.team_id?.name}</h6>
                                                        </div>
                                                        <button
                                                            className='text-[#1751D0] font-interSemiBold tracking-wide rounded-lg bg-white hover:bg-[#F5F8FD] border border-[#AEBFFF] py-2 !px-6'
                                                            onClick={() => acceptInvitation(pendingInvitation._id)}>
                                                            Join
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#113B98]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#113B98]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SelectTeams;
