interface PropType {
	isLoading: boolean
}

const Loader = ({ isLoading }: PropType) => {
	return (
		<>
		{isLoading && (
			<div
				className="inset-0 fixed bg-opacity-80 bg-black transition-opacity z-[60] flex items-center justify-center"
				id="loading"
			>
				<div className="custom-spinner"></div>
			</div>
		)}
		</>
		
	);
};

export default Loader;
