import { inputField } from "../types";
import iButtonImg from "../assets/images/iButton-icon.svg";
import iButtonHideImg from "../assets/images/iButton-hide-icon.svg";
import errorButtonImg from "../assets/images/error-icon.svg";


export const InputTextField = ({
  label,
  style,
  name,
  value,
  type = "text",
  placeholder,
  onChange,
  onBlur,
  err,
  iButton,
  showEyeBtn,
  handleShowEyeBtn,
  errorButton,
  onClick,
  isDisabled = false,
  className,
  autoComplete = 'off',
  onPaste
  
}: inputField) => {
  return (
    <div>
      {label && (
        <label htmlFor={name} className="label pb-2">
          {label}<span className="text-[#D92D20]">*</span>
        </label>
      )}
	  <div className="relative">
		<input
			className={`${className} input-field border border-[#D0D5DD] hover:border-[#1751D0] focus:border-[#1751D0] block w-full ${style}`}
			id={name}
			name={name}
			value={value}
			type={type}
			placeholder={placeholder}
			onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      disabled={isDisabled}
      autoComplete={autoComplete}
      onPaste={onPaste}
		/>

		{errorButton && <img className="absolute top-1/2 transform -translate-y-1/2 right-[14px] cursor-pointer" src={errorButtonImg} alt="button" />}

		{iButton && <img className="absolute top-1/2 transform -translate-y-1/2 right-[14px] cursor-pointer" 
    src={showEyeBtn ?iButtonImg : iButtonHideImg } alt="button" onClick={handleShowEyeBtn} />}
	  </div>
		{err && <label className="text-[#D92D20] text-sm pl-px">{err}</label>}    
</div>
  );
};
