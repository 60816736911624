interface HeaderProps {
    title?:string;
    isShowHeader: boolean;
    className: string;
    children?: React.ReactNode;
}

const HeaderModal = ({ title, isShowHeader, className, children }:HeaderProps) => {
    if (!isShowHeader) return null;
  
    return (
      <div className={` ${className}`}>
        {children}
      </div>
    );
  };

  export { HeaderModal };
