import { useState,useMemo } from 'react';

interface UsePaginationProps {
  totalItems: number;
  itemsPerPage?: number;
  initialPage?: number;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  startItem: number;
  endItem: number;
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
  currentData: <T>(data: T[],isRequired?: boolean) => T[];
}

export const usePagination = ({
  totalItems,
  itemsPerPage = 5,
  initialPage = 1
}: UsePaginationProps): PaginationInfo => {
  const [currentPage,setCurrentPage] = useState(initialPage);

  // Calculate total pages
  const totalPages = useMemo(() =>
    Math.ceil(totalItems / itemsPerPage),
    [totalItems,itemsPerPage]
  );

  // Ensure current page stays within bounds
  // const validCurrentPage = Math.min(Math.max(1,currentPage),totalPages);
  const validCurrentPage = Math.max(1,Math.min(currentPage,Math.max(1,totalPages)));

  // Calculate start and end items for display
  const startItem = (validCurrentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1,totalItems);

  const nextPage = () => {
    setCurrentPage(page => Math.min(page + 1,totalPages));
  };

  const prevPage = () => {
    setCurrentPage(page => Math.max(page - 1,1));
  };

  const setPageSafe = (page: number) => {
    const newPage = Math.min(Math.max(1,page),totalPages);
    setCurrentPage(newPage);
  };

  const currentData = <T>(data: T[],isRequired?: boolean): T[] => {
    if (isRequired) {

      const start = (validCurrentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return data.slice(start,end);
    }
    // Use validCurrentPage instead of currentPage to ensure correct slice
    return data;

  };

  return {
    currentPage: validCurrentPage, // Return validCurrentPage instead of currentPage
    totalPages,
    startItem,
    endItem,
    nextPage,
    prevPage,
    setPage: setPageSafe,
    currentData
  };
};