import React,{ Dispatch,SetStateAction,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';

import { Body } from './Body';
import allImgPaths from "assets/images";
import CustomSelect from "../CustomSelect";

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}

const AddToProjectModal = ({ isShow,setIsShow,setToast }: Iprops) => {
    const [toggle,setToggle] = useState(0);
    const [option,setOption] = useState('source');
    const [isOpen,setIsOpen] = useState(false);
    const [projectId,setProjectId] = useState(0);
    const projectList: any = [
        {
            label: "Project 1",value: "1",
        },{
            label: "Project 2",value: "2"

        },{ label: "Project 3",value: "3" }
    ]

    const onCloseModal = () => {
        setIsShow(false);
    };

    const handleChange = (value: any) => {
        setProjectId(value);
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[480px]'} isShow={isShow}>
                <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                    <div className='flex items-center justify-between pb-7'>
                        <img className='h-10 w-10' src={folderPlus} alt='remove' />
                        {/* Close Icon */}
                        <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                    </div>

                    <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Add to Project</h2>
                    <p className='text-sm text-[#4F4D55] font-interRegular'>Add selected portfolios to your existing projects</p>
                </HeaderModal>

                <Body title='' isShowBody={true} className='modal-body px-6 pt-7'>
                    <div className='pb-5'>
                        <label htmlFor='' className='text-[#2D2B32] text-sm font-interRegular block pb-2 flex'>
                            Project
                            <img src={allImgPaths.questionCircle} alt='help' />
                        </label>
                        <CustomSelect
                            options={projectList}
                            placeholder='Select the project add to'
                            className="font-interRegular"
                            onChange={(e: any) => handleChange(e.value)}
                            extendedOption={false}
                        />
                        {/* <input type='text' placeholder='Device Holder' className='border hover:borderborder border-[#D0D5DD] hover:border focus:border hover:border-[#1E0AF5] focus:border-[#1E0AF5] p-2 rounded-md w-full' /> */}

                    </div>
                    <div className='flex flex-col gap-y-[5px] gap-x-6 '>
                        <h5 className='text-[#4F4D55] text-sm font-interRegular pb-1'>Add as</h5>
                        <div className='flex gap-4 items-center pb-3'>
                            <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                <div className='relative flex items-center justify-center mt-1'>
                                    <div
                                        className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'source' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                        {option === 'source' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                    </div>
                                    <input
                                        type='radio'
                                        name=''
                                        className='sr-only'
                                        onClick={(e) => {
                                            setToggle(0);
                                            setOption('source');
                                        }}
                                        checked={option === 'source'}
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Source</h3>
                                </div>
                            </label>
                            <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                <div className='relative flex items-center justify-center mt-1'>
                                    <div
                                        className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'Compare with data' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                        {option === 'Compare with data' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                    </div>
                                    <input
                                        type='radio'
                                        name=''
                                        className='sr-only'
                                        onClick={(e) => {
                                            setToggle(1);
                                            setOption('Compare with data');
                                        }}
                                        checked={option === 'Compare with data'}
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Compare with data</h3>
                                </div>
                            </label>
                        </div>
                    </div>
                </Body>

                <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                    <div className='flex items-center gap-x-3 justify-center'>
                        <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                            Cancel
                        </button>
                        <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                            Add
                        </button>
                    </div>
                </FooterModal>
            </Modal>
        </>
    );
};

export default AddToProjectModal;
