import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { forgotPasswordService } from "../../services/auth/auth";
import { forgotPassword } from "../../schema/validation";
import { emailValidation } from "../../utils/function";
import { signupValidationText } from "../../utils/constant";

const useForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState({ success: "", error: "" });

  const resetStateToDefaults = ()=> {
    setEmail("");
    setMsg({ success: "", error: "" })
  }
  useEffect(()=>{
    resetStateToDefaults();
  },[location.pathname]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmail(value);
    if (name === "email") {
      const isValid = emailValidation(value);

      if (!isValid) {
        setMsg({ ...msg, error: signupValidationText.emailText });
      } else {
        setMsg({ ...msg, error: "" });
      }
    } 
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    forgotPassword
      .validate({ email })
      .then((data: any) => {
        if (data) {
          forgotEmail(data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setMsg({ ...msg, error: err.message });
      });
  };

  const forgotEmail = async (userEmail: any) => {
    
    try {
      const response: any = await forgotPasswordService(userEmail);
      setMsg({ ...msg, success: response.message });
      localStorage.setItem("userId", response.user_id);
      localStorage.setItem("userEmail", userEmail.email);
      navigate("/mail-sent");
    } catch (error: any) {
      setMsg({ ...msg, error: error.message });
      console.log("error while forgot the password =", error);
    }
  };

  return {
    email,
    msg,
    handleInputChange,
    handleFormSubmit,
  };
};
export default useForgotPassword;
