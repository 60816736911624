import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {  resetPasswordSchema } from "../../schema/validation";
import { resetPasswordService, sendOtp } from "../../services/auth/auth";
import { SOMETHING_WENT_WRONG, signupValidationText } from "../../utils/constant";
import { checkAllFieldSame, passwordValidation } from "../../utils/function";

interface fieldType {
  code: string;
  password: string;
}

const useResetPassword = () => {
  const location = useLocation();
  const initialFormDetail = { password: "", confirmPassword: ""};
  const initialShowEyeOptions = { password:false, confirmPass: false }

  const [formDetail, setFormDetail] = useState(initialFormDetail);
  const [passwordRule, setPasswordRule] = useState<boolean[]>([]);
  const [showPasswordRule, setShowPasswordRule] = useState(false);
  const [showContinueScreen, setShowContinueScreen] = useState(false);
  const [showEyeBtn, setShowEyeButton] = useState(initialShowEyeOptions);
  const [error, setError] = useState(initialFormDetail);
  const [msg, setMsg] = useState({ success: "", err: "" });

  const urlParams = new URLSearchParams(window.location.search);
  const verificationCode = urlParams.get('code');

  const userId = localStorage.getItem("userId") as string;

  
  useEffect(()=>{
    setFormDetail(initialFormDetail)
    setPasswordRule([]);
    setShowContinueScreen(false);
    setError(initialFormDetail);
    setMsg({ success: "", err: "" });
  },[location.pathname]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDetail({ ...formDetail, [name]: value });

    if (name === "password") {
      setShowPasswordRule(true);
      const results = passwordValidation(value);
      setPasswordRule(results);
    } else{
      setShowPasswordRule(false);
    }

    if(passwordRule.length>0 && checkAllFieldSame(passwordRule)) {
      setShowPasswordRule(false);
    }
    if (name === "confirmPassword") {
      if (formDetail["password"] !== value) {
        setError({
          ...error,
          confirmPassword: signupValidationText.confirmPassword,
        });
      }
      if (formDetail["password"] === value) {
        setError({ ...error, confirmPassword: "" });
      }
    } 
    else {
      setError({ ...error, [name]: "" });
    }
  };

  const handleShowPassWordRule = () => {
    setShowPasswordRule(!showPasswordRule);
  };

  const handlePassEyeBtn = () => {
    setShowEyeButton({...showEyeBtn, password:!showEyeBtn.password});
  };

  const handleConfirmPassEyeBtn = () => {
    setShowEyeButton({...showEyeBtn, confirmPass:!showEyeBtn.confirmPass});
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    resetPasswordSchema
      .validate(formDetail,{ abortEarly: false })
      .then((data: any) => {
        if (data && verificationCode) {
          const payload = {
            password: data.password,
            code: verificationCode,
          };
          resetpassword(payload);
        }
      })
      .catch((err: any) => {
        const fieldErrors:any = {};
        err.inner.forEach((validationError: any) => {
          fieldErrors[validationError.path] = validationError.message;
        });
        if (fieldErrors.password) {
          setError({ ...error, password: fieldErrors.password });
        } else if (fieldErrors.confirmPassword) {
          setError({ ...error, confirmPassword: fieldErrors.confirmPassword });
        } 
      });
  };

  const resetpassword = async (payload: fieldType) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const response: any = await resetPasswordService(userId, payload);
        setMsg({ ...msg, success: response.message });
        setShowContinueScreen(true);
        localStorage.clear();
      } catch (error: any) {
        console.log("Error while reset the password", error);
        setMsg({ ...msg, err: error.message });
      }
    } else {
      console.log("UserId is not Getting.");
      setMsg({ ...msg, err: SOMETHING_WENT_WRONG });
    }
  };

  const resendOtp = async () => {
    try {
      if (userId) {
        const response: any = await sendOtp(userId);
        setMsg({ ...msg, success: response.message });
      } else {
        setMsg({ ...msg, err: SOMETHING_WENT_WRONG });
      }
    } catch (err: any) {
      console.log("error while resend otp =", err);
      setMsg({ ...msg, err: err.message });
    }
  };

  return {
    formDetail,
    error,
    msg,
    resendOtp,
    showEyeBtn,
    passwordRule,
    showPasswordRule,
    showContinueScreen,
    handleShowPassWordRule,
    setShowPasswordRule,
    handleInputChange,
    handleFormSubmit,
    handlePassEyeBtn,
    handleConfirmPassEyeBtn
  };
};
export default useResetPassword;
