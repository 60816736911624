import React,{ Dispatch,SetStateAction,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import create from 'assets/images/create-claim.svg';
import close from 'assets/images/close-icon.svg';
import question from 'assets/images/help-circle-contained-filled.svg';
import dropArrow from 'assets/images/dropdown.svg';

import { Body } from './Body';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}

const CreateClaimChart = ({ isShow,setIsShow,setToast }: Iprops) => {
    const [toggle,setToggle] = useState(0);
    const [isNameChecked,setIsNameChecked] = useState(true);
    const [isClaimChecked,setIsClaimChecked] = useState(false);
    const [isOpen,setIsOpen] = useState(false);

    const onCloseModal = () => {
        setIsShow(false);
    };

    const claimData = ['Claim 1','Claim 2','Claim 3'];

    return (
        <>
            <Modal className={'modal-wrapper max-w-[440px]'} isShow={isShow}>
                <form>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between'>
                            <img className='cursor-pointer w-12 h-12 mb-4' src={create} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-lg text-[#101828] font-bold leading-none pb-1.5 text-left pt-3 px-2'>Create Claim Chart</h2>
                        <p className='text-md text-left text-[#475467] px-2'>Choose the type of claim chart you want to create.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-2'>
                        <h5 className='text-left px-2 text-neutral-700'>I want to</h5>
                        <div className='flex gap-8 items-center pb-3'>
                            <label className='flex px-3 my-3 cursor-pointer justify-start items-start'>
                                <input
                                    type='radio'
                                    name=''
                                    className='pt-2'
                                    onClick={(e) => {
                                        setToggle(0);
                                        setIsNameChecked(true);
                                        setIsClaimChecked(false);
                                    }}
                                    checked={isNameChecked}
                                />
                                <div className='flex flex-col'>
                                    <h3 className='text-base text-[#101828] font-semibold leading-none text-left px-2'>Create New</h3>
                                    <h6 className='text-left px-2 text-sm text-neutral-700'>Create a new file</h6>
                                </div>
                            </label>
                            <label className='flex px-3 my-3cursor-pointer justify-start items-start'>
                                <input
                                    type='radio'
                                    name=''
                                    className='pt-2'
                                    onClick={(e) => {
                                        setToggle(1);
                                        setIsClaimChecked(true);
                                        setIsNameChecked(false);
                                    }}
                                    checked={isClaimChecked}
                                />
                                <div className='flex flex-col'>
                                    <h3 className='text-base text-[#101828] font-semibold leading-none text-left px-2'>Add to existing</h3>
                                    <h6 className='text-left px-2 text-sm text-neutral-700'>Append to existing file</h6>
                                </div>
                            </label>
                        </div>
                        {toggle === 0 && (
                            <div className='flex flex-col gap-1 px-2'>
                                <label htmlFor='' className='text-left'>
                                    Name
                                </label>
                                <input type='text' placeholder='For Company 1' className='border hover:borderborder border-[#D0D5DD] hover:border-2 focus:border-2 hover:border-[#1E0AF5] focus:border-[#1E0AF5] p-2 rounded-md' />
                            </div>
                        )}
                        {toggle === 1 && (
                            <div className='flex flex-col gap-1 px-2'>
                                <div className='flex gap-1'>
                                    <label htmlFor='' className='text-left'>
                                        Select existing claim chart
                                    </label>
                                    <img src={question} alt='question' />
                                </div>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsOpen(!isOpen);
                                    }}
                                    className={`border hover:borderborder border-[#D0D5DD] hover:border-2 hover:border-[#1E0AF5] p-2 rounded-md ${isOpen ? 'border-[#1E0AF5] border-2' : ''}`}>
                                    <span className='flex items-center justify-between text-neutral-400'>
                                        Select
                                        <img src={dropArrow} alt='dropArrow' />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className='px-2 bg-white border rounded-md flex flex-col gap-1'>
                                        {claimData.map((data,index) => {
                                            return (
                                                <span key={index} className='text-left py-1 border-b'>
                                                    {data}
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center px-2'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Save
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default CreateClaimChart;
