import { axiosInstance } from "./axios";

export const fetchProjectList = async (page: number,limit: number,team_id: string) => {
    try {
        // &team_id=${team_id}
        return await axiosInstance.get(`project/list?page=${page}&perPage=${limit}&team_id=${team_id}`);
    } catch (error) {
        throw error;
    }
};

export const addInviteCollaborator = async (id: string,data: any) => {
    try {
        return await axiosInstance.post(`/project/${id}/invite`,data);
    } catch (error) {
        throw error;
    }
};

export const addProject = async (data: any) => {
    try {
        return await axiosInstance.post(`/project/`,data);
    } catch (error) {
        throw error;
    }
}