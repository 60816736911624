import Slider from 'react-slick';
import { Link,useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useRef } from 'react';
import { InputTextField } from 'components/InputField';
import CustomCheckBox from 'components/CustomCheckBox';
import useAppState from 'context/useAppState';
import allImgPaths from 'assets/images';
import { sliderSetting } from 'utils/constant';
// import useAppState from "../context/useAppState";
// import Footer from "../components/Footer";

function Login() {
    const { error,errMsg,userData,handleInputChange,handleFormSubmit,googleLogin,showEyeBtn,handlePassEyeBtn } = useAppState('login');
    const { email,password } = userData;

    const navigate = useNavigate();

    const sliderRef = useRef<any>(null);

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };

    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    const isFormValid = () => {
        return !!email && !!password;
    };

    return (
        <div className='flex h-screen'>
            {/* Login Left */}
            <div className='w-1/2'>
                <div className='bg-[#F9FAFB] signup-slider-wrapper'>
                    <Slider {...sliderSetting} ref={sliderRef} {...sliderSetting} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto customHeight800:pt-8 customHeight700:pt-14'>
                    <div className='w-full max-w-[360px] mx-auto'>
                        {/* Logo */}
                        <div className='text-center pb-10 pt-0'>
                            <img className='inline-block' src={allImgPaths.logo} alt='logo' />
                        </div>

                        {/* Content */}
                        <h1 className='font-interSemiBold text-2xl text-[#101828] pb-3.5 text-center'>{'Log in to your account'}</h1>
                        <p className='text-[#475467] pb-8 text-center'>{'Welcome back! Dive into your event universe with a single click.'}</p>

                        {/* Form */}
                        <form onSubmit={handleFormSubmit}>
                            <div className='mb-5'>
                                <InputTextField label={'Email'} style={error.email ? 'border-red-500' : ''} name={'email'} placeholder={'Enter your email'} onChange={handleInputChange} value={email} type='text' err={error.email} />
                            </div>

                            <div className='mb-5'>
                                <InputTextField
                                    label={'Password'}
                                    name={'password'}
                                    style={error.password ? 'border-red-500' : ''}
                                    placeholder={'Enter your password'}
                                    onChange={handleInputChange}
                                    value={password}
                                    iButton={true}
                                    type={showEyeBtn.password ? 'text' : 'password'}
                                    handleShowEyeBtn={handlePassEyeBtn}
                                    showEyeBtn={showEyeBtn.password}
                                    err={error.password}
                                />
                                <p className='text-[#D92D20] text-sm pt-0.5'>{errMsg}</p>
                            </div>

                            {/* Forgot password */}
                            <div className='flex justify-between password-checkbox pb-3'>
                                <CustomCheckBox name='Individual' id="remember-30" label={'Remember for 30 days'} />
                                <div className='leading-none'>
                                    <span className='text-[#1751D0] cursor-pointer font-interSemiBold text-sm hover:text-[#113B98]' onClick={() => navigate('/forgotpassword')}>
                                        {'Forgot password'}
                                    </span>
                                </div>
                            </div>

                            <button type='submit' className={`submit-btn-blue w-full mt-2 mb-4 blue-btn-hover ${!isFormValid() ? 'button-disabled' : ''}`} disabled={!isFormValid()}>
                                {'Log in'}
                            </button>
                        </form>

                        {/* Already Account */}
                        <div className='text-sm text-[#475467] pt-7 text-center customHeight920:pb-5 pb-0'>
                            {'New to IpVijil?'}{' '}
                            <Link className='text-sm font-interSemiBold text-[#1751D0] hover:text-[#113B98]' to='/signup'>
                                {'Create an account'}
                            </Link>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
