import {useState} from 'react';
import OtpInput from 'react-otp-input';

function Otp({onChangeOtp, borderColor}: any) {
    const [otp, setOtp] = useState('');

    const handleOtp = (otp: string) => {
        setOtp(otp);
        onChangeOtp(otp);
    };

    return (
        <OtpInput
            value={otp}
            onChange={handleOtp}
            numInputs={6}
            renderSeparator={<span className='dash-styling'> </span>}
            renderInput={(props: any) => <input {...props} />}
            inputStyle={{
                width: '64px',
                height: '64px',
                border: '1px solid #D0D5DD',
                borderRadius: '10px',
                marginLeft: '5px',
                marginRight: '5px',
                color: '#344054',
                fontSize: '44px',
                borderColor: borderColor,
                //  boxShadow: "0px 0px 0px 3px #9e77ed3d",
            }}
        />
    );
}

export default Otp;
