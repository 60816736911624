interface BodyProps {
    title?:string,
    isShowBody:boolean,
    className:string,
    children:any
}

const Body = ({ title, isShowBody, className, children }:BodyProps) => {
    if (!isShowBody) return null;

    return (
        <div className={` ${className}`}>
            {children}
        </div>
    );
};

export { Body };