import { differenceInDays, format, isToday } from "date-fns";

import { optionProp } from "../types";

import { CUSTOM_ROLES, EVENT_RANGE_FIELDS, LLM_INTENTS } from "./constant";
import { regex } from "./regex";
import { isString } from "lodash";
// import { getRangeValuesFromString } from "./fete";

export const passwordValidation = (value: any) => {
  const MIN_LENGTH = 8;
  const MAX_LENGTH = 15;
  const results = [];

  results.push(value.length >= MIN_LENGTH && value.length <= MAX_LENGTH);

  // Validate lowercase letter
  results.push(/[a-z]/.test(value));

  // Validate uppercase letter
  results.push(/[A-Z]/.test(value));

  // Validate number
  results.push(/\d/.test(value));

  // Validate special character
  results.push(/[~`!@#$%^&*()\-_+={}\[\]|\\:;'"<,>.?\/]/.test(value));

  // Validate non-email format (basic check, adjust as needed)
  results.push(!/\w+@\w+\.\w+/.test(value));

  return results;
};

export const nameValidation = (value: string) => {
  const valid = regex.name.test(value);
  return valid;
};
export const emailValidation = (value: any) => {
  const validEmail = regex.email.test(value);
  return validEmail;
};

export const checkAllFieldSame = (arr: boolean[]) => {
  return arr.every((val) => val === arr[0]);
};

export const generateId = () => {
  /* Generate a random number between 0 and 10000 */
  return Math.floor(Math.random() * 10001);
};

export const findSelectedOption = (existingRoles: any, roleName: string) => {
  if (existingRoles && existingRoles.length > 0) {
    return existingRoles.find((option: optionProp) => option.value === roleName);
  } else {
    return CUSTOM_ROLES.find((option: optionProp) => option.value === roleName);
  }
};

export const getDays = (dates: any) => {
  const date1 = new Date();
  const date2 = new Date(dates);

  const differenceInDaysDemo = differenceInDays(date2, date1);

  return differenceInDaysDemo;
};

export const getMonths = (startDate: any, endDate: any) => {
  const data = format(new Date(startDate), "MMM dd, yyyy");
  const data2 = format(new Date(endDate), "MMM dd, yyyy");
  return { data, data2 };
};

export const updatedTaskList = (data: any) => {
  const result: any = [];
  const value =
    data.length > 0 &&
    data.map((item: any) => {
      result.push({ value: item._id, label: item.title });
    });
  return result;
};

export const updatedMemberList = (data: any) => {
  const result: any = [];
  const value =
    data.length > 0 &&
    data.map((item: any) => {
      result.push({ value: item.user_id, label: item.user_email });
    });
  return result;
};

export const getEventStatus = (startDate: any, endDate: any) => {
  const currentDate = new Date();

  const isPastEvent = endDate < currentDate;
  const isOngoingEvent = startDate <= currentDate && endDate >= currentDate;
  const isUpcomingEvent = startDate > currentDate;

  if (isPastEvent) {
    return `Past Event`;
  } else if (isOngoingEvent) {
    return `Ongoing`;
  } else if (isUpcomingEvent) {
    const differenceInDaysDemo = differenceInDays(startDate, currentDate);
    return `${differenceInDaysDemo} Days to Go`;
  }
};

export const formatNumber = (data: number) => {
  const formatter = isString(data) ? parseInt(data)?.toLocaleString('en-US') :  data?.toLocaleString('en-US')
  return formatter;
}

function isSameDay(date1: any, date2: any) {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}

function formatTime(date: any) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  const timeString = `${hours}:${minutesStr} ${ampm}`;
  return timeString;
}

export const getTimeForChatMessage = (createdAt: any) => {
  const messageDate: any = new Date(createdAt);
  const currentDate: any = new Date();
  if (isSameDay(messageDate, currentDate)) {
      // Display time in HH:MM AM/PM format for today's message
      return formatTime(messageDate);
  } else {
      // Calculate days ago and display as "x Days Ago HH:MM AM/PM"
      const daysAgo = Math.floor((currentDate - messageDate) / (1000 * 60 * 60 * 24));
      const timeString = formatTime(messageDate);
      return `${daysAgo} Days Ago ${timeString}`;
  }
}

export const extractJSONFromText = (text: string) => {
  const regex = /\{.*\}/; // Match anything within curly braces
  const textWithoutNewLines = text.replace(/\n/g, "");
  const match = textWithoutNewLines.match(regex);
  if (match) {
      return JSON.parse(match[0]); // Return the entire matched JSON string
  } else {
      return {}; // Return empty string if no JSON found
  }
};

export const getRAWTextFromJSON = (obj: any, indent: string = ''): string => {
  let result = "";
  for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
              result += `${indent}${key}:\n${getRAWTextFromJSON(value, indent + '  ')}`;
          } else {
              result += `${indent}${key}: ${value}\n`;
          }
      }
  }
  return result;
}

export const jsonToMarkdown = (json: any, level: number = 0): string => {
  let markdown = '';

  // Determine indentation based on the level
  const indent = '  '.repeat(level);

  // Iterate over each key-value pair in the JSON object
  for (const key in json) {
    if (Object.prototype.hasOwnProperty.call(json, key)) {
      const value = json[key];

      // Skip properties with null or undefined values
      if (value === null || value === undefined) {
        continue;
      }

      // Generate Markdown content based on the value type
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Nested object
        markdown += `${indent}- **${key}:**\n`;
        markdown += jsonToMarkdown(value, level + 1); // Recursively process nested object
      } else if (Array.isArray(value)) {
        // Array of values
        markdown += `${indent}- **${key}:**\n`;
        value.forEach((item: any) => {
          if (typeof item === 'object') {
            markdown += jsonToMarkdown(item, level + 1); // Recursively process nested object in array
          } else {
            markdown += `${indent}  - ${item}\n`;
          }
        });
      } else {
        // Primitive value
        markdown += `${indent}- **${key}:** ${value}\n`;
      }
    }
  }

  return markdown;
}

export const formatFileSize = (bytes:any) => {
  if (bytes < 1024) {
      return bytes + ' bytes';
  } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
  } else {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }
}

// Helper function to recursively remove objects from a JavaScript object
const removeObjects = (obj: any): any => {
  if (Array.isArray(obj)) {
    // If the object is an array, recursively process each element
    return obj.map((element) => removeObjects(element));
  } else if (typeof obj === 'object' && obj !== null) {
    // If the object is a non-null object, recursively process each key-value pair
    const updatedObject: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        updatedObject[key] = removeObjects(obj[key]);
      }
    }
    return updatedObject;
  } else {
    // Return the value unchanged if it's not an array or object
    return obj;
  }
};

export const removeJSONFromString = (text: string): string => {
  try {
    const jsonObject = JSON.parse(text); // Parse the input string into a JavaScript object
    const modifiedObject = removeObjects(jsonObject); // Call a helper function to remove objects
    const modifiedText = JSON.stringify(modifiedObject); // Convert the modified object back to a string
    return modifiedText;
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return text; // Return the original text if parsing fails
  }
};

export const getNumberVal = (no: any) : number => {
  let convertedNo = no;
  switch (typeof no) {
    case 'number':
      convertedNo = no
      break;
    case 'string':
      convertedNo = no !== '' ? parseInt(no.replace(/,/g, ""), 10) : null
      break;
    default:
      break;
  }
  return convertedNo;
}

export const cleanObject = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return if obj is not an object or if it's null
  }

  // Create a new object to hold the cleaned properties
  let newObj : any = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];

      if (value !== null) {
        // Recursively call removeNulls on nested objects or arrays
        let cleanedValue = typeof value === 'object' ? cleanObject(value) : value;

        // Only add non-null values and non-empty objects/arrays
        if (typeof cleanedValue !== 'object' || (Array.isArray(cleanedValue) && cleanedValue.length) || (Object.keys(cleanedValue).length)) {
          newObj[key] = cleanedValue;
        }
      }
    }
  }

  return newObj;
}

export const removeIntents = (str: string) => {
  // Get the values of the LLM_INTENTS object
  const intents = Object.values(LLM_INTENTS);
  
  // Create a regex pattern to match all intents
  const regexPattern = new RegExp(intents.join('|'), 'g');
  
  // Replace all occurrences of the intents with an empty string
  return str.replace(regexPattern, '').trim();
}