interface FooterProps {
    title: string;
    isShowFooter:boolean;
    className: string;
    children: React.ReactNode;
}

const FooterModal = ({ title, isShowFooter, className, children }:FooterProps) => {
    if (!isShowFooter) return null;
  
    return (
      <div className={className}>
        <h2 className="text-lg font-semibold">{title}</h2>
        {children}
      </div>
    );
  };

export { FooterModal };