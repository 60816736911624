import React,{ Dispatch,SetStateAction,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';

import { Body } from './Body';
import { addProject } from "services/project";
import { InputTextField } from "components/InputField";
import Textarea from "../Textarea";
import Toast from "../Toast";

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}

const AddProjectModal = ({ isShow,setIsShow,setToast,setRefreshData }: Iprops) => {
    const [toggle,setToggle] = useState(0);
    const [option,setOption] = useState('prior art');
    const [isOpen,setIsOpen] = useState(false);

    const [projectName,setProjectName] = useState('');
    const [projectDescription,setProjectDescription] = useState('');

    const selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

    const onCloseModal = () => {
        setIsShow(false);
    };

    const handleProjectNameChange = (e: any) => {
        setProjectName(e.target.value)
    }
    const handleProjectDescriptionChange = (e: any) => {
        setProjectDescription(e.target.value)
    }

    const handleSubmit = async (event: any) => {
        event?.preventDefault();
        console.log(toggle,'toggle value');

        const payload = { name: projectName,description: projectDescription,type: !toggle ? 'Find Prior Art' : 'Find Covered Products',team_id: selectedTeam.currentTeamId };
        const { status } = await addProject(payload);



        setProjectName('');
        setProjectDescription('');
        setToggle(0);
        setIsShow(false);
        setRefreshData(true);
        // setToast({ show: true,heading: 'Success',message: 'Project added successfully' })
        Toast.success('Success','Project added successfully');
    };


    return (
        <>
            <Modal className={'modal-wrapper max-w-[480px]'} isShow={isShow}>
                <form onSubmit={handleSubmit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between pb-7'>
                            <img className='h-10 w-10' src={folderPlus} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>New Project</h2>
                        <p className='text-sm text-[#4F4D55] font-interRegular'>Choose the type of project you want to create.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-7'>
                        <div className='pb-5'>
                            <label htmlFor='' className='text-[#2D2B32] text-sm font-interRegular block pb-2'>
                                Project Name
                            </label>
                            <InputTextField
                                className='placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full'
                                name={'name'}
                                placeholder={'Device Holder'}
                                onChange={handleProjectNameChange}
                                value={projectName}
                            />
                            {/* <input type='text' placeholder='Device Holder'
                                onChange={handleProjectNameChange}
                                className='border hover:borderborder border-[#D0D5DD] hover:border focus:border hover:border-[#1E0AF5] focus:border-[#1E0AF5] p-2 rounded-md w-full' /> */}
                        </div>
                        <div className='flex flex-col gap-y-[5px] gap-x-6 '>
                            <h5 className='text-[#4F4D55] text-sm font-interRegular pb-1'>I want to</h5>
                            <div className='flex gap-4 items-center pb-3'>
                                <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-1'>
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'prior art' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'prior art' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(0);
                                                setOption('prior art');
                                            }}
                                            checked={option === 'prior art'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Find prior art</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Check if my claims are safe to file</h6>
                                    </div>
                                </label>
                                <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-1'>
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'find covered product' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'find covered product' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(1);
                                                setOption('find covered product');
                                            }}
                                            checked={option === 'find covered product'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Find covered products</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Find products/claims similar to my claims</h6>
                                    </div>
                                </label>
                            </div>
                            <div className='flex  flex-col gap-y-[5px]'>
                                <div className='flex justify-between'>
                                    <label htmlFor='' className='text-[#2D2B32] text-sm font-interRegular block'>
                                        Description
                                    </label>
                                    <label htmlFor='' className='font-interRegular text-xs text-[#7F7D83]'>
                                        Optional
                                    </label>
                                </div>
                                {/* <input type='text' placeholder='Enter description'
                                    onChange={handleProjectDescriptionChange}
                                    className='border hover:borderborder border-[#D0D5DD] hover:border focus:border hover:border-[#1E0AF5] focus:border-[#1E0AF5] p-2 rounded-md' /> */}
                                <Textarea
                                    name="project-desc"
                                    placeholder="Enter description"
                                    value={projectDescription}
                                    onChange={handleProjectDescriptionChange}

                                />
                            </div>
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Continue
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default AddProjectModal;
