import {useRef} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import {sliderSetting} from '../../utils/constant';
import {InputTextField} from '../../components/InputField';
import {PasswordRulesComponent, AccountExistPopUp} from '../../components/Auth';
import useAppState from '../../context/useAppState';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import allImgPaths from 'assets/images';

function SignUp() {
    const {
        error,
        errMsg,
        showEyeBtn,
        userData,
        emailExistPopup,
        passwordRule,
        showPasswordRule,
        setShowPasswordRule,
        handleDismiss,
        handleInputChange,
        handlePassEyeBtn,
        handleConfirmPassEyeBtn,
        handleShowPassWordRule,
        handleFormSubmit,
        googleLogin,
        emailExistButNotVerified,
        handleLoginRedirect,
        inviteeEmail,
    } = useAppState('signup');
    const {name, email, password, confirmPassword} = userData;

    const sliderRef = useRef<any>(null);

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };

    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    const isFormValid = () => {
        return !!name && !!email && !!password && !!confirmPassword;
    };

    return (
        <div className='flex'>
            {/* SignUp Left */}
            <div className='w-1/2'>
                <div className='bg-[#f9fafb] signup-slider-wrapper'>
                    <Slider {...sliderSetting} ref={sliderRef} {...sliderSetting} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-16 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            {/* SignUp Form */}
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto'>
                    <div className='w-full max-w-[360px] mx-auto'>
                        {/* Logo */}
                        <div className={`text-center  pb-7 customHeight920:pb-8 customHeight920:pt-32 pt-0`}>
                            <img className={`inline-block ${emailExistPopup ? 'pt-32' : ''}`} src={allImgPaths.mainLogo} alt='logo' />
                        </div>

                        {/* Content */}
                        <h1 className='font-interSemiBold text-2xl text-[#101828] pb-2.5 text-center'>Create your Account</h1>
                        <p className='text-[#475467] pb-6 text-center'>Sign up and celebrate!</p>

                        {/* Account Already exists */}
                        <AccountExistPopUp isPopUpShow={emailExistButNotVerified || emailExistPopup} handleDismiss={handleDismiss} handleLoginRedirect={handleLoginRedirect} />

                        {/* Form */}
                        <form onSubmit={handleFormSubmit}>
                            <div className='mb-5'>
                                <InputTextField
                                    label='Name'
                                    style={error.name ? 'border border-red-500' : ''}
                                    name={'name'}
                                    placeholder={'Enter your name'}
                                    onChange={handleInputChange}
                                    value={name}
                                    err={error.name}
                                    errorButton={error.name && true}
                                />
                            </div>

                            <div className='mb-4'>
                                <InputTextField
                                    label='Email'
                                    style={error.email ? 'border-red-500' : ''}
                                    name={'email'}
                                    placeholder={'Enter your email'}
                                    onChange={handleInputChange}
                                    value={inviteeEmail ? inviteeEmail : email}
                                    type='text'
                                    err={error.email}
                                    errorButton={error.email && true}
                                    isDisabled={inviteeEmail ? true : false}
                                />
                                <span className='inline-block pt-2 text-sm text-[#475467] '>
                                    We suggest using <span className='font-interMedium text-sm text-[#475467]'>work email address</span>
                                </span>
                            </div>

                            <div className='mb-5 custom-padding-right relative'>
                                <InputTextField
                                    label='Password'
                                    style={error.password ? 'border-red-500' : ''}
                                    name={'password'}
                                    placeholder={'Create a Password'}
                                    onChange={handleInputChange}
                                    onBlur={() => setShowPasswordRule(false)}
                                    value={password}
                                    type={showEyeBtn.password ? 'text' : 'password'}
                                    err={error.password}
                                    handleShowEyeBtn={handlePassEyeBtn}
                                    iButton={true}
                                    showEyeBtn={showEyeBtn.password}
                                    onClick={handleShowPassWordRule}
                                />
                                <PasswordRulesComponent passwordRule={passwordRule} showPasswordRule={showPasswordRule} setShowPasswordRule={setShowPasswordRule} />
                            </div>

                            <div className='mb-4 custom-padding-right relative'>
                                <InputTextField
                                    label='Confirm Password'
                                    style={error.confirmPassword ? 'border-red-500' : ''}
                                    name={'confirmPassword'}
                                    placeholder={'Re-enter password'}
                                    onChange={handleInputChange}
                                    value={confirmPassword}
                                    type={showEyeBtn.confirmPass ? 'text' : 'password'}
                                    err={error.confirmPassword}
                                    iButton={true}
                                    handleShowEyeBtn={handleConfirmPassEyeBtn}
                                    showEyeBtn={showEyeBtn.confirmPass}
                                />

                                {/* Error Message */}
                                <p className='text-[#D92D20] text-sm pt-0.5'>{errMsg}</p>
                            </div>

                            <button type='submit' className={`submit-btn-blue w-full mt-2 mb-4 blue-btn-hover ${!isFormValid() ? 'button-disabled' : ''}`} disabled={!isFormValid()}>
                                Get Started
                            </button>
                        </form>

                        {/* Already Account */}
                        <div className='text-sm text-[#475467] pt-7 text-center customHeight920:pb-5 pb-0'>
                            Already using IpVijil?{' '}
                            <Link className='text-sm font-interSemiBold text-[#1751D0] hover:text-[#113B98]' to='/login'>
                                Log in to an existing team
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
