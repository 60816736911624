/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState,useMemo,useCallback } from 'react';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import { usePagination } from 'hooks/paginate/usePagination';
import AddToProjectModal from "components/common/modals/AddToProject";
import NewPortfolioModal from "components/common/modals/NewPortfolio";
import { useNavigate } from "react-router-dom";

function Portfolio() {
    const projectColumns = [
        {
            name: 'Portfolio Name',
            ref: 'portfolioName',
            hide: false,
            position: 1,
        },
        {
            name: 'Last Update',
            ref: 'lastUpdate',
            hide: false,
            position: 4,
        },
        {
            name: 'Team',
            ref: 'team',
            hide: false,
            position: 5,
        },
        {
            name: 'Action',
            ref: 'action',
            hide: false,
            position: 6,
        },
    ];

    const projectData = [
        {
            name: 'Alexander Blackwood',
            portfolioName: 'Device holder',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '04/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/36.jpg',name: 'John Doe' },
                { img: 'https://randomuser.me/api/portraits/men/34.jpg',name: 'Jane Smith' },
                { img: 'https://randomuser.me/api/portraits/men/35.jpg',name: 'Mike Johnson' },
            ],
            description: 'Cover and grip for handheld devices',
        },
        {
            name: 'Charlotte Hamilton',
            portfolioName: 'Custom Handlebar',
            type: 'Find Covered Products',
            claimCharts: '2',
            lastUpdate: '04/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/37.jpg',name: 'Sarah Wilson' },
                { img: 'https://randomuser.me/api/portraits/men/38.jpg',name: 'Tom Brown' },
                { img: 'https://randomuser.me/api/portraits/men/39.jpg',name: 'Lisa Davis' },
            ],
            description: 'Ergonomic hand grips',
        },
        {
            name: 'Benjamin Lancaster',
            portfolioName: 'Smart Display Mount',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '04/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/40.jpg',name: 'David Chen' },
                { img: 'https://randomuser.me/api/portraits/men/41.jpg',name: 'Emma Wilson' },
            ],
            description: 'Advanced display mounting solution',
        },
        {
            name: 'Diana Wellington',
            portfolioName: 'Power Bank Pro',
            type: 'Find Covered Products',
            claimCharts: '3',
            lastUpdate: '03/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/42.jpg',name: 'Sophie Turner' },
                { img: 'https://randomuser.me/api/portraits/men/43.jpg',name: 'James Miller' },
            ],
            description: 'High-capacity portable charging',
        },
        {
            name: 'Edward Fitzgerald',
            portfolioName: 'Camera Stabilizer',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '03/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/44.jpg',name: 'Robert Brown' },
                { img: 'https://randomuser.me/api/portraits/women/45.jpg',name: 'Linda Davis' },
            ],
            description: 'Professional camera stabilization system',
        },
        {
            name: 'Grace Henderson',
            portfolioName: 'Wireless Charger',
            type: 'Find Covered Products',
            claimCharts: '4',
            lastUpdate: '02/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/46.jpg',name: 'Maria Garcia' },
                { img: 'https://randomuser.me/api/portraits/women/47.jpg',name: 'Emily White' },
            ],
            description: 'Fast wireless charging solution',
        },
        {
            name: 'Henry Morrison',
            portfolioName: 'Smart Lock',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '02/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/48.jpg',name: 'William Taylor' },
                { img: 'https://randomuser.me/api/portraits/men/49.jpg',name: 'Michael Brown' },
            ],
            description: 'IoT-enabled security system',
        },
        {
            name: 'Isabella Clarke',
            portfolioName: 'Tablet Stand',
            type: 'Find Covered Products',
            claimCharts: '2',
            lastUpdate: '01/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/50.jpg',name: 'Anna Johnson' },
                { img: 'https://randomuser.me/api/portraits/women/51.jpg',name: 'Patricia Moore' },
            ],
            description: 'Adjustable tablet mounting solution',
        },
        {
            name: 'James Patterson',
            portfolioName: 'Solar Charger',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '01/05/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/52.jpg',name: 'Kevin Wilson' },
                { img: 'https://randomuser.me/api/portraits/men/53.jpg',name: 'Thomas Anderson' },
            ],
            description: 'Portable solar charging system',
        },
        {
            name: 'Katherine Brooks',
            portfolioName: 'Smart Speaker',
            type: 'Find Covered Products',
            claimCharts: '5',
            lastUpdate: '30/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/54.jpg',name: 'Sarah Davis' },
                { img: 'https://randomuser.me/api/portraits/women/55.jpg',name: 'Laura Wilson' },
            ],
            description: 'AI-powered audio system',
        },
        {
            name: 'Lawrence Carter',
            portfolioName: 'Laptop Stand Pro',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '30/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/56.jpg',name: 'Daniel Lee' },
                { img: 'https://randomuser.me/api/portraits/men/57.jpg',name: 'Richard Martin' },
            ],
            description: 'Ergonomic laptop elevation system',
        },
        {
            name: 'Margaret Wilson',
            portfolioName: 'Phone Mount',
            type: 'Find Covered Products',
            claimCharts: '3',
            lastUpdate: '29/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/58.jpg',name: 'Jessica Taylor' },
                { img: 'https://randomuser.me/api/portraits/women/59.jpg',name: 'Michelle Rodriguez' },
            ],
            description: 'Vehicle phone mounting solution',
        },
        {
            name: 'Nathan Richardson',
            portfolioName: 'Power Strip',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '29/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/60.jpg',name: 'Christopher Lee' },
                { img: 'https://randomuser.me/api/portraits/men/61.jpg',name: 'Andrew Wilson' },
            ],
            description: 'Smart power distribution system',
        },
        {
            name: 'Olivia Thompson',
            portfolioName: 'Desk Mount',
            type: 'Find Covered Products',
            claimCharts: '4',
            lastUpdate: '28/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/62.jpg',name: 'Elizabeth Brown' },
                { img: 'https://randomuser.me/api/portraits/women/63.jpg',name: 'Jennifer Davis' },
            ],
            description: 'Monitor mounting mechanism',
        },
        {
            name: 'Peter Anderson',
            portfolioName: 'Cable Management',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '28/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/64.jpg',name: 'Joseph Wilson' },
                { img: 'https://randomuser.me/api/portraits/men/65.jpg',name: 'David Moore' },
            ],
            description: 'Professional cable organization',
        },
        {
            name: 'Rachel Mitchell',
            portfolioName: 'Headphone Stand',
            type: 'Find Covered Products',
            claimCharts: '2',
            lastUpdate: '27/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/66.jpg',name: 'Rebecca Martin' },
                { img: 'https://randomuser.me/api/portraits/women/67.jpg',name: 'Amanda Clark' },
            ],
            description: 'Premium headphone display',
        },
        {
            name: 'Samuel Harrison',
            portfolioName: 'Keyboard Rest',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '27/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/68.jpg',name: 'Brian Taylor' },
                { img: 'https://randomuser.me/api/portraits/men/69.jpg',name: 'Jason Wilson' },
            ],
            description: 'Ergonomic keyboard support',
        },
        {
            name: 'Teresa Palmer',
            portfolioName: 'Mouse Pad Pro',
            type: 'Find Covered Products',
            claimCharts: '3',
            lastUpdate: '26/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/70.jpg',name: 'Nicole Anderson' },
                { img: 'https://randomuser.me/api/portraits/women/71.jpg',name: 'Samantha Lee' },
            ],
            description: 'Premium mouse surface solution',
        },
        {
            name: 'Victor Reynolds',
            portfolioName: 'USB Hub',
            type: 'Find Prior Art',
            claimCharts: 'N/A',
            lastUpdate: '26/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/men/72.jpg',name: 'George Martin' },
                { img: 'https://randomuser.me/api/portraits/men/73.jpg',name: 'Eric Davis' },
            ],
            description: 'Multi-port connectivity solution',
        },
        {
            name: 'Wendy Sullivan',
            portfolioName: 'Monitor Light',
            type: 'Find Covered Products',
            claimCharts: '4',
            lastUpdate: '25/04/2024',
            team: [
                { img: 'https://randomuser.me/api/portraits/women/74.jpg',name: 'Stephanie Wilson' },
                { img: 'https://randomuser.me/api/portraits/women/75.jpg',name: 'Rachel Brown' },
            ],
            description: 'Screen-mounted lighting system',
        },
    ];
    const totalItems = projectData.length;
    const itemsPerPage = 8;


    const [openAddToProjectModal,setOpenAddToProjectModal] = useState(false);
    const [openNewPortFolioModal,setOpenNewPortFolioModal] = useState(false);
    const [showMenu,setshowMenu] = useState(false);
    const [sortOrder,setSortOrder] = useState('asc');
    const [arrowUp,setArrowUp] = useState(false);
    const [sortField,setSortField] = useState<any>(null);
    const [paginatedData,setPaginatedData] = useState<any[]>([]);
    const [selectedItems,setSelectedItems] = useState<number[]>([]);
    const [selectAll,setSelectAll] = useState(false);

    const [toast,setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });

    const navigate = useNavigate();

    const { currentPage,totalPages,startItem,endItem,nextPage,prevPage,currentData } = usePagination({
        totalItems,
        itemsPerPage,
        initialPage: 1,
    });

    // One effect for data pagination
    useEffect(() => {
        const newData = currentData(projectData);
        setPaginatedData(newData);
        setSelectedItems([]);
        setSelectAll(false);
    },[currentPage]);

    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectAll(e.target.checked);
            if (e.target.checked) {
                console.log('hit all');

                // Select all items in the current page
                const currentPageIds = paginatedData.map((_,index) => index);
                setSelectedItems(currentPageIds);
            } else {
                // Deselect all items
                setSelectedItems([]);
            }
        },
        [paginatedData]
    );

    const handleSelectItem = useCallback(
        (index: number) => {
            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev,index];

                // Update selectAll state based on if all items in current page are selected
                setSelectAll(newSelected.length === paginatedData.length);

                return newSelected;
            });
        },
        [paginatedData]
    );

    const handlePrev = () => {
        prevPage();
    };

    const handleNext = () => {
        nextPage();
    };

    const handleSort = (type: string) => {
        const sortedData = [...paginatedData].sort((a,b) => {
            if (type === 'lastUpdate') {
                const dateA: any = new Date(a.lastUpdate);
                const dateB: any = new Date(b.lastUpdate);
                return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            } else {
                return sortOrder === 'asc' ? a[type].localeCompare(b[type]) : b[type].localeCompare(a[type]);
            }
        });

        setPaginatedData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setArrowUp(!arrowUp);
        setSortField(type);
    };

    const handleMenuClick = () => {
        setshowMenu(!showMenu);
    };

    const createColumnClassName = (field: string) => {
        if (field === 'portfolioName') {
            return 'w-[25%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6';
        }
        if (field === 'lastUpdate') {
            return 'w-[25%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6';
        }
        if (field === 'team') {
            return 'w-[25%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6';
        }
        if (field === 'action') {
            return 'w-[25%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6';
        }
    };
    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />

            {/* dashboard main */}
            <div className='w-[calc(100%_-_75px)] ml-auto'>
                {/* Hedaer */}
                <Header title='Portfolios' count={projectData.length} isChildEle={false} />
                <div className='flex justify-between p-[24px] h-[80vh] w-full'>
                    <div className=' bg-white w-full'>
                        <div className='flex justify-between m-[20px] items-center'>
                            <div className=''>
                                <div className={`border hover:border-[#1e0af5] rounded-lg w-80 py-2 px-3.5 flex items-center gap-x-2 `}>
                                    <img src={allImgPaths.searchIcon} alt='search' />
                                    <input className='w-full' placeholder='Search' type='text' />
                                </div>
                            </div>
                            <div className='flex items-center justify-between  gap-x-[12px] '>
                                <img src={allImgPaths.rightCurvedIcon} alt='' />
                                <button className='cancle-btn-blue flex gap-x-2' onClick={() => setOpenAddToProjectModal(true)}>
                                    <img src={allImgPaths.folderPlusIcon} alt='' /> Add to Project
                                </button>
                                <button className='submit-btn-blue flex h-auto gap-x-2 !my-[15px] mx-[14px] !align-middle !items-center' onClick={() => setOpenNewPortFolioModal(true)}>
                                    <img src={allImgPaths.folderPlusWhiteIcon} alt='' /> Create Portfolio
                                </button>
                                {/* <span className='w-4 inline-block mr-0.5 mb-px'>
                                    <img className={`cursor-pointer ml-auto`} src={allImgPaths.verticleDotsGrey} onClick={handleMenuClick} alt='' />
                                </span> */}
                                {showMenu && <div></div>}
                            </div>
                        </div>

                        <div className='w-full'>
                            <div className=''>
                                <div className='overflow-x-scroll bg-white'>
                                    {/* Tabs Head */}
                                    <div className='flex flex-nowrap sticky top-0 z-10 checkbox-alignment2'>
                                        {projectColumns?.length > 0 &&
                                            projectColumns.map((column: any,columnIndex: number) => (
                                                <React.Fragment key={columnIndex}>
                                                    {
                                                        <div className={createColumnClassName(column.ref)}>
                                                            {/* To manage sort icon and column width */}
                                                            {column.ref === 'portfolioName' && (
                                                                <span className='font-interMedium text-sm text-[#4F4D55] flex items-center justify-start gap-x-[8px] w-[100%]'>
                                                                    <div className='relative inline-block'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={'select-all-' + columnIndex}
                                                                            name='checkbox-group'
                                                                            checked={selectAll}
                                                                            onChange={handleSelectAll}
                                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                            hover:border-indigo-600 
                                                                            checked:bg-indigo-600 checked:border-0 
                                                                            transition-colors cursor-pointer'
                                                                        />
                                                                        <svg
                                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                             inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                            viewBox='0 0 16 16'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'>
                                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                                        </svg>
                                                                    </div>
                                                                    {column.name}
                                                                    <img
                                                                        className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                        onClick={() => handleSort(column.ref)}
                                                                        src={allImgPaths.arrowUp3}
                                                                        alt=''
                                                                    />
                                                                </span>
                                                            )}

                                                            {column.ref === 'lastUpdate' && (
                                                                <span className=' font-interMedium text-sm text-[#4F4D55] flex items-center justify-start gap-x-[8px] w-[100%]'>
                                                                    {column.name}
                                                                    <img
                                                                        className={`w-4 cursor-pointer transform ${sortField === column.ref && sortOrder === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                        onClick={() => handleSort(column.ref)}
                                                                        src={allImgPaths.arrowUp3}
                                                                        alt=''
                                                                    />
                                                                </span>
                                                            )}

                                                            {/* Column with no sort icon*/}
                                                            {column.ref === 'team' && <span className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</span>}
                                                            {column.ref === 'action' && <span className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</span>}
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            ))}
                                    </div>

                                    {/* Body */}
                                    {
                                        <div className={'h-auto'}>
                                            {paginatedData.length > 0 &&
                                                paginatedData.map((row: any,rowIndex: number) => (
                                                    <React.Fragment key={rowIndex}>
                                                        <div className={`flex flex-nowrap`}>
                                                            {projectColumns?.length > 0 &&
                                                                projectColumns.map((column: any,columnIndex: number) => (
                                                                    <React.Fragment key={columnIndex}>
                                                                        {column.ref === 'portfolioName' && (
                                                                            <div className={`${createColumnClassName(column.ref)} table-row !justify-start`}>
                                                                                <span className='text-sm text-gray-600 flex items-start gap-x-2'>
                                                                                    <label htmlFor={'item' + rowIndex} className='flex gap-x-2 cursor-pointer'>
                                                                                        <div className='relative inline-block'>
                                                                                            <input
                                                                                                type='checkbox'
                                                                                                id={'item' + rowIndex}
                                                                                                name='checkbox-group'
                                                                                                checked={selectedItems.includes(rowIndex)}
                                                                                                onChange={() => handleSelectItem(rowIndex)}
                                                                                                className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                                checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                                                                transition-colors cursor-pointer'
                                                                                            />
                                                                                            <svg
                                                                                                className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                                   inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                                viewBox='0 0 16 16'
                                                                                                fill='none'
                                                                                                stroke='currentColor'
                                                                                                strokeWidth='2'
                                                                                                strokeLinecap='round'
                                                                                                strokeLinejoin='round'>
                                                                                                <polyline points='4 8 7 11 12 5'></polyline>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className='flex flex-col'>
                                                                                            <span className='text-gray-900 font-interRegular text-sm font-semibold leading-5 tracking-tight' onClick={() => navigate('/portfolio/' + rowIndex + '/details')}>{row[column.ref]}</span>
                                                                                            <span className='max-w-[14rem] truncate text-gray-500 font-interRegular text-sm font-semibold leading-5 tracking-tight flex-1'>{row.description}</span>
                                                                                        </div>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {/* lastUpdate */}
                                                                        {column.ref === 'lastUpdate' && (
                                                                            <div className={`${createColumnClassName(column.ref)} table-row !flex-col !items-start`}>
                                                                                <span className=' flex items-center gap-x-2 text-neutral-700 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                <span className=' flex items-center gap-x-2  font-inter text-sm font-normal leading-5 tracking-tight text-gray-500'> {row.name} </span>
                                                                            </div>
                                                                        )}
                                                                        {column.ref === 'team' && (
                                                                            <div className={`${createColumnClassName(column.ref)} flex items-center`}>
                                                                                {row[column.ref].map((item: any,index: number) => (
                                                                                    <img src={item.img} alt='' height={24} width={24} className='rounded-full cursor-pointer on-hover-border-shadow' />
                                                                                ))}
                                                                                <img src={allImgPaths.plusIcon} alt='' className=' p-1 rounded-full cursor-pointer !border !border-dashed !border-[#ccc] on-hover-border-shadow h-7 w-7 ' />
                                                                            </div>
                                                                        )}
                                                                        {column.ref === 'action' && (
                                                                            <div className={`${createColumnClassName(column.ref)} table-row justify-between`}>
                                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.eyeIcon} alt='' height={20} width={24} />
                                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.editIcon} alt='' height={20} width={24} />
                                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.trashIcon} alt='' height={20} width={24} />
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            {projectData.length === 0 && <div className='h-[calc(100vh_-_334px)]'>No data available</div>}
                                        </div>
                                    }
                                </div>
                                {/* Paginate */}
                                <div className={`flex items-center justify-between w-full p-[20px]`}>
                                    {
                                        <div className='text-sm text-gray-600'>
                                            Showing <b>{startItem}</b> to <b>{endItem}</b> of <b>{totalItems}</b> Results
                                        </div>
                                    }

                                    <div className='flex items-center gap-2'>
                                        <button
                                            onClick={handlePrev}
                                            disabled={currentPage === 1}
                                            className={`px-4 py-2 text-sm rounded border ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                                            Previous
                                        </button>

                                        <button
                                            onClick={handleNext}
                                            disabled={currentPage === totalPages}
                                            className={`px-4 py-2 text-sm rounded border ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openAddToProjectModal && <AddToProjectModal isShow={openAddToProjectModal} setIsShow={setOpenAddToProjectModal} setToast={setToast} />}
                {openNewPortFolioModal && <NewPortfolioModal isShow={openNewPortFolioModal} setIsShow={setOpenNewPortFolioModal} setToast={setToast} />}
            </div>
        </div>
    );
}
export default Portfolio;
