import React, {Dispatch, SetStateAction, useState} from 'react';

import {Modal} from './Modal';

import {HeaderModal} from './Header';
import {FooterModal} from './Footer';

import create from 'assets/images/create-claim.svg';
import close from 'assets/images/close-icon.svg';
import question from 'assets/images/help-circle-contained-filled.svg';
import dropArrow from 'assets/images/dropdown.svg';

import {Body} from './Body';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}

const ConfigureScoring = ({isShow, setIsShow, setToast}: Iprops) => {
    const [isOpen, setIsOpen] = useState(false);

    const onCloseModal = () => {
        setIsShow(false);
    };
    const [sliderValues, setSliderValues] = useState({
        specification: 95,
        prosecution: 90,
        precedential: 50,
        dictionary: 55,
        articleAccuracy: 65,
    });
    const handleSliderChange = (name: string, value: number) => {
        console.log(name, value, sliderValues, 'TEST');

        setSliderValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const RangeSlider = ({name, slug, value}: {name: string; slug: string; value: number}) => (
        <div className='flex flex-col'>
            <div className='mb-[8px]'>{name}</div>
            <div className='flex items-center justify-center gap-3'>
                <div className='relative w-full'>
                    <input
                        type='range'
                        min='0'
                        max='100'
                        value={value}
                        onChange={(e) => handleSliderChange(slug, parseInt(e.target.value))}
                        className='w-full h-2 cursor-pointer'
                        style={{
                            WebkitAppearance: 'none',
                            background: `linear-gradient(to right, #1e0af5 ${value}%, #E5E7EB ${value}%)`,
                            borderRadius: '9999px',
                        }}
                    />
                </div>
                <span className='text-sm font-bold'>{value}%</span>
            </div>
        </div>
    );

    return (
        <>
            <Modal className={'modal-wrapper max-w-[440px]'} isShow={isShow}>
                <form>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between'>
                            <img className='cursor-pointer w-12 h-12 mb-4' src={create} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-lg text-[#101828] font-bold leading-none pb-1.5 text-left pt-3 px-2'>Configure Scoring Weightage</h2>
                        <p className='text-md text-left text-[#475467] px-2'>4 matches found</p>
                    </HeaderModal>

                    {/* <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-2'>
                        <div className='flex flex-col gap-x-[48px]'>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Specification</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'95'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'95'}%, #E5E7EB ${'95'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Prosecution</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'90'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'90'}%, #E5E7EB ${'90'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Precedential</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'50'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'50'}%, #E5E7EB ${'50'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Precedential</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'50'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'50'}%, #E5E7EB ${'50'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Dictionary</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'50'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'50'}%, #E5E7EB ${'50'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='mb-[8px]'>Article Accuracy</div>
                                <div className='flex items-center justify-center gap-3'>
                                    <input
                                        type='range'
                                        id='confidence-slider'
                                        min='0'
                                        max='100'
                                        value={'50'}
                                        className='w-full h-2 cursor-pointer'
                                        style={{
                                            WebkitAppearance: 'none',
                                            background: `linear-gradient(to right, #9333EA ${'50'}%, #E5E7EB ${'50'}%)`,
                                            borderRadius: '9999px',
                                        }}
                                    />
                                    <span className='text-sm font-bold'>55%</span>
                                </div>
                            </div>
                        </div>
                    </Body> */}
                    <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-2'>
                        <div className='flex flex-col gap-y-4'>
                            <RangeSlider name='Specification' slug='specification' value={sliderValues.specification} />
                            <RangeSlider name='Prosecution' slug='prosecution' value={sliderValues.prosecution} />
                            <RangeSlider name='Precedential' slug='precedential' value={sliderValues.precedential} />
                            <RangeSlider name='Dictionary' slug='dictionary' value={sliderValues.dictionary} />
                            <RangeSlider name='ArticleAccuracy' slug='articleAccuracy' value={sliderValues.articleAccuracy} />
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center px-2'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Save
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default ConfigureScoring;
