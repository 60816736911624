import { axiosInstance, googleAuthInstance, defaultAxios } from "../axios";
import { signupField, signInField } from "../../types/index";

export const signUpService = async (data: signupField) => {
  try {
    return await axiosInstance.post(`user/register`, data);
  } catch (error) {
    throw error;
  }
};

export const signInService = async (data: signInField) => {
  try {
    return await axiosInstance.post(`user/login`, data);
  } catch (error) {
    throw error;
  }
};

export const fetchOrganizationRoles = async () => {
  try {
    return await axiosInstance.get(`user/get-organization-roles`);
  } catch (error) {
    throw error;
  }
};

export const verifyCodeService = async (userId: string, code: string) => {
  try {
    return await axiosInstance.put(`user/verify-code/${userId}`, {
      code: code,
    });
  } catch (error) {
    throw error;
  }
};

export const registerUser = async (userId: string, typeId: string) => {
  try {
    return await axiosInstance.put(`user/add-organization-type/${userId}`, {
      type_id: typeId,
    });
  } catch (error) {
    throw error;
  }
};

export const sendOtp = async (userId: string) => {
  try {
    return await axiosInstance.post(`user/resend-code`, { id: userId });
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordService = async (email: string) => {
  try {
    return await axiosInstance.post(`user/forgot-password`, email);
  } catch (error) {
    throw error;
  }
};

export const resetPasswordService = async (userId: string, data: { password: string, code: string }) => {
  try {
    return await axiosInstance.post(`user/reset-password/${userId}`, data);
  } catch (error) {
    throw error;
  }
};

export const getGoogleUserInfo = async (tokenResponse: any) => {
  try {
    return await googleAuthInstance.get('userinfo',
      {
        headers: {
          Authorization: `Bearer ${tokenResponse?.access_token}`
        }
      }
    )
  } catch (error) {
    throw error;
  }
}

export const googleAuthValidate = async (payload: any) => {
  try {
    return await axiosInstance.post(`user/google-auth-validate`, payload);
  } catch (error) {
    throw error;
  }
};

export const createTeamService = async (payload: any) => {
  try {
    return await axiosInstance.post(`user/add-user-team`, payload);
  } catch (error) {
    throw error;
  }
};

export const getPendingInvitations = async () => {
  try {
    return await axiosInstance.get(`user/get-pending-invitations`);
  } catch (error) {
    throw error;
  }
}

export const acceptPendingInvitations = async (payload: any) => {
  try {
    return await axiosInstance.post(`user/join-team`, payload);
  } catch (error) {
    throw error;
  }
}

export const generatePresignedUrl = async (fileData: any) => {
  try {
    return await axiosInstance.post('user/create-presigned-url', fileData);
  } catch (error) {
    throw error;
  }
}

export const uploadFileToS3 = async (imageData: any) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': imageData.type
      },
      body: imageData.file
    };

    const response = await fetch(imageData.url, requestOptions);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response;
  } catch (error) {
    console.log('error while uploading to s3 =>', error);
    throw error;
  }
}

export const getProfileImage = async (payload: any) => {
  try {
    return await axiosInstance.post(`/user/get-teams-logo`,
      payload
    );
  } catch (error) {
    throw error;
  }
}

// For Vendor's File Upload
export const generatePresignedUrlForVendor = async (fileData: any) => {
  try {
    return await axiosInstance.post('user/create-presigned-url-for-vendor', fileData);
  } catch (error) {
    throw error;
  }
}

export const uploadVendorFileToS3 = async (imageData: any) => {
  try {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': imageData.type,
        'x-amz-tagging': imageData.tagging
        // 'x-amz-tagging': 'type=rfp&actions=extraction_summarization'
        // 'x-amz-tagging': 'type=VenueVendorContract&actions=extraction_summarization'
        // 'x-amz-tagging': 'type=EVENT&actions=extraction_summarization'
      },
      body: imageData.file
    };

    const response = await fetch(imageData.url, requestOptions);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response;
  } catch (error) {
    console.log('error while uploading to s3 =>', error);
    throw error;
  }
}