interface ModalProps {
    children: React.ReactNode;
    className: string;
    isShow: boolean;
}

const Modal = ({ children, className, isShow }: ModalProps) => {
    return (<>
        {isShow && <div className={`modal relative ${isShow && "is-visible"} `}>
            <div className={` w-full rounded-lg ${className}`}>
                {children}
            </div>
        </div>}
    </>);
};

export { Modal };
