import React from 'react'

const ClaimStructure = ({data, index}: any) => {
  return (
    <div className='flex justify-center object-contain' key={index}>
      <img src={data.image} alt="structure" />
    </div>
  )
}

export default ClaimStructure