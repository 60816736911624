import { inputField } from "types";

const Textarea = ({
  label,
  style,
  name,
  value,
  placeholder,
  onChange,
  err,
  isDisabled = false,
  className,

}: inputField) => {


  return (
    <>
      <div className="relative">
        <div className="border border-[#D0D5DD] hover:border-[#1751D0] focus-within:border-[#1751D0] rounded-lg flex items-start gap-x-2 py-2 px-3">
          <textarea

            name="notes"
            maxLength={200}
            value={value}
            onChange={onChange}
            className="placeholder:text-[#667085] placeholder:text-base text-base text-[#344054] w-full h-[24px]"
            placeholder={placeholder}
            style={{
              width: "100%",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Textarea;