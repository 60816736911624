import mainLogo from "./login_logo.svg";
import closeIcon from "./close-icon.svg";
import logOutIcon from './logout-icon.svg';
import profileImgNew from "./profile-img-new.svg";
import settingsIcon from './settings.png';
import defaultTeamProfileImage from './team-profile-default-icon.png';
import arrowRightGrey from "./arrow-grey.svg";
import arrowRightBlue from "./arrow-blue.svg";
import circleBlack from "./plus-circle-black-icon.svg";
import searchIcon from "./search-small-icon.svg";
import threeVerticleDotsIcon from './three-dots-icon.svg';
import starGreyIcon2 from './star-grey2-icon.svg';
import trashIcon from './trash-01.svg';
import downloadIcon from './download-01.svg';
import editIcon from './edit-03.svg';
import printIcon from './print.svg';
import image1 from './Image1.png';
import image2 from './Image2.png';
import image3 from './Image3.png';
import structure from './Structure.png';
import dropdownArrow from './down-icon4.svg';
import goBack from './left-icon.svg';
import filter from './filter.svg';
import configure from './audio-settings-01.svg';
import confidence from './percent-03.svg';
import graphical from './Lead icon container.svg';
import right from './right-icon.svg';
import blueStar from './stars-01.svg';
import citeIcon from './cite.svg';
import vijilizeIcon from './vijilize.svg';
import homeIcon2 from './home-04.svg';
import boxIcon from './box.png';
import boxIconGrey from './box-icon-grey.svg';
import folderIcon from './folder-open-03.svg';
import arrowUp3 from './arrow-up-icon-3.svg';
import rightCurvedIcon from './arrow-curve-right-up.svg';
import eyeIcon from './eye-open.png';
import sliderImg from './Revenue-bro.svg';
import logo from './login_logo.svg';
import plusCircleBlueIcon from './plus-circle-blue-icon.svg';
import plusIcon from './plus-02.svg';
import folderPlusIcon from './folder-plus-01.svg';
import folderPlusWhiteIcon from './folder-plus-white.svg';

import rightSideIcon2 from './right-side-icon2.svg';
import mobileLogo from './login_logo.svg';
import miniLogo from './mini_logo.svg';
import greyHome from './home-04-filled.png';
import Image1 from './Image1.png';
import Image2 from './Image2.png';
import Image3 from './Image3.png';
import Structure from './Structure.png';
import Product1 from './product1.png';
import Product2 from './product2.png';
import Explanation1 from './exp1.png';
import Explanation2 from './exp2.png';

import confidenceWhite from './configure-white.svg';
import defaultProfile from './default-user.png';
import uploadFileIcon3 from './file-upload-icon-2.svg';
import breadCrumbHomeIcon from './breadcrumb_icon.svg';
import verticleDotsGrey from './vertical-dots-grey.svg';
import plusIconWhite from './plus-icon-white.svg';
import plusIcon3 from './plus-icon-3.svg';

import questionCircle from './help-circle-contained-filled.svg';
import uploadIcon from './upload-Icon.svg';
import filePlus2 from './file-plus-02.svg';
import inviteTeamProjectIcon from './invite-fete-icon.png';

import ChevronRight from './chevron-right.svg';
import ChevronDown from './angle-down-small.svg';
import FolderIcon from './folder.svg';
import FileIcon from './file.svg';
import backArrow from "./back-arrow.svg";
import EmptyStars from "./stars-empty.svg";

import pastFeteEmptyState from './past-fete-empty-state.svg';
import successToastIcon from './success-icon.svg';
import errorToastIcon from './error-icon.svg';
import warningToastIcon from './warning-icon.svg';
import infoToastIcon from './info-icon.svg';

import noProjects from './no-project-data.png';
import searchIcon2 from './search-lg.svg';
import searchIcon3 from './search-icon-border.svg';



const allImgPaths = (() => ({
	searchIcon3,
	EmptyStars,
	backArrow,
	plusIcon3,
	verticleDotsGrey,
	plusIconWhite,
	mainLogo,
	breadCrumbHomeIcon,
	boxIconGrey,
	closeIcon,
	logOutIcon,
	profileImgNew,
	settingsIcon,
	defaultTeamProfileImage,
	arrowRightGrey,
	arrowRightBlue,
	circleBlack,
	searchIcon,
	threeVerticleDotsIcon,
	starGreyIcon2,
	trashIcon,
	editIcon,
	downloadIcon,
	printIcon,
	image1,
	image2,
	image3,
	structure,
	dropdownArrow,
	goBack,
	filter,
	configure,
	confidence,
	graphical,
	right,
	blueStar,
	citeIcon,
	vijilizeIcon,
	homeIcon2,
	boxIcon,
	folderIcon,
	arrowUp3,
	rightCurvedIcon,
	eyeIcon,
	sliderImg,
	logo,
	plusCircleBlueIcon,
	rightSideIcon2,
	mobileLogo,
	miniLogo,
	plusIcon,
	folderPlusIcon,
	folderPlusWhiteIcon,
	greyHome,
	Image1,
	Image2,
	Image3,
	Structure,
	Product1,
	Product2,
	Explanation1,
	Explanation2,
	confidenceWhite,
	defaultProfile,
	uploadFileIcon3,
	questionCircle,
	uploadIcon,
	filePlus2,
	inviteTeamProjectIcon,
	ChevronRight,
	ChevronDown,
	FolderIcon,
	FileIcon,
	pastFeteEmptyState,
	successToastIcon,
	errorToastIcon,
	warningToastIcon,
	infoToastIcon,
	noProjects,
	searchIcon2


}))();
export default allImgPaths;
