import React,{ Children,useState } from 'react';
import allImgPaths from "assets/images";

// Default data structure with sample content
const defaultData = {
    name: 'root',
    type: 'folder',
    children: [
        {
            name: 'Device Holders',
            type: 'folder',
            children: [
                {
                    name: 'Files',
                    type: 'folder',
                    children: [
                        {
                            name: 'Legal',
                            type: 'folder',
                            children: [
                                { name: 'patents.pdf',type: 'file' }
                            ]
                        },
                        {
                            name: 'Agreements',
                            type: 'folder',
                            children: [
                                { name: 'agreement.pdf',type: 'file' }
                            ]
                        },

                    ]
                },
                {
                    name: 'Patents',
                    type: 'folder',
                    children: [
                        {
                            name: 'Legal',
                            type: 'folder',
                            children: [
                                { name: 'patents.pdf',type: 'file' }
                            ]
                        },
                        {
                            name: 'Agreements',
                            type: 'folder',
                            children: [
                                { name: 'agreement.pdf',type: 'file' }
                            ]
                        },

                    ]
                },

            ]
        },
        {
            name: 'Custom Handle Bar',
            type: 'folder',
            children: [
                { name: 'vacation-2023.jpg',type: 'file' },
                { name: 'family-portrait.png',type: 'file' }
            ]
        },
        {
            name: 'Speaker with AI assistant',
            type: 'folder',
            children: [
                { name: 'vacation-2023.jpg',type: 'file' },
                { name: 'family-portrait.png',type: 'file' }
            ]
        }
    ]
};

const FileExplorer = ({ initialData = defaultData }) => {
    const [expandedFolders,setExpandedFolders] = useState(new Set(['root']));

    const toggleFolder = (path: any) => {
        setExpandedFolders(prev => {
            const next = new Set(prev);
            if (next.has(path)) {
                next.delete(path);
            } else {
                next.add(path);
            }
            return next;
        });
    };

    const FileTreeNode = ({ node,level = 0,path = '' }: { node: any,level: number,path: string }) => {
        // Add a fallback for node in case it's undefined
        const safeNode = node || { name: 'Unknown',type: 'file' };

        const currentPath = `${path}/${safeNode.name}`;
        const isExpanded = expandedFolders.has(currentPath);
        const paddingLeft = `${level * 1.5}rem`;

        if (safeNode.type === 'file') {
            return (
                <div
                    className="flex items-center gap-x-2 py-1 hover:bg-gray-100 rounded cursor-pointer"
                    style={{ paddingLeft }}
                >
                    <img src={allImgPaths.FileIcon} height={24} width={24} alt="" />
                    <span className="font-interMedium text-base text-[#4F4D55]">{safeNode.name}</span>
                </div>
            );
        }

        return (
            <div className="w-full">
                <div
                    className="flex items-center py-1 gap-x-2 hover:bg-gray-100 rounded cursor-pointer"
                    style={{ paddingLeft }}
                    onClick={() => toggleFolder(currentPath)}
                >
                    {isExpanded ? (
                        <img src={allImgPaths.ChevronDown} height={24} width={24} alt="" />
                    ) : (
                        <img src={allImgPaths.ChevronRight} height={24} width={24} alt="" />
                    )}
                    {isExpanded ? (
                        <img src={allImgPaths.folderIcon} height={24} width={24} alt="" />
                    ) : (
                        <img src={allImgPaths.folderIcon} height={24} width={24} alt="" />
                    )}
                    <span className="font-interMedium text-base text-[#4F4D55]">{safeNode.name}</span>
                </div>
                {isExpanded && safeNode.children && safeNode.children.length > 0 && (
                    <div>
                        {safeNode.children.map((child: any,index: number) => (
                            <FileTreeNode
                                key={`${currentPath}/${child.name}-${index}`}
                                node={child}
                                level={level + 1}
                                path={currentPath}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="w-full border rounded-lg p-4 bg-white shadow-sm">
            <FileTreeNode node={initialData} level={0} path="" />
        </div>
    );
};

export default FileExplorer;