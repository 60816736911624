import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOrganizationRoles, registerUser } from "../../services/auth/auth";
import { checkBoxSchema } from "../../schema/validation";

const useRegisterHook = () => {
  const navigate = useNavigate();
  const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);
  const [organizationRole, setOrganizationRole] = useState([]);
  const initialSelectRoleDetails = { _id: "", name: "" }
  const [selectedRole, setSelectedRole] = useState(initialSelectRoleDetails);
  const [err, setError] = useState("");

  useEffect(() => {
    if (selectedCheckbox) {
      const data: any = organizationRole.find(
        (role: any) => role.name === selectedCheckbox
      );
      setSelectedRole(data);
    }
  }, [selectedCheckbox,organizationRole]);

  const resetStateToDefault = () => {
    setSelectedCheckbox(null);
    setOrganizationRole([]);
    setSelectedRole(initialSelectRoleDetails);
    setError('');
  } 

  const handleInputChange = (name: string) => {
    setSelectedCheckbox(name);
    setError("");
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    checkBoxSchema
      .validate({ userType: selectedRole.name })
      .then((data: any) => {
        if (data) {
          addUser();
        }
      })
      .catch((err: any) => {
        setError(err.message);
      });

    const addUser = async () => {
      try {
        const userId = localStorage.getItem("userId") as string;
        if(userId){
          const response = await registerUser(userId, selectedRole?._id);
          const userData = {
            accessToken: response.data.accessToken,
            name: response.data.name,
            refreshToken: response.data.refreshToken,
            role: response.data.role,
            id:response.data._id,
            profile_image:response.data.profile_image
          };
          localStorage.setItem("userData", JSON.stringify(userData));
          resetStateToDefault();
          if (response) {
            navigate("/");
          }
        } else {
          setError("Something went wrong.");
        }
        
      } catch (error: any) {
        console.log("Error while adding user=", error);
        setError(error.message);
      }
    };
  };

  const getRoles = async () => {
    try {
      const response = await fetchOrganizationRoles();
      setOrganizationRole(response.data);
    } catch (error) {
      console.log("error while fetching the user role =", error);
    }
  };

  return {
    handleInputChange,
    handleFormSubmit,
    getRoles,
    organizationRole,
    selectedCheckbox,
    err,
  };
};

export default useRegisterHook;
