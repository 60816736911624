import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
import { signupSchema } from "../../schema/validation";
import { signupField } from "../../types";
import {
  getGoogleUserInfo,
  googleAuthValidate,
  signUpService,
} from "../../services/auth/auth";
import {
  checkAllFieldSame,
  emailValidation,
  nameValidation,
  passwordValidation,
} from "../../utils/function";
import { signupValidationText } from "../../utils/constant";

const useSignupHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialSignupDetails = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const initialShowEyeOptions = { password:false, confirmPass: false }
  const [userData, setUserData] = useState(initialSignupDetails);
  const [error, setError] = useState(initialSignupDetails);
  const [errMsg, setErrMsg] = useState("");
  const [showEyeBtn, setShowEyeButton] = useState(initialShowEyeOptions);
  const [passwordRule, setPasswordRule] = useState<boolean[]>([]);
  const [showPasswordRule, setShowPasswordRule] = useState(false);
  const [emailExistPopup, setEmailExistPopup] = useState(false);
  const [emailExistButNotVerified, setEmailExistButNotVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const invitorId = searchParams.get('invitor_id');
  const inviteeEmail = searchParams.get('invitee_email');

  if(inviteeEmail && !userData.email){
    setUserData(({...userData, email:inviteeEmail}))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    // setError({ ...error, [name]: "" });
    if (name == "name") {
      const isValid = nameValidation(value);
      if (!isValid) {
        setError({ ...error, name: signupValidationText.nameText });
      } else {
        setError({ ...error, name: "" });
      }
    } else if (name === "email") {
      if(emailValidation(value)){
        setError({ ...error, email: "" });
      } else{
          setError({...error, email:signupValidationText.emailText})
      }
    }

    // password field custom validation
     if (name === "password") {
      setShowPasswordRule(true);
      const results = passwordValidation(value);

	  if(passwordRule.length>0 && checkAllFieldSame(passwordRule)) {
		setShowPasswordRule(false);
	  } else {
		setShowPasswordRule(true);
	  }
	  
      setPasswordRule(results);
    } else{
      setShowPasswordRule(false);
    }

    if(name === "confirmPassword") {
      if(userData['password'] !== value) {
        setError({ ...error, confirmPassword: signupValidationText.confirmPassword });
      } 
      if(userData['password'] === value) {
        setError({ ...error, confirmPassword: '' });
      }
    }
  };

  const handleShowPassWordRule = () => {
    setShowPasswordRule(!showPasswordRule);
  };

  const handleDismiss = () => {
    setEmailExistPopup(false);
    setEmailExistButNotVerified(false);
  }

  const handleLoginRedirect = () => {
    handleDismiss();
    navigate('/login');
  }

  const handlePassEyeBtn = () => {
    setShowEyeButton({...showEyeBtn, password:!showEyeBtn.password});
  };

  const handleConfirmPassEyeBtn = () => {
    setShowEyeButton({...showEyeBtn, confirmPass:!showEyeBtn.confirmPass});
  }

  const resetStateToDefaults = () => {
    setUserData(initialSignupDetails);
    setError(initialSignupDetails);
    setEmailExistPopup(false);
    setEmailExistButNotVerified(false);
    setErrMsg('');
    setShowEyeButton(initialShowEyeOptions);
    setPasswordRule([]);
    setShowPasswordRule(false);
    setEmailExistPopup(false);
    setEmailExistButNotVerified(false);
  }

  useEffect(() => {
    resetStateToDefaults();
  }, [location.pathname]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signupSchema
      .validate(userData,{ abortEarly: false })
      .then((data: any) => {
        let payload: any = {
          name: data.name,
          email: data.email,
          password: data.password,
        };
        if(invitorId) {
          payload.created_by = invitorId;
          // payload.team_id = teamId;
        }
        JSON.stringify(localStorage.setItem("userEmail", data.email));
        //API call
        signUp(payload);
      })
      .catch((err: any) => {
        const fieldErrors:any = {};
        err.inner.forEach((validationError: any) => {
          fieldErrors[validationError.path] = validationError.message;
        });
        if(fieldErrors.name){
          setError({ ...error, name: fieldErrors.name });
        } else if (fieldErrors.email) {
          setError({ ...error, email: fieldErrors.email });
        } else if (fieldErrors.password) {
          setError({ ...error, password: fieldErrors.password });
        } else if (fieldErrors.confirmPassword) {
          setError({ ...error, confirmPassword: fieldErrors.confirmPassword });
        } 
      });
  };

  const signUp = async (data: signupField) => {
    try {
      const response: any = await signUpService(data);
      if (response.data.user_id || response.data._id) {
        JSON.stringify(localStorage.setItem("userId", response.data.user_id));
        if(response.data?.accessToken && response.data?.refreshToken) {
          const userData = {
            accessToken: response.data.accessToken,
            name: response.data.name,
            refreshToken: response.data.refreshToken,
            role: response.data.role,
            id: response.data._id,
            profile_image: response.data.profile_image,
          };
          JSON.stringify(localStorage.setItem("userEmail", response.data.email));
          localStorage.setItem("userData", JSON.stringify(userData));
        }
        resetStateToDefaults();
        if(inviteeEmail) {
          navigate('/select-team');
        } else {
          navigate("/verifyuser");
        }
      }
    } catch (error: any) {
      console.log("error while signup user =", error);
      if (error.statusText === "USER_EXISTS") {
        setEmailExistPopup(true);
      } else if (error.statusText === "USER_NOT_VERIFIED") {
        setEmailExistButNotVerified(true);
      } else {
        setErrMsg(error.message);
      }
    }
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse: any) => {
  //     try {
  //       const userInfo: any = await getGoogleUserInfo(tokenResponse);
  //       const userData = userInfo?.data;

  //       const payloadData = {
  //         email: userData.email,
  //         name: userData.name,
  //         picture: userData.picture,
  //         created_by: null,
  //         token: null,
  //       };

  //       const apiResponse = await googleAuthValidate(payloadData);
  //       const responseData = apiResponse.data;

  //       if (responseData?.statusText == "ORGANIZATION_TYPE_NOT_SET") {
  //         JSON.stringify(
  //           localStorage.setItem("userId", apiResponse.data.user_id)
  //         );
  //         navigate("/register");
  //       } else {
  //         const userData = {
  //           accessToken: responseData.accessToken,
  //           name: responseData.name,
  //           refreshToken: responseData.refreshToken,
  //           role: responseData.role,
  //           id: responseData._id,
  //           profile_image: responseData.profile_image,
  //         };
  //         JSON.stringify(localStorage.setItem("userEmail", responseData.email));
  //         localStorage.setItem("userData", JSON.stringify(userData));
  //         navigate('/team-setup');
  //       }
  //     } catch (error: any) {
  //       setErrMsg(error.message);
  //     }
  //   },
  //   onError: (errorResponse) => console.log({ errorResponse }),
  // });

  return {
    handlePassEyeBtn,
    handleConfirmPassEyeBtn,
    handleInputChange,
    handleShowPassWordRule,
    handleFormSubmit,
    handleDismiss,
    setShowPasswordRule,
    error,
    userData,
    errMsg,
    showEyeBtn,
    passwordRule,
    showPasswordRule,
    emailExistPopup,
    emailExistButNotVerified,
    handleLoginRedirect,
    inviteeEmail
  };
};
// googleLogin

export default useSignupHook;
