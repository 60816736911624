import allImgPaths from 'assets/images';

const SearchBar = ({img_url, placeholder, width, extra_class}:any) => {
    return (
        <>
            <div className={`border hover:border-[#1751D0] rounded-lg ${width} py-2 px-3.5 flex items-center gap-x-2 ${extra_class}`}>
                <img src={img_url} alt='search' />
                <input className='w-full' placeholder={placeholder} type='text' />
            </div>
        </>
    )
}

export default SearchBar;