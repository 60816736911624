import React,{ Dispatch,SetStateAction,useEffect,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import { Body } from './Body';
import allImgPaths from "assets/images";
import FileExplorer from "../Explorer";


const FileUploadProgress = ({ files }: { files: any[] }) => {

    return (
        <div className="space-y-4 overflow-scroll max-h-[240px]">

            {files.map((file,index) => (
                <div key={index} className="bg-white rounded-lg p-4 shadow-sm">


                    <div className="flex items-start justify-between">
                        <div className="flex items-center space-x-3">
                            <div className="text-red-500">
                                <svg
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M4 4v12a2 2 0 002 2h8a2 2 0 002-2V8.342a2 2 0 00-.602-1.43l-4.44-4.342A2 2 0 009.56 2H6a2 2 0 00-2 2z" />
                                </svg>
                            </div>
                            <div>
                                <div className="font-medium text-gray-900">{file.name}</div>
                                <div className="text-sm text-gray-500">{file.size}</div>
                            </div>
                        </div>
                        <button className="text-gray-400 hover:text-gray-600">
                            X
                        </button>
                    </div>

                    <div className="mt-4">
                        <div className="relative">
                            <div className="h-2 bg-gray-200 rounded-full">
                                <div
                                    className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                                    style={{ width: `${file.progress || 30}%` }}
                                />
                            </div>
                        </div>
                        <div className="mt-2 flex justify-between items-center">
                            <span className="text-sm text-gray-500">{file.progress || 30}%</span>
                            {file.progress === 100 && (
                                <span className="text-sm text-green-500 flex items-center">
                                    <svg
                                        className="w-4 h-4 mr-1"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    Complete
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}



function AddSource({ isShow,setIsShow,setToast }: Iprops) {

    const [toggle,setToggle] = useState(0);
    const [option,setOption] = useState('with patent');
    const [previewSrc,setPreviewSrc] = useState<string | null>(null);
    const [selectedFiles,setSelectedFiles] = useState<any[]>([]);
    const [filePreviews,setFilePreviews] = useState<any[]>([]);

    const onCloseModal = () => {

        setIsShow(false);
        setSelectedFiles([]);
        setFilePreviews([]);


    };

    // const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const file = e.dataTransfer.files[0];
    //     displayPreview(file);
    // };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let recievedFiles: any[] = (e.target.files || []) as any[];
        const files = Array.from(recievedFiles);
        handleFiles(files);
    };
    const handleFiles = async (files: any[]) => {
        // Filter files if needed (e.g., specific file types)
        const allowedTypes = ['application/pdf','image/jpeg','image/png']; // Example file types
        let validFiles = files;


        // Update state with new files




        const previewPromises: Promise<string>[] = [];

        validFiles.forEach((file) => {
            const promise = new Promise<string>((resolve) => {
                displayPreview(file,(preview) => {
                    resolve(preview);
                });
            });
            previewPromises.push(promise);
        });
        setSelectedFiles(prevFiles => [...prevFiles,...validFiles]);
        Promise.all(previewPromises).then((previews) => {
            setFilePreviews(prevFiles => [...prevFiles,...previews]);
        });


    };

    // const displayPreview = (file: File) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onloadend = () => {
    //         if (reader.result) {
    //             // setPreviewSrc(reader.result as string);
    //             return (reader.result as string);
    //         }
    //         else {
    //             return '';
    //         }
    //     };
    // };

    const displayPreview = (file: File,callback: (preview: string) => void) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (reader.result) {
                callback(reader.result as string);
            } else {
                callback('');
            }
        };
    };



    return (
        <>
            <Modal className={'modal-wrapper max-w-[580px]'} isShow={isShow}>
                <form>
                    {/* Modal bg Shade */}

                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6 pb-3'>
                        <div className='flex items-center justify-between'>
                            <img className='cursor-pointer mb-4' src={allImgPaths.filePlus2} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={allImgPaths.closeIcon} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-lg text-[#101828] font-bold leading-none pb-1.5 text-left pt-3 px-2'>Add source patent</h2>
                        <p className='text-sm text-left text-[#475467] px-2'>Add patent number, upload patent file or bring in from existing portfolios.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-4'>
                        <div className="modal-container flex flex-col gap-y-[24px] mx-[24px] my-[16px]">
                            {/* Top Radio Part */}
                            <div className="flex gap-x-[24px]">

                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>
                                        {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'with patent' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'with patent' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(0);
                                                setOption('with patent');
                                            }}
                                            checked={option === 'with patent'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>With Patent</h5>

                                </label>
                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>
                                        {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'from portfolio' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'from portfolio' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'from portfolio' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'from portfolio' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(1);
                                                setOption('from portfolio');
                                            }}
                                            checked={option === 'from portfolio'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>From Portfolio</h5>

                                </label>
                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>
                                        {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'external upload' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'external upload' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'external upload' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'external upload' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(2);
                                                setOption('external upload');
                                            }}
                                            checked={option === 'external upload'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>External Upload</h5>

                                </label>
                            </div>

                            {toggle == 0 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                    Enter Patent #
                                    <img src={allImgPaths.questionCircle} alt='help' />
                                </div>
                                <div className="">
                                    <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Enter patent numbers separated by commas'} />
                                </div>
                            </div>}

                            {toggle == 1 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div className="w-full">
                                    <FileExplorer />
                                </div>
                            </div>}

                            {toggle == 2 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div
                                    className='relative border-2 border-[#C9C9CC] hover:border-gray-400 border-dashed rounded-lg p-2 w-full'
                                    id='dropzone'
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnter={(e) => e.preventDefault()}
                                    onDragLeave={() => { }}
                                    onDrop={handleDrop}>
                                    <input id='photo-dropbox' type='file' multiple accept=".png,.jpg,.pdf,.jpeg,.svg" className='absolute inset-0 w-full h-full opacity-0 z-50' onChange={handleFileChange} />
                                    <label className='flex cursor-pointer gap-1 w-full px-3 py-2 bg-[#F9FAFB] rounded-lg' htmlFor='photo-dropbox'>
                                        <input id='photo-dropbox' type='file' multiple accept=".png,.jpg,.pdf,.jpeg,.svg" className='sr-only' onChange={(e) => console.log(e.target.files)} />
                                        <img src={allImgPaths.uploadIcon} alt='upload.svg' />
                                        <span className='px-2'>
                                            Drag & drop patent file or <span className='text-[#1751D0]'>browse files</span>
                                        </span>
                                    </label>

                                </div>
                                {/* {filePreviews.length > 0 && filePreviews.map((file) => (
                                    <>
                                        <img id={'preview'} src={file || ''} className={`mt-4 mx-auto max-h-4 ${file ? '' : 'hidden'}`} alt='Preview' />
                                    </>
                                )
                                )} */}
                                {selectedFiles.length > 0 &&

                                    <FileUploadProgress files={selectedFiles} />}
                            </div>}
                        </div>

                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-8 px-6 pb-4'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Continue
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    )

}
export default AddSource;