import React,{ Dispatch,SetStateAction,useEffect,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import { Body } from './Body';
import allImgPaths from "assets/images";
import FileExplorer from "../Explorer";


interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}



function AddComparisonData({ isShow,setIsShow,setToast }: Iprops) {

    const [toggle,setToggle] = useState(0);
    const [option,setOption] = useState('with patent');
    const [previewSrc,setPreviewSrc] = useState<string | null>(null);
    const [selectedFiles,setSelectedFiles] = useState<any[]>([]);
    const [filePreviews,setFilePreviews] = useState<any[]>([]);

    const onCloseModal = () => {

        setIsShow(false);
        setSelectedFiles([]);
        setFilePreviews([]);


    };

    // const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const file = e.dataTransfer.files[0];
    //     displayPreview(file);
    // };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let recievedFiles: any[] = (e.target.files || []) as any[];
        const files = Array.from(recievedFiles);
        handleFiles(files);
    };
    const handleFiles = async (files: any[]) => {
        // Filter files if needed (e.g., specific file types)
        const allowedTypes = ['application/pdf','image/jpeg','image/png']; // Example file types
        let validFiles = files;


        // Update state with new files




        const previewPromises: Promise<string>[] = [];

        validFiles.forEach((file) => {
            const promise = new Promise<string>((resolve) => {
                displayPreview(file,(preview) => {
                    resolve(preview);
                });
            });
            previewPromises.push(promise);
        });
        setSelectedFiles(prevFiles => [...prevFiles,...validFiles]);
        Promise.all(previewPromises).then((previews) => {
            setFilePreviews(prevFiles => [...prevFiles,...previews]);
        });


    };

    // const displayPreview = (file: File) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onloadend = () => {
    //         if (reader.result) {
    //             // setPreviewSrc(reader.result as string);
    //             return (reader.result as string);
    //         }
    //         else {
    //             return '';
    //         }
    //     };
    // };

    const displayPreview = (file: File,callback: (preview: string) => void) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (reader.result) {
                callback(reader.result as string);
            } else {
                callback('');
            }
        };
    };



    return (
        <>
            <Modal className={'modal-wrapper max-w-[700px]'} isShow={isShow}>
                <form>
                    {/* Modal bg Shade */}

                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between'>
                            <img className='cursor-pointer mb-4' src={allImgPaths.filePlus2} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={allImgPaths.closeIcon} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-lg text-[#101828] font-bold leading-none pb-1.5 text-left pt-3 px-2'>Add Comparison Data</h2>
                        <p className='text-sm text-left text-[#475467] px-2'>Add Files, patents, URLs to compare with the source patent</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-4'>
                        <div className="modal-container flex flex-col gap-y-[24px] mx-[24px] my-[16px]">
                            {/* Top Radio Part */}
                            <div className="flex gap-x-[24px]">

                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>
                                        {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'with patent' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'with patent' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(0);
                                                setOption('with patent');
                                            }}
                                            checked={option === 'with patent'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>With Patent</h5>

                                </label>
                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>
                                        {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'from portfolio' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'from portfolio' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'from portfolio' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'from portfolio' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(1);
                                                setOption('from portfolio');
                                            }}
                                            checked={option === 'from portfolio'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>From Portfolio</h5>

                                </label>
                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>

                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'external upload' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'external upload' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(2);
                                                setOption('external upload');
                                            }}
                                            checked={option === 'external upload'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>External Upload</h5>
                                </label>
                                <label className='flex  cursor-pointer justify-start items-center'>

                                    <div className='relative flex items-center justify-center mt-1'>

                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
    ${option === 'paste url' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'paste url' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(3);
                                                setOption('paste url');
                                            }}
                                            checked={option === 'paste url'}
                                        />
                                    </div>
                                    <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>Paste URLs</h5>
                                </label>
                            </div>

                            {toggle == 0 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                    Enter Patent #
                                    <img src={allImgPaths.questionCircle} alt='help' />
                                </div>
                                <div className="">
                                    <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Enter patent numbers separated by commas'} />
                                </div>
                            </div>}

                            {toggle == 1 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div className="w-full">
                                    <FileExplorer />
                                </div>
                            </div>}

                            {toggle == 2 && <div className="p-[12px] flex flex-col gap-[4px]">
                                External Upload
                            </div>}
                            {toggle == 3 && <div className="p-[12px] flex flex-col gap-[4px]">
                                <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                    URL
                                    <img src={allImgPaths.questionCircle} alt='help' />
                                </div>
                                <div className="">
                                    <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Paste URL'} />
                                </div>
                            </div>}
                        </div>

                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-8 px-6 pb-4'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Continue
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    )

}
export default AddComparisonData;