import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import allImgPaths from "assets/images";
import { DOMAttributes, useState } from "react";

interface CustomToastMessageProps {
  heading: string;
  message: string;
  iconPath: string;
}
interface ToastMethodProps {
  type:string
  heading:string,
  message:string
}

const CustomToastMessage = ({ message, heading, iconPath }: CustomToastMessageProps) => {
  return (
    <div className="flex items-start gap-x-2">
      <img className="-mt-2" src={iconPath} alt="icon" />
      <div className="">
        <h4 className="text-sm text-[#101828] font-interSemiBold pb-[3px]">{heading}</h4>
        <p className="text-sm text-[#344054] pb-1.5">{message}</p>
      </div>
    </div>
  );
};

const showToast = ({type, heading, message}:ToastMethodProps) => {
  switch (type) {
    case 'success':      
      toast.success(
        <CustomToastMessage
          iconPath={allImgPaths.successToastIcon}
          heading={heading}
          message={message}
        />
      );
      break;
    case 'error':
      toast.error(
        <CustomToastMessage
          iconPath={allImgPaths.errorToastIcon}
          heading={heading}
          message={message}
        />
      );
      break;
    case 'warning':
      toast.warning(
        <CustomToastMessage
          iconPath={allImgPaths.warningToastIcon}
          heading={heading}
          message={message}
        />
      );
      break;
    case 'info':
      toast.info(
        <CustomToastMessage
          iconPath={allImgPaths.infoToastIcon}
          heading={heading}
          message={message}
        />
      );
      break;
    default:
      break;
  }

};

const Toast = () => {
  return showToast;
}

Toast.success = (heading:string, message:string) => {  
  showToast({type:'success', heading, message});
};

Toast.error = (heading:string, message:string) => {
  showToast({type:'error', heading, message});
};

Toast.warning = (heading:string, message:string) => {
  showToast({type:'warning', heading, message});
};

Toast.info = (heading:string, message:string) => {
  showToast({type:'info', heading, message});
};

export default Toast;
