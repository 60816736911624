import React from 'react'

const ClaimGraphics = ({ data, index }: any) => {
    return (
        <div className='flex flex-col justify-center items-center' key={index}>
            <img src={data.image} alt="product" className="" />
            <h5>Fig. {index + 1} <span className='text-[#7E8C9A]'>{data.title}</span></h5>
        </div>
    )
}

export default ClaimGraphics