import React from 'react';
const PageNotFound = () => {
    return (
        <div className='text-center mt-96'>
            <h1 className='font-bold'>404 Error</h1>
            <h1 className='font-bold'>Page Not Found</h1>
        </div>
    );
};

export default PageNotFound;
