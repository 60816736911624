import React from 'react';

const ProductCard = ({data, showExplanation, addClass, index, checked, onChange}: any) => {
    const handleScoreChange = (e: any) => {
        console.log('New score:', e);
    };
    return (
        <div className={`border border-[#EADEF0] ${addClass}`}>
            {showExplanation ? (
                <div className='relative'>
                    <input type='checkbox' checked={checked} onChange={onChange} className='absolute top-2 left-2 z-10 w-5 h-5' />
                    <img src={data.image} alt='product' className='object-cover w-full' />
                </div>
            ) : (
                <img src={data.image} alt='product' className='object-cover w-full' />
            )}

            <div className='p-1 flex flex-col gap-2'>
                <h5 className='text-xl font-semibold text-left flex gap-2 items-center'>
                    {showExplanation ? <span className='px-2 bg-[#7828C8] text-white'>{index + 1}</span> : <></>} {data.title}
                </h5>
                <h6 className='text-left'>
                    <span className='bg-gray-50 text-sm p-1 border rounded-md'>{data.tag}</span>
                </h6>
                <a href={data.url} className='text-left text-sm text-[#006FEE] '>
                    {data.url}
                </a>
                {showExplanation && (
                    <>
                        <h6 className='text-base text-[#555F6D] font-semibold text-left'>Overlap with source</h6>
                        <p className='text-left text-[#71717A]'>{data.explanation}</p>
                    </>
                )}
                <div className='px-2 w-full max-w-md'>
                    <label htmlFor='confidence-slider' className='block text-gray-500 text-sm text-left mb-2'>
                        {showExplanation ? 'Overlap score' : 'Confidence Score'}
                    </label>
                    <div className='relative flex items-center justify-center gap-3'>
                        <div className='relative w-full'>
                            {/* Background track */}
                            <div className='absolute w-full h-2 bg-gray-200 rounded-full' />

                            {/* Colored progress track */}
                            <div className='absolute h-2 bg-[#1e0af5] rounded-full' style={{width: `${data.score}%`}} />

                            {/* Custom styled range input */}
                            <input
                                type='range'
                                min='0'
                                max='100'
                                value={data.score}
                                className='w-full h-2 cursor-pointer'
                                style={{
                                    WebkitAppearance: 'none',
                                    background: `linear-gradient(to right, #1e0af5 ${data.score}%, #E5E7EB ${data.score}%)`,
                                    borderRadius: '9999px',
                                }}
                            />
                        </div>
                        <span className='text-sm font-bold min-w-[40px]'>{data.score}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
