import React, {useState} from 'react';

import ProductCard from 'components/ProductCard';
import CreateClaimChart from 'components/common/modals/CreateClaimChart';
import ClaimGraphics from 'components/ClaimGraphics';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import ConfigureScoring from 'components/common/modals/ConfigureScoring';
import {useNavigate} from 'react-router-dom';
import allImgPaths from 'assets/images';

const ClaimAnalysisVijilize = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    const [selectedCount, setSelectedCount] = useState(0);
    const [checkedItems, setCheckedItems] = useState<{[key: number]: boolean}>({});
    const [toast, setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });
    const navigate = useNavigate();

    const [openCreateClaimModal, setCreateClaimModal] = useState(false);
    const [openConfigureScoreModal, setConfigureScoreModal] = useState(false);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleCheckboxChange = (index: number) => {
        setCheckedItems((prevCheckedItems) => {
            const isChecked = !prevCheckedItems[index];
            const newCheckedItems = {...prevCheckedItems, [index]: isChecked};
            setSelectedCount(isChecked ? selectedCount + 1 : selectedCount - 1);
            return newCheckedItems;
        });
    };

    const claimData = [
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 80,
            confidence: 99,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 76,
            confidence: 95,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 94,
            confidence: 99,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 80,
            confidence: 99,
        },
    ];

    const claimGraphicsData = [
        {
            image: allImgPaths.Image1,
            title: 'Source Description comes here',
        },
        {
            image: allImgPaths.Image2,
            title: 'Source Description comes here',
        },
        {
            image: allImgPaths.Image3,
            title: 'Source Description comes here',
        },
    ];

    const productData = [
        {
            image: allImgPaths.Product1,
            title: 'Otterbox',
            tag: 'Manufacturer',
            url: 'https://www.otterbox.com/en-us/popsockets-otterbox',
            score: 58,
            explanation: 'The accused instrumentality is a collapsing and expanding one hand gripping apparatus that is fastened to and built into the back of a handheld device case.',
            demoimg: allImgPaths.Explanation1,
        },
        {
            image: allImgPaths.Product2,
            title: 'Walmart',
            tag: 'Retailer',
            url: 'https://www.walmart.com/ip/Otter-Pop-Defender-Series- Case-iPhone-11-Pro-Max-Howler-Grey/182980406 ',
            score: 98,
            explanation: 'The accused instrumentality is a collapsing and expanding one hand gripping apparatus that is fastened to and built into the back of a handheld device case.',
            demoimg: allImgPaths.Explanation2,
        },
    ];

    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />
            <div className='w-[calc(100%_-_75px)] h-[100vh] ml-auto'>
                {/* Hedaer */}
                <Header title='Vijilize' count={0} isChildEle={true} />
                <div className='pt-6 px-8 h-[calc(100vh_-_125px)] overflow-y-scroll scrollbar-adjust'>
                    {/* Heading */}
                    <div className='flex justify-between items-center w-full mb-10'>
                        <div className='flex items-start gap-4'>
                            <img className='pt-3 cursor-pointer' src={allImgPaths.goBack} onClick={() => navigate(-1)} alt='return' />
                            <div className=''>
                                <h2 className='text-[#101828] text-xl font-bold text-left'>Claim Analysis for Patent # US10800024</h2>
                                <p className='text-lg text-[#475467] text-left'>Description of the patent comes here</p>
                            </div>
                        </div>
                        <div className='relative font-interRegular'>
                            <div className='flex gap-2 items-center'>
                                {/* Checkbox Count */}
                                <h2 className='px-2 text-[#344051]'>{selectedCount} product selected</h2>

                                {/* Create Claim Chart */}
                                <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] flex items-center gap-2 font-interSemiBold rounded-lg  px-3.5 py-2.5`} onClick={() => setCreateClaimModal(true)}>
                                    <img src={allImgPaths.confidenceWhite} alt='' />
                                    Create Claim Chart
                                </button>

                                {/* Configure Scoring Button */}
                                <button className={`flex items-center gap-2 border font-interSemiBold !text-base rounded-lg  px-3.5 py-2.5 hover:border-black hover:border-2`} onClick={() => setConfigureScoreModal(true)}>
                                    <img src={allImgPaths.configure} alt='' />
                                    Configure Scoring
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Body */}
                    <div className='flex gap-4'>
                        {/* First Section */}
                        <div className='w-[40%] flex flex-col gap-3'>
                            {claimData.map((data, index) => {
                                return (
                                    <div key={index} className='flex flex-col border'>
                                        <button type='button' onClick={() => toggleAccordion(index)} className='px-3 border-b py-5 font-bold text-lg text-left flex justify-between'>
                                            <div className='flex items-center gap-2'>
                                                <input type='checkbox' className='w-4 h-4' id={'claim-' + (index + 1)} name={'claim-' + (index + 1)} />
                                                <label>
                                                    <span>Claim {index + 1}</span>
                                                </label>
                                            </div>
                                            <div className='flex gap-3 items-center'>
                                                <div className='flex gap-6 items-center'>
                                                    <h3>
                                                        Similarity Score : <span className={`px-2 ${data.similarity > 90 ? 'text-green-500' : 'text-red-500'}`}>{data.similarity} %</span>
                                                    </h3>
                                                    <h3>
                                                        Confidence Score : <span className={`px-2 ${data.confidence > 90 ? 'text-green-500' : 'text-red-500'}`}>{data.confidence} %</span>
                                                    </h3>
                                                </div>
                                                <img src={allImgPaths.dropdownArrow} alt='dropdown-svg' className={`w-4 h-4 shrink-0 transition-transform duration-300 ${activeIndex === index ? 'rotate-180' : ''}`} />
                                            </div>
                                        </button>
                                        <div className={`overflow-hidden transition-all duration-300 ${activeIndex === index ? 'max-h-[300px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                            <p className='text-left py-2 px-3 tracking-wide text-lg'>
                                                <span className='text-[#196742]'>{data.green[0]}</span> <span className='text-[#006FEE]'>{data.blue}</span> <span className='text-[#C53434]'>{data.red}</span>{' '}
                                                <span className='text-[#196742]'>{data.green[1]}</span> <span className='text-purple-700'>{data.purple}</span>{' '}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Second Section */}
                        <div className='w-[30%] flex flex-col gap-5 border p-4 rounded-md'>
                            <div className='flex flex-col gap-5'>
                                {claimGraphicsData.map((data, index) => {
                                    return <ClaimGraphics data={data} index={index} />;
                                })}
                            </div>
                        </div>

                        {/* Third Section */}
                        <div className='w-[30%] flex justify-center flex-col gap-5 border p-4 rounded-md'>
                            <div className='text-left'>
                                <h2 className='font-bold text-lg'>Covered Products</h2>
                                <h5 className='text-sm'>{productData.length} matches found</h5>
                            </div>
                            {productData.map((data, index) => {
                                return (
                                    <div key={index} className='flex gap-5'>
                                        <ProductCard data={data} showExplanation={true} addClass='w-full' index={index} checked={!!checkedItems[index]} onChange={() => handleCheckboxChange(index)} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {openCreateClaimModal && <CreateClaimChart isShow={openCreateClaimModal} setIsShow={setCreateClaimModal} setToast={setToast} />}
                    {openConfigureScoreModal && <ConfigureScoring isShow={openConfigureScoreModal} setIsShow={setConfigureScoreModal} setToast={setToast} />}
                </div>
            </div>
        </div>
    );
};

export default ClaimAnalysisVijilize;
