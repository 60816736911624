import { useEffect,useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { signInSchema } from "../../schema/validation";
import { signInField } from "../../types";
import { signInService } from "../../services/auth/auth";
import { ORGANIZATION_SET_ERROR,USER_NOT_ACTIVE,signupValidationText } from "../../utils/constant";

import { emailValidation } from "../../utils/function";

const useSigninHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialSignupDetails = {
    email: "",
    password: "",
  };
  const initialShowEyeOptions = { password: false }
  const [userData,setUserData] = useState(initialSignupDetails);
  const [error,setError] = useState(initialSignupDetails);
  const [errMsg,setErrMsg] = useState("");
  const [showEyeBtn,setShowEyeButton] = useState(initialShowEyeOptions);
  const [userInfo,setUserInfo] = useState();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name,value } = e.target;
    setUserData({ ...userData,[name]: value });
    setError({ ...error,password: "" })
    if (name === "email") {
      const isValid = emailValidation(value);

      if (!isValid) {
        setError({ ...error,email: signupValidationText.emailText });
      } else {
        setError({ ...error,email: "" });
      }
    }
    else {
      setError({ email: "",password: "" });
    }
    // setError({ ...error, [name]: "" });
    setErrMsg("");
  };

  const resetStateToDefaults = () => {
    setUserData(initialSignupDetails);
    setError(initialSignupDetails);
    setErrMsg("");
    setShowEyeButton(initialShowEyeOptions);
  }

  useEffect(() => {
    resetStateToDefaults();
  },[location.pathname]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log('TODAY AT 24th OCT 2024')
    event.preventDefault();
    signInSchema
      .validate(userData,{ abortEarly: false })
      .then((data: any) => {
        const payload = {
          email: data.email,
          password: data.password,
        };
        //API call
        signIn(payload);
      })
      .catch((err: any) => {
        const fieldErrors: any = {};
        err.inner.forEach((validationError: any) => {
          fieldErrors[validationError.path] = validationError.message;
        });
        if (fieldErrors.email) {
          setError({ ...error,email: fieldErrors.email });
        } else if (fieldErrors.password) {
          setError({ ...error,password: fieldErrors.password });
        }
      });
  };

  const signIn = async (data: signInField) => {
    try {
      const response: any = await signInService(data);
      if (response.data) {
        const userData = {
          accessToken: response.data.accessToken,
          name: response.data.name,
          refreshToken: response.data.refreshToken,
          role: response.data.role,
          id: response.data._id,
          profile_image: response.data.profile_image,
        };
        JSON.stringify(localStorage.setItem("userEmail",response.data.email));
        localStorage.setItem("userData",JSON.stringify(userData));
        resetStateToDefaults();
        // navigate("/select-team");

        // Check if user is part of any team or has any pending invitation
        if (response?.data?.has_team || response?.data?.has_pending_invitation) {
          navigate("/select-team");
        } else {
          navigate("/team-setup");
        }

      }
    } catch (error: any) {
      console.log("error while signIn user =",error);
      setErrMsg(error.message);
      if (error.message === USER_NOT_ACTIVE) {
        navigate("/verifyuser");
      } else if (error.message === ORGANIZATION_SET_ERROR) {
        // In Response getting the UserId of the user who has not register
        localStorage.setItem("userId",error._id);
        navigate("/register");
      }
    }
  };


  const handlePassEyeBtn = () => {
    setShowEyeButton({ ...showEyeBtn,password: !showEyeBtn.password });
  };

  return {
    handleInputChange,
    handleFormSubmit,
    error,
    userData,
    errMsg,
    showEyeBtn,
    handlePassEyeBtn
  };
  // googleLogin
};
export default useSigninHook;
