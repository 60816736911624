import { useState } from "react";


const useSideBar = () => {
  const [showSidebar, setIsShowSidebar] = useState(false);

  const handleClick = () =>{
    setIsShowSidebar(!showSidebar);
  }
  
  return {
    showSidebar,
    handleClick
  };
};
export default useSideBar;
