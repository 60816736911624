import {useEffect, useRef} from 'react';
import useAppState from '../../context/useAppState';
import CustomCheckBox from '../../components/CustomCheckBox';
import {USER_TYPE} from '../../utils/constant';
import {InputTextField} from '../../components/InputField';
import orgaIcon from '../../assets/images/oraganisation-icon.svg';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import {sliderSetting} from '../../utils/constant';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import mainLogo from 'assets/images/login_logo.svg';
import teamSetUpBanner from 'assets/images/Revenue-bro.svg';
import uploadIocn from 'assets/images/folder-open-03.svg';
import defaultTeamIcon from '../../assets/images/team-profile-default-icon.png';
// import Footer from "../../components/Footer";

const TeamSetup = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isExisting = queryParams.get('existing') === 'true';
    const {setTeamChange} = useAppState('selectTeam');

    const {userEmail, teamName, emails, msg, imageValidation, imageUrl, emailText, teamNameValidation, emailValidation, handleDelete, handleChange, handleSkip, handleFileChange, handleAddEmail, handleInputChange, handleFormSubmit} =
        useAppState('teamSetup');

    const handleButtonClick = (event: any) => {
        event?.preventDefault();
        document.getElementById('file-input')?.click();
    };

    const navigate = useNavigate();

    return (
        <div className='flex'>
            {/* Team Setup Left */}
            <div className='w-1/2'>
                <div className='bg-[#f9fafb] flex items-center justify-center h-[calc(100vh_-_1px)]'>
                    <img src={teamSetUpBanner} alt='team setup banner' />
                </div>
            </div>

            {/* Team Setup right */}
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto'>
                    <div className='w-full max-w-[396px] mx-auto'>
                        {/* Logo */}
                        <div className='text-center pb-6 customHeight920:pb-8 customHeight920:pt-20 pt-0'>
                            <img className='inline-block' src={mainLogo} alt='logo' />
                        </div>

                        {/* Content */}
                        <div className='w-full px-3'>
                            <h1 className='font-interSemiBold text-2xl text-[#101828] mb-3 text-center'>Setup your Team</h1>

                            <div className='text-center mb-6'>
                                <span className='border border-[#EAECF0] text-sm font-interMedium text-[#344054] bg-[#F2F4F7] px-2.5 py-2.5 rounded-full mx-auto block'>
                                    Confirmed as <span className='text-sm text-[#067647] font-interMedium border border-[#ABEFC6] rounded-full bg-[#fff] px-3 py-1 ml-1 mt-1 inline-block'>{userEmail}</span>
                                </span>
                            </div>

                            <form onSubmit={handleFormSubmit}>
                                <div className='mb-5'>
                                    <InputTextField
                                        label={'Team Name'}
                                        style={teamNameValidation ? 'border-red-500' : ''}
                                        name={'name'}
                                        placeholder={'Enter Your Team Name'}
                                        onChange={handleInputChange}
                                        value={teamName}
                                        type='text'
                                        err={teamNameValidation}
                                        errorButton={teamNameValidation && true}
                                    />
                                    <span className='text-sm text-[#475467] pt-1 inline-block'>Max. 50 characters</span>
                                </div>

                                {/* Team Photo */}
                                <div className='flex items-center gap-x-5 mb-5'>
                                    <img className='w-12 h-12 rounded-full border border-[#00000014] object-contain' src={imageUrl || defaultTeamIcon} alt='default ' />
                                    <input className='flex items-center gap-x-1.5 border border-[#D0D5DD] rounded-lg py-2.5 px-3.5' type='file' id='file-input' accept='.jpg,.jpeg,.png' style={{display: 'none'}} onChange={handleFileChange} />
                                    <button className='flex items-center gap-x-1.5 border border-[#D0D5DD] hover:border-[#113B98] rounded-lg py-2.5 px-3.5' onClick={handleButtonClick}>
                                        <img src={uploadIocn} alt='upload Iocn' />
                                        <span className='text-sm font-interSemiBold text-[#344054]'>Upload photo</span>
                                    </button>
                                </div>
                                <p className='text-[#D92D20] text-sm pt-0.5'>{imageValidation}</p>

                                {/* Invite Teams */}
                                <div className='px-4 py-4 border rounded-lg border-[#D0D5DD] mb-6'>
                                    <h4 className='text-base text-[#344054] font-interSemiBold pb-2 leading-none'>Invite your team(optional)</h4>
                                    <p className='text-[#475467] text-sm pb-5'>Start collaborating with your team. You can add more later.</p>

                                    {/* Textarea */}
                                    <div className=''>
                                        <p className='text-sm font-interMedium text-[#344054] pb-1.5'>Email address(es)</p>
                                        <div className='border border-[#D0D5DD] rounded-lg w-full py-3 px-3.5 flex flex-col-reverse h-24 overflow-y-auto hover:border-[#113B98] focus-within:border-[#113B98]'>
                                            <textarea
                                                className='resize-none mb-auto min-h-8'
                                                name=''
                                                id=''
                                                value={emailText.trim()}
                                                onChange={handleChange}
                                                onKeyUp={(event) => {
                                                    if (event.key === ',') {
                                                        handleAddEmail();
                                                    }
                                                }}
                                                onBlur={handleAddEmail}
                                            />

                                            <div className='chips-container'>
                                                {emails.map((email: string, index: number) => (
                                                    <div key={index} className='chip'>
                                                        {email}
                                                        <button onClick={() => handleDelete(index)}>&times;</button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <p className='text-[#D92D20] text-sm pt-0.5'>{emailValidation}</p>

                                        <p className='text-[#475467] text-sm pt-1'>Enter one or multiple email separated by commas</p>
                                    </div>
                                    {/* error */}
                                    {msg.error && <p className='text-[#D92D20] text-sm'>{msg.error}</p>}
                                </div>

                                {/* Button */}
                                <div className='flex gap-x-3 items-center justify-end mb-6'>
                                    {isExisting && (
                                        <button
                                            onClick={() => {
                                                navigate('/');
                                                setTeamChange(false);
                                            }}
                                            className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base hover:border-[#113B98] w-1/2'>
                                            Cancel
                                        </button>
                                    )}
                                    <button className={`submit-btn-blue blue-btn-hover ${isExisting ? 'w-1/2' : 'w-full'} ${!teamName ? 'button-disabled' : ''}`} type='submit'>
                                        Continue
                                    </button>
                                </div>

                                <p className='text-sm text-[#475467] text-center'>
                                    By continuing you’re agreeing to our
                                    <a className='text-[#1751D0] hover:text-[#113B98] text-sm font-interSemiBold' href=''>
                                        {' '}
                                        Service Agreement
                                    </a>{' '}
                                    and  user{' '}
                                    <a className='text-[#1751D0] hover:text-[#113B98] text-sm font-interSemiBold' href=''>
                                        Terms and Conditions.
                                    </a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#113B98]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#113B98]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSetup;
