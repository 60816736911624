import React, {useState} from 'react';

import ClaimGraphics from 'components/ClaimGraphics';
import ClaimStructure from 'components/ClaimStructure';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import {useNavigate} from 'react-router-dom';
import ConfigureScoring from 'components/common/modals/ConfigureScoring';

const ClaimAnalysis = () => {
    const [toggle, setToggle] = useState(0);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isOpen, setIsOpen] = useState(false);
    const [showConfidence, setShowConfidence] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const navigate = useNavigate();

    const [toast, setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });

    const [openConfigureScoreModal, setConfigureScoreModal] = useState(false);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? 0 : index);
    };

    const toggleMenu = (index: any, e: any) => {
        e.stopPropagation();
        setActiveMenu(activeMenu === index ? null : index);
    };

    const claimData = [
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 80,
            confidence: 99,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 76,
            confidence: 95,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 94,
            confidence: 99,
        },
        {
            green: [
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,',
                'on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein,',
            ],
            blue: 'a base is fastened to or built into, the back of the handheld device or its case wherein,',
            red: 'an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,',
            purple: 'the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
            similarity: 80,
            confidence: 99,
        },
    ];

    const claimGraphicsData = [
        {
            image: allImgPaths.Image1,
            title: 'Source Description comes here',
        },
        {
            image: allImgPaths.Image2,
            title: 'Source Description comes here',
        },
        {
            image: allImgPaths.Image3,
            title: 'Source Description comes here',
        },
    ];

    const claimStructureData = [
        {
            image: allImgPaths.Structure,
        },
    ];

    const confidenceRanges = [
        {label: 'more than 90%', value: '90-100'},
        {label: '80% - 90%', value: '80-90'},
        {label: '50%-80%', value: '50-80'},
        {label: 'less than 50%', value: '0-50'},
    ];

    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />
            <div className='w-[calc(100%_-_75px)] h-[100vh] ml-auto'>
                {/* Hedaer */}
                <Header title='Claim Analysis' count={0} isChildEle={true} />
                <div className='pt-6 px-8 h-[calc(100vh_-_125px)] overflow-y-scroll scrollbar-adjust'>
                    {/* Heading */}
                    <div className='flex justify-between items-center w-full mb-10'>
                        <div className='flex items-start gap-4'>
                            <img className='pt-3 cursor-pointer' src={allImgPaths.goBack} alt='return' onClick={() => navigate(-1)} />
                            <div className=''>
                                <h2 className='text-[#101828] text-xl font-bold text-left'>Claim Analysis for Patent # US10800024</h2>
                                <p className='text-lg text-[#475467] text-left'>Description of the patent comes here</p>
                            </div>
                        </div>
                        <div className='relative font-interRegular'>
                            <div className='flex gap-2'>
                                {/* Filter Button */}
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        setShowConfidence(false);
                                    }}
                                    className={`flex items-center gap-2 px-4 py-2 text-sm border rounded-md hover:border-black hover:border-2 ${isOpen ? 'border-black border-2' : ''}`}>
                                    <span className='flex items-center gap-2'>
                                        <img src={allImgPaths.filter} alt='filter.svg' />
                                        Filter
                                    </span>
                                </button>

                                {/* Configure Scoring Button */}
                                <button className={`flex items-center gap-2 px-4 py-2 text-sm border rounded-md hover:border-black hover:border-2`} onClick={() => setConfigureScoreModal(true)}>
                                    <img src={allImgPaths.configure} alt='' />
                                    Configure Scoring
                                </button>
                            </div>

                            {/* Dropdown Menu */}
                            {isOpen && (
                                <div className='absolute z-10 right-44 w-64 mt-2 bg-white border rounded-md shadow-lg'>
                                    {/* Confidence Score Header */}
                                    <div className='flex justify-between items-center p-3 border-b hover:bg-gray-200' onClick={() => setShowConfidence(!showConfidence)}>
                                        <div className='flex items-center gap-2'>
                                            <img src={allImgPaths.confidence} alt='' />
                                            <span className='text-sm font-medium'>By Confidence Score</span>
                                        </div>
                                        <img src={allImgPaths.right} alt='right' />
                                    </div>

                                    {/* Show Graphical Match Toggle */}
                                    <div className='flex items-center gap-2 p-3'>
                                        <img src={allImgPaths.graphical} alt='' />
                                        <span className='text-sm font-medium'>Show Graphical match only</span>
                                    </div>
                                </div>
                            )}
                            {showConfidence && (
                                <div className='absolute z-10 w-32 left-24 mt-2 bg-white border rounded-md shadow-lg'>
                                    {/* Confidence Ranges */}
                                    {confidenceRanges.map((range) => (
                                        <span key={range.value} className='font-medium flex items-center gap-2 p-2 text-sm rounded cursor-pointer hover:bg-gray-200'>
                                            {range.label}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        {/* Left Section */}

                        <div className='w-1/2 flex flex-col gap-3'>
                            {claimData.map((data, index) => (
                                <div key={index} className='flex flex-col border rounded-lg shadow-sm'>
                                    <div className='px-4 py-4 border-b'>
                                        <div className='flex items-center justify-between'>
                                            <span className='font-bold text-lg'>Claim {index + 1}</span>
                                            <div className='flex items-center gap-4'>
                                                <div className='flex gap-4 items-center'>
                                                    <div>
                                                        <span className='text-gray-600'>Similarity Score: </span>
                                                        <span className={`font-medium ${data.similarity > 90 ? 'text-green-600' : 'text-red-600'}`}>{data.similarity}%</span>
                                                    </div>
                                                    <div>
                                                        <span className='text-gray-600'>Confidence Score: </span>
                                                        <span className={`font-medium ${data.confidence > 90 ? 'text-green-600' : 'text-red-600'}`}>{data.confidence}%</span>
                                                    </div>
                                                </div>
                                                <button onClick={() => toggleAccordion(index)} className='p-1 hover:bg-gray-100 rounded-full transition-colors'>
                                                    <svg className={`w-5 h-5 transform transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''}`} viewBox='0 0 20 20' fill='currentColor'>
                                                        <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`overflow-hidden transition-all duration-300 ${activeIndex === index ? 'max-h-96' : 'max-h-0'}`}>
                                        <div className='p-4 text-lg leading-relaxed relative flex justify-between'>
                                            <div className='w-[95%]'>
                                                <span className='text-[#196742]'>{data.green[0]}</span>
                                                <span className='text-[#006FEE]'>{data.blue}</span>
                                                <span className='text-[#C53434]'>{data.red}</span>
                                                <span className='text-[#196742]'>{data.green[1]}</span>
                                                <span className='text-purple-700'>{data.purple}</span>
                                            </div>

                                            {/* Star Menu Button */}
                                            <button className=' mx-2 text-blue-600 hover:text-blue-700' onClick={(e) => toggleMenu(index, e)}>
                                                <img src={allImgPaths.blueStar} alt='' />
                                            </button>

                                            {/* Popup Menu */}
                                            {activeMenu === index && (
                                                <div className='absolute z-50 right-2 bottom-[10%] -translate-y-1/2  border min-w-[120px] rounded-lg bg-white shadow-[0px_6px_16px_0px_rgba(10,9,11,0.08),0px_0px_0px_1px_rgba(10,9,11,0.05)]'>
                                                    <div className='py-1'>
                                                        <button className='flex w-full px-[6px] py-[6px] gap-x-1 text-left hover:bg-gray-100 transition-colors text-sm' onClick={() => navigate('/project/3/claim-analysis/4/cite')}>
                                                            <img src={allImgPaths.citeIcon} alt='' />
                                                            Cite
                                                        </button>
                                                        <button className='flex w-full px-[6px] py-[6px] gap-x-1 text-left hover:bg-gray-100 transition-colors text-sm' onClick={() => navigate('/project/3/claim-analysis/4/vijilize')}>
                                                            <img src={allImgPaths.vijilizeIcon} alt='' />
                                                            Vijilize
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Right Section */}
                        <div className='w-1/2 flex justify-center flex-col gap-5 border p-4 rounded-md'>
                            <ul className='flex items-center justify-center'>
                                <li
                                    onClick={(e) => {
                                        setToggle(0);
                                    }}
                                    className={`hover:bg-neutral-200 rounded-l-md font-interSemiBold text-sm px-3 py-2 border cursor-pointer ${toggle === 0 ? 'bg-neutral-200 pointer-none' : ''}`}>
                                    Claim Graphics
                                </li>
                                <li onClick={(e) => setToggle(1)} className={`hover:bg-neutral-200 rounded-r-md font-interSemiBold text-sm  border px-3 py-2 cursor-pointer ${toggle === 1 ? 'bg-neutral-200 pointer-none' : ''}`}>
                                    Claim Structure
                                </li>
                            </ul>
                            {toggle === 0 && (
                                <div className='flex flex-col gap-5 overflow-auto max-h-[80%]'>
                                    {claimGraphicsData.map((data, index) => {
                                        return <ClaimGraphics data={data} index={index} />;
                                    })}
                                </div>
                            )}
                            {toggle === 1 && (
                                <div className='flex flex-col gap-5 overflow-auto max-h-[80%]'>
                                    {claimStructureData.map((data, index) => {
                                        return <ClaimStructure data={data} />;
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    {openConfigureScoreModal && <ConfigureScoring isShow={openConfigureScoreModal} setIsShow={setConfigureScoreModal} setToast={setToast} />}
                </div>
            </div>
        </div>
    );
};

export default ClaimAnalysis;
