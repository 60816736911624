const Paginate = ({startItem,endItem,totalItems,handlePrev,currentPage,handleNext,totalPages}:any) => {
    return (
        <>
            <div className={`flex items-center justify-between w-full p-[20px] border-t border-[#F1F1F1]`}>
                {
                    <div className='text-base font-interRegular text-[#4F4D55]'>
                        Showing <span className='text-[#0A090B] font-interMedium'>{startItem}</span> to <span className='text-[#0A090B] font-interMedium'>{endItem}</span> of <span className='text-[#0A090B] font-interMedium'>{totalItems}</span> Results
                    </div>
                }

                <div className='flex items-center gap-2'>
                    <button
                        onClick={handlePrev}
                        disabled={currentPage === 1}
                        className={`px-3 py-2 text-sm rounded border ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-[#4F4D55] hover:bg-gray-50'}`}>
                        Previous
                    </button>

                    <button
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 text-sm rounded border ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default Paginate;