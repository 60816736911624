import React from 'react';

import ProductCard from 'components/ProductCard';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';

const ClaimChart = () => {
    const heading = ['Source Claim', 'Product', 'Explanation'];

    const claimData = [
        {
            content:
                'A collapsing and expanding one hand gripping apparatus fastened to or built into the back of a handheld device or its case consisting essentially of,a one hand gripping apparatus fastened to or built into the back of a handheld device wherein,a base is fastened to or built into, the back of the handheld device or its case wherein,an extension extends perpendicular from the base consisting essentially of a single piece conical telescoping flexible tubular structure, consisting essentially of a plurality of graduated sections, more flexible between the sections, that flex and/or fold over and into one-another, wherein the extension is movable between a collapsed, closed position and an extended, open position,on the opposite end of the extension from the base is a grip, wherein the grip is disc- shaped and mounted perpendicular to the end of the extension and has an outer perimeter that is not in contact with the extension when the one hand gripping apparatus is in an extended or retracted position, wherein the grip is attached to the extension, wherein the grip extends radially past the extension and wherein, the one hand gripping apparatus in the collapsed position lies flat to the handheld device.',
        },
    ];

    const productData = [
        {
            image: allImgPaths.Product1,
            title: 'Otterbox',
            tag: 'Manufacturer',
            url: 'https://www.otterbox.com/en-us/popsockets-otterbox',
            score: 98,
            explanation: 'The accused instrumentality is a collapsing and expanding one hand gripping apparatus that is fastened to and built into the back of a handheld device case.',
            demoimg: allImgPaths.Explanation1,
        },
        {
            image: allImgPaths.Product2,
            title: 'Walmart',
            tag: 'Retailer',
            url: 'https://www.walmart.com/ip/Otter-Pop-Defender-Series- Case-iPhone-11-Pro-Max-Howler-Grey/182980406 ',
            score: 98,
            explanation: 'The accused instrumentality is a collapsing and expanding one hand gripping apparatus that is fastened to and built into the back of a handheld device case.',
            demoimg: allImgPaths.Explanation2,
        },
    ];

    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />
            <div className='w-[calc(100%_-_75px)] h-[100vh] ml-auto'>
                {/* Hedaer */}
                <Header count={0} title='Claim Chart' isChildEle={true} />
                <div>
                    {/* Page Header Part */}
                    <div className='flex justify-between align-middle p-[16px]'>
                        <div className='fex flex-col'>
                            <h3 className='text-[#101828] text-xl font-interSemiBold'>Device Holder</h3>
                            <span className='text-[#7F7D83] font-light text-sm'>Cover and grip for handheld devices</span>
                        </div>
                        <div className='flex items-center gap-4'>
                            <button className='flex items-center gap-2 px-4 py-2 cancle-btn-blue  transition-colors'>
                                <img src={allImgPaths.printIcon} alt='' /> Print
                            </button>
                            <button className='flex items-center gap-2 px-4 py-2 cancle-btn-blue  transition-colors'>
                                <img src={allImgPaths.downloadIcon} alt='' /> Download
                            </button>
                        </div>
                    </div>
                    <div className='p-6'>
                        <div className='flex'>
                            {heading.map((data, index) => {
                                return (
                                    <div key={index} className='w-1/3 px-3 border py-5 font-bold text-lg text-left'>
                                        {data}
                                    </div>
                                );
                            })}
                        </div>
                        <div className='flex'>
                            <div className='flex w-1/3 flex-col lg:max-h-[745px] border overflow-auto'>
                                {claimData.map((data, index) => {
                                    return (
                                        <div key={index} className=''>
                                            <h2 className='px-3 border-b py-5 font-bold text-lg text-left'>Claim {index + 1}</h2>
                                            <p className='px-5 py-5 text-[#555F6D] text-md text-left'>{data.content}</p>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='w-2/3 lg:max-h-[745px] overflow-auto'>
                                {productData.map((data, index) => {
                                    return (
                                        <div key={index} className='flex border rounded-md m-3 p-2 gap-5'>
                                            <div className='w-1/2'>
                                                <ProductCard data={data} showExplanation={false} />
                                            </div>
                                            <div className='w-1/2'>
                                                <p className='text-left text-[#71717A]'>{data.explanation}</p>
                                                <div className='flex justify-center items-center p-4 h-full'>
                                                    <img className='' src={data.demoimg} alt='product.image' />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClaimChart;
