import * as yup from "yup";
import { regex } from "../utils/regex";
import {
  EMAIL_VALIDATION,
  TASK_VALIDATION,
  TEAM_TEXT,
  otpValidation,
  roleValidation,
  signupValidationText,
  teamNameLengthText,
  teamNameValidationText,
  teamValidation,
} from "../utils/constant";

export const signupSchema = yup.object().shape({
  name: yup.string().matches(regex.name, signupValidationText.nameText).required(signupValidationText.nameText),
  email: yup
    .string()
    .email("Invalid Email Format")
    .matches(regex.email, signupValidationText.emailText).required(signupValidationText.emailText),
  password: yup
    .string()
    .min(8, signupValidationText.minPasswordLength)
    .max(32, signupValidationText.maxPasswordLength)
    .matches(regex.password, signupValidationText.validPassword).required(signupValidationText.passwordText),
  confirmPassword: yup
    .string()
    .nullable()
    .oneOf([null, yup.ref("password")], signupValidationText.confirmPassword)
    .required(signupValidationText.confirmPasswordText),
});

export const signInSchema = yup.object().shape({
  email: yup.string().matches(regex.email, signupValidationText.emailText).required(signupValidationText.emailValidation),
  password: yup
    .string().required(signupValidationText.passwordText),
});

export const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .required(otpValidation.otpRequired)
    .length(6, otpValidation.sixDigitOtp),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(signupValidationText.passwordText),
  confirmPassword: yup
    .string()
    .required(signupValidationText.confirmPasswordText)
    .nullable()
    .oneOf([null, yup.ref("password")], signupValidationText.confirmPassword)
    .required(signupValidationText.confirmPasswordText),
});

export const checkBoxSchema = yup.object().shape({
  userType: yup.string().required(roleValidation.roleRequired),
});

export const forgotPassword = yup.object().shape({
  email: yup.string().required(signupValidationText.emailValidation).matches(regex.email, signupValidationText.emailText)
});

export const teamSetup = yup.object().shape({
	teamName: yup.string().max(50, teamNameLengthText).required(teamValidation.teamNameText),
	emails: yup.array().of(yup.string().email('Invalid email format')).nullable(),
  });

export const updateTeamValidation = yup.object().shape({
  name: yup.string().max(50, teamNameLengthText),
  logo: yup.string()
})

export const assignTaskSchema = yup.object().shape({
  teamEmail: yup.object().shape({
    value: yup.string().optional(),
    label: yup.string().required(EMAIL_VALIDATION)
  }).required(),
  assignTask: yup.object().shape({
    value: yup.string().optional(),
    label: yup.string().required(TASK_VALIDATION)
  }).required()
});

export const completeTaskSchema = yup.object().shape({
  reason: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required('Please select a reason.')
  }).required(),
  additional_info: yup.string()
});

export const addPromptSchema = yup.object().shape({
  name: yup.string().required('Please enter a name for the prompt.'),
  prompt: yup.string().required('Please enter prompt.')
});

export const updatePromptSchema = yup.object().shape({
  newPrompt: yup.string().required('Please enter prompt.')
});

export const uploadFileSchema = yup.object().shape({
});