/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState,useMemo,useCallback } from 'react';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import { usePagination } from 'hooks/paginate/usePagination';
import AddToProjectModal from "components/common/modals/AddToProject";
import NewPortfolioModal from "components/common/modals/NewPortfolio";
import AddToPortfolioModal from "components/common/modals/AddToPortfolio";
import { useNavigate } from "react-router-dom";
import FileExplorer from "components/common/Explorer";

function PortfolioDetails() {

    const [openAddToPortfolioModal,setopenAddToPortfolioModal] = useState(false);

    const navigate = useNavigate();

    const [toast,setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });


    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />

            {/* dashboard main */}
            <div className='w-[calc(100%_-_75px)] h-[100vh]  ml-auto'>
                {/* Hedaer */}
                <Header title='' count={0} isChildEle={true} />
                <div className='h-full'>
                    <div className='flex justify-between align-middle p-[16px]'>
                        <div className='flex items-start gap-4'>
                            <img className='pt-3 cursor-pointer' src={allImgPaths.goBack} alt='return' onClick={() => navigate(-1)} />
                            <div className=''>
                                <h2 className='text-[#0A090B] text-xl font-interSemiBold text-left'>Device Holder</h2>
                                <p className='text-lg text-[#7F7D83] font-interRegular text-left'>Cover and grip for handheld devices</p>
                            </div>
                        </div>
                        <div className='flex justify-between gap-x-2 mr-2'>

                            <div className='flex gap-x-[16px] items-center '>
                                <button className='submit-btn-blue h-auto gap-x-2 ' onClick={() => setopenAddToPortfolioModal(true)}>
                                    Add
                                </button>
                                <div className=''>
                                    <div className={`border hover:border-[#1e0af5] rounded-lg w-80 py-2 px-3.5 flex items-center gap-x-2 `}>
                                        <img src={allImgPaths.searchIcon} alt='search' />
                                        <input className='w-full' placeholder='Search' type='text' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex ">
                        <div className="w-1/4">
                            <FileExplorer />
                        </div>
                        <div className="w-3/4 bg-white">
                            <iframe title="pdf" src="https://pdfobject.com/pdf/sample.pdf" width={"100%"} height={'480px'}></iframe>
                        </div>
                    </div>

                </div>
                {openAddToPortfolioModal && <AddToPortfolioModal isShow={openAddToPortfolioModal} setIsShow={setopenAddToPortfolioModal} setToast={setToast} />}
            </div>
        </div>
    );
}
export default PortfolioDetails;
