import axios from "axios";
import { API_BASE_URL } from "utils/constant";

export const defaultAxios = axios;

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

export const googleAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_AUTH_URL,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const user = JSON.parse(localStorage.getItem("userData") as string);

    if (user?.accessToken) {
      config.headers["Authorization"] = `Bearer ${user?.accessToken}`;
    }
    config.headers["Content-Type"] =
      config.headers["Content-Type"] || "application/json";
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response?.data;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log("error.response =>", error);
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const user = JSON.parse(localStorage.getItem("userData") as string);
        if(user?.refreshToken){
          const response = await axios.post(
            `${API_BASE_URL}/user/refresh-token`,
            {
              refresh_token: user.refreshToken,
            }
          );
          const payload = {
            name: response.data.data.name,
            refreshToken: response.data.data.refreshToken,
            accessToken: response.data.data.accessToken,
            role: response.data.data.role,
            id: response.data.data._id,
            profile_image: response.data.data.profile_image,
          };
          localStorage.setItem("userData", JSON.stringify(payload));
          localStorage.setItem(
            "userEmail",
            JSON.stringify(response.data.data.email)
          );
  
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.data.accessToken;
        }
        return axiosInstance(originalRequest);
      } catch (error) {
        // Redirect to login page
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error.response.data);
  }
);

export default axiosInstance;
