/* eslint-disable eqeqeq */
import React,{ useCallback,useEffect,useRef,useState } from 'react';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';


import { usePagination } from 'hooks/paginate/usePagination';
import ClaimTable from './ClaimTable';
import { useNavigate } from 'react-router-dom';
import AddSource from "components/common/modals/AddSource";
import AddComparisonData from "components/common/modals/AddComparisonData";
import SearchBar from 'components/common/SearchBar';

function FindCoveredProducts() {
    var toggle = 1;
    var comparisonCount = 20;
    var claimCount = 20;
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [sortOrder,setSortOrder] = useState('asc');
    const [arrowUp,setArrowUp] = useState(false);
    const columns = [
        { name: 'Patents',count: 16 },
        { name: 'Files',count: 2 },
        { name: 'URLs',count: 2 },
        { name: 'Publications',count: 10 },
    ];
    // sources
    const emptySource: any[] = [
        {
            value: '1',
            title: 'US10800024',
            date: '2020-10-13',
            content: 'Apparatus for gripping handheld devices',
        },
        {
            value: '2',
            title: 'US10925181',
            date: '2021-02-16',
            content: 'Ergonomic protective case for mobile electronic devices',
        },
        {
            value: '3',
            title: 'US11076428',
            date: '2021-07-30',
            content: 'Adjustable mounting system for portable computing devices',
        },
        {
            value: '4',
            title: 'US10842083',
            date: '2020-11-24',
            content: 'Shock-absorbing holder for tablet computers',
        },
        {
            value: '5',
            title: 'US11089174',
            date: '2021-08-10',
            content: 'Multi-angle stand mechanism for mobile phones',
        },

    ];
    // Patent table
    const emptyPatentData: any[] = [
        {
            id: 'US4211445A',
            patentTitle: 'Soap bar holder',
            publication_date: '1980-07-08',
        },
        {
            id: 'US5396399',
            patentTitle: 'Multi-purpose handle/prop for pen-based computer having overhanging front surface',
            publication_date: '2006-07-09',
        },
        {
            id: 'US6016248A',
            patentTitle: 'Hand held tablet computer having external mechanisms for facilitating positioning',
            publication_date: '2009-11-22',
        },
        {
            id: 'US6094341A',
            patentTitle: 'Notebook computer',
            publication_date: '2007-03-21',
        },
        {
            id: 'US6814416B2',
            patentTitle: 'Stabilizer mechanisms for computer related equipment',
            publication_date: '2012-07-19',
        },
        {
            id: 'US7123478B2',
            patentTitle: 'Portable electronic device with adjustable display panel',
            publication_date: '2014-09-15',
        },
        {
            id: 'US8256731B1',
            patentTitle: 'Ergonomic laptop computer support stand',
            publication_date: '2015-04-30',
        },
        {
            id: 'US8567832C1',
            patentTitle: 'Foldable tablet mounting system for vehicles',
            publication_date: '2016-02-12',
        },
        {
            id: 'US9012456D1',
            patentTitle: 'Advanced cooling system for portable computing devices',
            publication_date: '2017-08-23',
        },
        {
            id: 'US9445123E2',
            patentTitle: 'Modular keyboard attachment for tablet computers',
            publication_date: '2018-05-17',
        },
        {
            id: 'US9876543F1',
            patentTitle: 'Wireless charging pad with integrated device stand',
            publication_date: '2019-11-05',
        },
        {
            id: 'US10234567G2',
            patentTitle: 'Convertible laptop-tablet with detachable stylus holder',
            publication_date: '2020-03-28',
        },
        {
            id: 'US10456789H1',
            patentTitle: 'Anti-glare screen protector with blue light filtering',
            publication_date: '2021-06-14',
        },
        {
            id: 'US11123456J2',
            patentTitle: 'Adjustable ergonomic keyboard stand with integrated cooling',
            publication_date: '2022-09-30',
        },
        {
            id: 'US11789012K1',
            patentTitle: 'Portable device mounting system with integrated cable management',
            publication_date: '2023-12-05',
        },

    ];
    const sourcePatents: any[] = [

    ];
    // Patent table
    const patentData: any[] = [

    ];
    let patentColumns: any[] = [
        {
            name: 'PatentId',
            ref: 'id',
            hide: false,
            position: 1,
        },
        {
            name: 'Title',
            ref: 'patentTitle',
            hide: false,
            position: 2,
        },
        {
            name: 'Publication Date',
            ref: 'publication_date',
            hide: false,
            position: 3,
        },
        {
            name: 'Action',
            ref: 'action',
            hide: false,
            position: 4,
        },
    ];
    // claim charts

    const [isActiveColumn,setIsActiveColumn] = useState(0);
    const [isActiveSection,setIsActiveSection] = useState(0);
    const [showMenu,setSowMenu] = useState(false);
    const [isFocused,setIsFocused] = useState<boolean>(false);
    const [selectedItems,setSelectedItems] = useState<number[]>([]);
    const [selectAll,setSelectAll] = useState(false);
    const [dataLength,setDataLength] = useState(1);

    const navigate = useNavigate();

    // const [sourceSearch, setSourceSearch] = useState();
    // const [patentsSearch, setPatentsSearch] = useState();
    const [openModal,setOpenModal] = useState(false);
    const [openComparisonModal,setOpenComparisonModal] = useState(false);

    const [paginatedData,setPaginatedData] = useState<any[]>([]);
    const [toast,setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });

    const totalItems = patentData.length;
    const itemsPerPage = 5;

    const { currentPage,totalPages,startItem,endItem,nextPage,prevPage,currentData } = usePagination({
        totalItems: dataLength,
        itemsPerPage,
        initialPage: 1,
    });

    useEffect(() => {


        setDataLength(patentData.length)
        const newData = currentData(patentData,true);
        setPaginatedData(newData);
        setSelectedItems([]);
        setSelectAll(false);
    },[currentPage]);

    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectAll(e.target.checked);
            if (e.target.checked) {
                console.log('hit all');

                // Select all items in the current page
                const currentPageIds = paginatedData.map((_,index) => index);
                setSelectedItems(currentPageIds);
            } else {
                // Deselect all items
                setSelectedItems([]);
            }
        },
        [paginatedData]
    );

    const handleSelectItem = useCallback(
        (index: number) => {
            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev,index];

                // Update selectAll state based on if all items in current page are selected
                setSelectAll(newSelected.length === paginatedData.length);

                return newSelected;
            });
        },
        [paginatedData]
    );

    const selectColumn = async (index: number) => {
        setIsActiveColumn(index);
        // TODO: api calls need to bind
        if (index == 0) {
        } else {
            console.log('Others');
        }
    };

    // const handleSearchValue = (event: any) => {
    //     getFormValue(event.target.value);
    //     console.log(formValue);
    // };

    // const handleFocus = () => {
    //     setIsFocused(true);
    //     console.log('test');
    // };

    // const handleBlur = () => {
    //     setIsFocused(false);
    // };

    const handleMenuClick = () => {
        setSowMenu(!showMenu);
    };
    const createColumnClassName = (field: string) => {
        if (field == 'id') {
            return 'table-col w-[20%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'patentTitle') {
            return 'table-col w-[50%]  flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'publication_date') {
            return 'table-col w-[20%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'action') {
            return 'table-col w-[10%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
    };

    const handlePrev = () => {
        prevPage();
    };

    const handleNext = () => {
        nextPage();
    };

    const handleSort = (type: string) => {
        // const enabledRows = taskViewRows.filter((row: any) => !row.isCardDisabled);
        // const disabledRows = taskViewRows.filter((row: any) => row.isCardDisabled);
        const sortedEnabledRows = [...paginatedData].sort((a,b) => {
            if (sortOrder === 'asc') {
                return a[type].localeCompare(b[type]);
            } else {
                return b[type].localeCompare(a[type]);
            }
        });
        const finalSortedData = sortedEnabledRows;
        setPaginatedData(finalSortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setArrowUp(!arrowUp);
    };

    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />

            {/* dashboard main */}
            <div className='w-[calc(100%_-_72px)] ml-auto'>
                {/* Hedaer */}
                <Header title='Find Covered Product' count={0} isChildEle={true} />
                <div>
                    {/* Page Header Part */}

                    <div className='flex justify-between py-4 px-4'>
                        <div className='flex items-start gap-x-2 pl-1'>
                            <img className='inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1' src={allImgPaths.goBack} alt='return' onClick={() => navigate(-1)} />
                            <div className=''>
                                <h2 className='text-[#0A090B] text-xl font-interSemiBold'>Claim Analysis for Patent # US10800024</h2>
                                <p className='text-sm text-[#7F7D83] font-interRegular'>Cover and grip for handheld devices</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-x-2'>
                            {isActiveSection == 0 && (
                                <button className='!text-sm text-white bg-[#1751D0] hover:bg-[#120693] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5' onClick={() => navigate('/project/4/claim-analysis')}>
                                    Vijilize
                                </button>
                            )}
                            <ul className='flex items-center'>
                                <li
                                    onClick={() => setIsActiveSection(0)}
                                    className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] text-[#4F4D55] rounded-tl-lg rounded-bl-lg flex items-center gap-x-2 cursor-pointer  ${isActiveSection === 0 ? 'bg-[#F1F1F1] pointer-none' : 'bg-[#fff]'}`}>
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>Comparison Library ({comparisonCount})</span>
                                </li>

                                <li
                                    onClick={() => setIsActiveSection(1)}
                                    className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] flex items-center gap-x-2 cursor-pointer rounded-tr-lg rounded-br-lg ${isActiveSection === 1 ? 'bg-[#F1F1F1] pointer-none' : 'bg-[#fff] '}`}>
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>Claim Charts ({claimCount})</span>
                                </li>
                            </ul>

                        </div>
                    </div>

                    {/* Comparison Library */}
                    {isActiveSection == 0 && (
                        <div className='flex gap-x-5 px-6 h-[calc(100vh_-_170px)]'>
                            <div className=' bg-white shadow-sm border-solid border-[1px] border-[#E6E6E6] w-[332px] flex-shrink-0 rounded-lg'>
                                <div className='flex items-center justify-between py-3.5 px-3 border-b border-[#E6E6E6]'>
                                    <h2 className='text-base font-interMedium'>Source Patents</h2>
                                    <img className='inline-block cursor-pointer on-hover-bg-grey w-7 h-7 p-1 rounded ' onClick={() => setOpenModal(true)} src={allImgPaths.circleBlack} alt='' />
                                </div>

                                <div className="px-3 pt-3 pb-4">
                                    <SearchBar
                                        img_url={allImgPaths.searchIcon}
                                        width={'w-full'}
                                        placeholder={'Search'}
                                    />
                                </div>

                                {/* No Source Patent  */}
                                {!sourcePatents.length && (
                                    <div className='pt-14 text-center'>
                                        <h2 className='text-base text-[#101828] font-interSemiBold pb-0.5'>No source patents</h2>
                                        <p className='text-sm text-[#475467] pb-4'>Add your source patents</p>
                                        <button className={`!text-sm text-white bg-[#1751D0] hover:bg-[#120693] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 mx-auto`} onClick={() => setOpenModal(true)}>
                                            <img className='w-4' src={allImgPaths.plusIconWhite} alt="" />
                                            Add New
                                        </button>
                                    </div>
                                )}
                                <div className='overflow-y-auto'>
                                    {sourcePatents?.length > 0 &&
                                        sourcePatents.map((dt,index) => (
                                            <li className='px-6 border-b border-[#F1F1F1] flex justify-between py-3 last:border-b-0' key={index}>
                                                <div className='flex'>
                                                    <div className='relative'>
                                                        <input
                                                            type='checkbox'
                                                            id={dt.value}
                                                            name='checkbox-group'
                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                            checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                            transition-colors cursor-pointer'
                                                        />
                                                        <svg
                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                        inset-0 stroke-white mt-[1px] ml-[1px]'
                                                            viewBox='0 0 16 16'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'>
                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                        </svg>
                                                    </div>

                                                    <label htmlFor={dt.value} className='ml-3 flex-1 cursor-pointer w-[202px]'>
                                                        <div className='flex flex-col'>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{dt.title}</p>
                                                                <span className='text-sm font-interRegular text-[#4F4D55]'>{dt.date}</span>
                                                            </div>
                                                            <p title={dt.content} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2 max-w-60'>{dt.content}</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <img src={allImgPaths.EmptyStars} alt='star icon' className='w-9 h-9 cursor-pointer' />
                                                </div>
                                            </li>
                                        ))}
                                </div>
                            </div>

                            <div className='bg-white rounded-lg shadow-sm border-solid border border-[#E6E6E6] w-[calc(100%_-_346px)]'>
                                <div className='flex px-3 py-2 justify-between items-center border-b border-[#E6E6E6]'>
                                    <span className='text-sm font-interMedium text-black'>Compare with</span>

                                    <div className='flex gap-x-4 items-center'>
                                        <button className='!text-sm text-white bg-[#1751D0] hover:bg-[#120693] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5' onClick={() => setOpenComparisonModal(true)}>
                                            Add
                                        </button>
                                        <SearchBar
                                            img_url={allImgPaths.searchIcon}
                                            width={'w-full'}
                                            placeholder={'Search'}
                                        />
                                    </div>
                                </div>
                                {/* Tabs */}
                                <div className='bg-[#FFF] border-b border-[#EAECF0]'>
                                    <div className='flex items-center justify-center w-full px-6'>
                                        <div id='scrollBoardContainer' ref={scrollContainerRef} className='flex items-center justify-around no-scrollbar gap-[16px] overflow-x-auto h-[43px] w-[calc(100vw_-_225px)]'>
                                            {columns?.length > 0 &&
                                                columns.map((column: any,cIndex: any) => (
                                                    <div>
                                                        <span
                                                            key={cIndex}
                                                            onClick={() => {
                                                                selectColumn(cIndex);
                                                            }}
                                                            className={`${isActiveColumn === cIndex ? 'text-[#1751D0] planning-board-border-bottom after:bg-[#1751D0]' : 'text-[#7F7D83]'
                                                                }  text-sm font-interMedium pointer-cursor relative mx-10 flex-shrink-0 hover:text-[#1751D0]`}>
                                                            {column.name}
                                                            <span className='text-sm font-interMedium ml-1'>({column.count})</span>
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='rounded-br-2xl overflow-hidden'>
                                        <div className='bg-white'>
                                            {/* Tabs Head */}
                                            <div className='flex flex-nowrap sticky top-0 z-10 checkbox-alignment2'>
                                                {isActiveColumn === 0 &&
                                                    patentColumns?.length > 0 &&
                                                    paginatedData.length > 0 &&
                                                    patentColumns.map((column: any,columnIndex: number) => (
                                                        <React.Fragment key={columnIndex}>
                                                            {
                                                                <div className={createColumnClassName(column.ref)}>
                                                                    {/* To manage sort icon and column width */}
                                                                    {column.name === 'PatentId' && (
                                                                        <span className=' font-interMedium text-sm text-[#4F4D55] flex items-center justify-normal gap-x-2 w-[100%]'>
                                                                            <div className='relative inline-block'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={'select-all-' + columnIndex}
                                                                                    name='checkbox-group'
                                                                                    checked={selectAll}
                                                                                    onChange={handleSelectAll}
                                                                                    className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                checked:bg-indigo-600 checked:border-0 
                                                                                transition-colors cursor-pointer'
                                                                                />
                                                                                <svg
                                                                                    className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                          inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                    viewBox='0 0 16 16'
                                                                                    fill='none'
                                                                                    stroke='currentColor'
                                                                                    strokeWidth='2'
                                                                                    strokeLinecap='round'
                                                                                    strokeLinejoin='round'>
                                                                                    <polyline points='4 8 7 11 12 5'></polyline>
                                                                                </svg>
                                                                            </div>
                                                                            {column.name}
                                                                            <img className={`w-4 cursor-pointer transform ${sortOrder == 'asc' ? 'rotate-0' : 'rotate-180'}`} src={allImgPaths.arrowUp3} alt='' onClick={() => handleSort(column.ref)} />
                                                                        </span>
                                                                    )}

                                                                    {/* Column with no sort icon*/}
                                                                    {column.name !== 'PatentId' && <span className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</span>}
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </div>

                                            {/* Body */}
                                            {/* {isActiveColumn === 0 && patentData.length > 0 && patentData.map((data: any, columnIndex: number) => <div></div>)} */}
                                            {isActiveColumn === 0 && (

                                                <>

                                                    <div className={'h-[calc(100vh_-_356px)]'}>
                                                        {paginatedData.length > 0 &&
                                                            paginatedData.map((row: any,rowIndex: number) => (
                                                                <React.Fragment key={rowIndex}>
                                                                    <div className={`flex flex-nowrap`}>
                                                                        {patentColumns?.length > 0 &&
                                                                            patentColumns.map((column: any,columnIndex: number) => (
                                                                                <React.Fragment key={columnIndex}>
                                                                                    {column.ref == 'id' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className='text-sm text-[#475467] flex items-center gap-x-2'>
                                                                                                <div className='relative inline-block'>
                                                                                                    <input
                                                                                                        type='checkbox'
                                                                                                        id={'item' + rowIndex}
                                                                                                        name='checkbox-group'
                                                                                                        checked={selectedItems.includes(rowIndex)}
                                                                                                        onChange={() => handleSelectItem(rowIndex)}
                                                                                                        className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                                checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                                                                transition-colors cursor-pointer'
                                                                                                    />
                                                                                                    <svg
                                                                                                        className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                                  inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                                        viewBox='0 0 16 16'
                                                                                                        fill='none'
                                                                                                        stroke='currentColor'
                                                                                                        strokeWidth='2'
                                                                                                        strokeLinecap='round'
                                                                                                        strokeLinejoin='round'>
                                                                                                        <polyline points='4 8 7 11 12 5'></polyline>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                {row[column.ref]}
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref == 'patentTitle' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className=' flex items-center gap-x-2 text-neutral-700 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref == 'publication_date' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className='text-neutral-900 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref === 'action' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <img className='cursor-pointer hover:bg-[#f2f4f7]' src={allImgPaths.trashIcon} alt='' />
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        {isActiveColumn === 0 && patentData.length === 0 &&
                                                            <div className="flex justify-center items-center h-full">
                                                                <div className="text-center">
                                                                    <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                                    <p className="font-interSemiBold text-base text-[#101828]">No data found</p>
                                                                    <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>

                                                    {/* Paginate */}
                                                    <div className={`flex items-center justify-between w-full p-[20px] rounded-bl-lg rounded-br-lg`}>
                                                        {
                                                            <div className='text-base font-interRegular text-[#4F4D55]'>
                                                                Showing <span className='text-[#0A090B] font-interMedium'>{startItem}</span> to <span className='text-[#0A090B] font-interMedium'>{endItem}</span> of <span className='text-[#0A090B] font-interMedium'>{totalItems}</span> Results
                                                            </div>
                                                        }

                                                        <div className='flex items-center gap-2'>
                                                            <button
                                                                onClick={handlePrev}
                                                                disabled={currentPage === 1}
                                                                className={`px-4 py-2 text-sm rounded border ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                                                                Previous
                                                            </button>

                                                            <button
                                                                onClick={handleNext}
                                                                disabled={currentPage === totalPages}
                                                                className={`px-4 py-2 text-sm rounded border ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>



                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Claim chart */}
                    {isActiveSection === 1 && (
                        <div className='flex justify-between p-[24px] h-[80vh] w-full'>
                            <div className=' bg-white rounded-xl shadow-sm border-solid border-[1px] border-[#E6E6E6] w-full'>
                                <div className='flex justify-between m-[20px] items-center'>
                                    <div className=''>
                                        <div className={`border hover:border-[#1e0af5] rounded-lg w-80 py-2 px-3.5 flex items-center gap-x-2 ${isFocused ? 'border-[#1e0af5]' : 'border-[#D0D5DD]'}`}>
                                            <img src={allImgPaths.searchIcon} alt='search' />
                                            <input className='w-full' placeholder='Search' type='text' />
                                        </div>
                                    </div>
                                    <div className='flex items-center'>
                                        <span className='w-4 inline-block mr-0.5 mb-px'>
                                            <img className={`cursor-pointer ml-auto`} src={allImgPaths.threeVerticleDotsIcon} onClick={handleMenuClick} alt='' />
                                        </span>
                                        {showMenu && <div></div>}
                                    </div>
                                </div>

                                <div className='w-full'>{<ClaimTable />}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {openModal && <AddSource isShow={openModal} setIsShow={setOpenModal} setToast={setToast} />}
            {openComparisonModal && <AddComparisonData isShow={openComparisonModal} setIsShow={setOpenComparisonModal} setToast={setToast} />}
        </div>
    );
}
export default FindCoveredProducts;
