import allImgPaths from 'assets/images';

import AddSource from 'components/common/modals/AddSource';
import {usePagination} from 'hooks/paginate/usePagination';
import React, {useCallback} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

function ClaimTable() {
    const claimData: any[] = [
        {
            id: 'claim1',
            name: 'For Claim 1',
            createdBy: 'James Greer',
            lastUpdate: '06/05/2024',
        },
        {
            id: 'products',
            name: 'All covered products',
            createdBy: 'Amy Williams',
            lastUpdate: '05/05/2024',
        },
        {
            id: 'company1',
            name: 'For Company 1',
            createdBy: 'Andy Miller',
            lastUpdate: '05/05/2024',
        },
        {
            id: 'company2',
            name: 'For Company 2',
            createdBy: 'James Greer',
            lastUpdate: '04/05/2024',
        },
        {
            id: 'claim2',
            name: 'For Claim 2',
            createdBy: 'Sarah Chen',
            lastUpdate: '06/05/2024',
        },
        {
            id: 'warranty',
            name: 'Warranty Claims',
            createdBy: 'Mike Johnson',
            lastUpdate: '03/05/2024',
        },
        {
            id: 'company3',
            name: 'For Company 3',
            createdBy: 'Lisa Parker',
            lastUpdate: '02/05/2024',
        },
        {
            id: 'services',
            name: 'Service Agreements',
            createdBy: 'Amy Williams',
            lastUpdate: '01/05/2024',
        },
        {
            id: 'claim3',
            name: 'For Claim 3',
            createdBy: 'David Thompson',
            lastUpdate: '30/04/2024',
        },
        {
            id: 'maintenance',
            name: 'Maintenance Contracts',
            createdBy: 'Andy Miller',
            lastUpdate: '29/04/2024',
        },
        {
            id: 'company4',
            name: 'For Company 4',
            createdBy: 'James Greer',
            lastUpdate: '28/04/2024',
        },
        {
            id: 'support',
            name: 'Support Agreements',
            createdBy: 'Sarah Chen',
            lastUpdate: '27/04/2024',
        },
        {
            id: 'claim4',
            name: 'For Claim 4',
            createdBy: 'Lisa Parker',
            lastUpdate: '26/04/2024',
        },
        {
            id: 'company5',
            name: 'For Company 5',
            createdBy: 'Mike Johnson',
            lastUpdate: '25/04/2024',
        },
        {
            id: 'insurance',
            name: 'Insurance Claims',
            createdBy: 'David Thompson',
            lastUpdate: '24/04/2024',
        },
    ];

    const claimColumns: any[] = [
        {
            name: 'Claim Chart Name',
            ref: 'name',
            hide: false,
            position: 1,
        },
        {
            name: 'Created by',
            ref: 'createdBy',
            hide: false,
            position: 2,
        },
        {
            name: 'Last Update',
            ref: 'lastUpdate',
            hide: false,
            position: 3,
        },
        {
            name: 'Actions',
            ref: 'action',
            hide: false,
            position: 4,
        },
    ];

    const [paginatedData, setPaginatedData] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [arrowUp, setArrowUp] = useState(false);
    const navigate = useNavigate();
    const totalItems = claimData.length;
    const itemsPerPage = 5;
    const {currentPage, totalPages, startItem, endItem, nextPage, prevPage, currentData} = usePagination({
        totalItems,
        itemsPerPage,
    });
    const createColumnClassName = (field: string) => {
        if (field == 'name') {
            return 'table-col w-[25%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'createdBy') {
            return 'table-col w-[25%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'lastUpdate') {
            return 'table-col w-[25%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        if (field == 'action') {
            return 'table-col w-[25%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] !justify-evemly border-r-0 border-y py-3 px-6 border-t-0';
        }
    };

    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectAll(e.target.checked);
            if (e.target.checked) {
                console.log('hit all');

                // Select all items in the current page
                const currentPageIds = paginatedData.map((_, index) => index);
                setSelectedItems(currentPageIds);
            } else {
                // Deselect all items
                setSelectedItems([]);
            }
        },
        [paginatedData]
    );

    const handleSelectItem = useCallback(
        (index: number) => {
            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index];

                // Update selectAll state based on if all items in current page are selected
                setSelectAll(newSelected.length === paginatedData.length);

                return newSelected;
            });
        },
        [paginatedData]
    );

    const handlePrev = () => {
        prevPage();
        setPaginatedData(currentData(claimData));
    };
    const handleNext = () => {
        nextPage();
        setPaginatedData(currentData(claimData));
    };
    const handleSort = (type: string) => {
        // const enabledRows = taskViewRows.filter((row: any) => !row.isCardDisabled);
        // const disabledRows = taskViewRows.filter((row: any) => row.isCardDisabled);
        const sortedEnabledRows = [...paginatedData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[type].localeCompare(b[type]);
            } else {
                return b[type].localeCompare(a[type]);
            }
        });
        const finalSortedData = sortedEnabledRows;
        setPaginatedData(finalSortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setArrowUp(!arrowUp);
    };

    useEffect(() => {
        const newData = currentData(claimData);
        setPaginatedData(newData);
        setSelectedItems([]);
        setSelectAll(false);
    }, [currentPage]);

    return (
        <div className='w-full'>
            <div className='rounded-br-2xl overflow-hidden'>
                <div className='overflow-x-scroll bg-white'>
                    {/* Tabs Head */}
                    <div className='flex flex-nowrap sticky top-0 z-10 checkbox-alignment2'>
                        {claimColumns?.length > 0 &&
                            claimColumns.map((column: any, columnIndex: number) => (
                                <React.Fragment key={columnIndex}>
                                    {
                                        <div className={createColumnClassName(column.ref)}>
                                            {/* To manage sort icon and column width */}
                                            {column.name === 'Claim Chart Name' && (
                                                <span className=' font-interMedium text-sm text-[#4F4D55] flex items-center justify-normal gap-x-2 w-[100%]'>
                                                    <div className='relative inline-block'>
                                                        <input
                                                            type='checkbox'
                                                            id={'select-all-' + columnIndex}
                                                            name='checkbox-group'
                                                            checked={selectAll}
                                                            onChange={handleSelectAll}
                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                     checked:bg-indigo-600 checked:border-0 
                                                     transition-colors cursor-pointer'
                                                        />
                                                        <svg
                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                               inset-0 stroke-white mt-[1px] ml-[1px]'
                                                            viewBox='0 0 16 16'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'>
                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                        </svg>
                                                    </div>
                                                    {column.name}
                                                    <img className={`w-4 cursor-pointer transform ${sortOrder == 'asc' ? 'rotate-0' : 'rotate-180'}`} src={allImgPaths.arrowUp3} alt='' onClick={() => handleSort(column.ref)} />
                                                </span>
                                            )}

                                            {/* Column with no sort icon*/}
                                            {column.name !== 'Claim Chart Name' && <span className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</span>}
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                    </div>

                    {/* Body */}
                    {/* {isActiveColumn === 0 && patentData.length > 0 && patentData.map((data: any, columnIndex: number) => <div></div>)} */}
                    {true && (
                        <div className={'h-auto'}>
                            {paginatedData.length > 0 &&
                                paginatedData.map((row: any, rowIndex: number) => (
                                    <React.Fragment key={rowIndex}>
                                        <div className={`flex flex-nowrap`}>
                                            {claimColumns?.length > 0 &&
                                                claimColumns.map((column: any, columnIndex: number) => (
                                                    <React.Fragment key={columnIndex}>
                                                        {column.ref == 'name' && (
                                                            <div className={`${createColumnClassName(column.ref)} `}>
                                                                <span className='text-sm text-[#475467] flex items-center gap-x-2'>
                                                                    <div className='relative inline-block'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={'item' + rowIndex}
                                                                            name='checkbox-group'
                                                                            checked={selectedItems.includes(rowIndex)}
                                                                            onChange={() => handleSelectItem(rowIndex)}
                                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                        checked:bg-indigo-600 checked:border-0 
                                                                        transition-colors cursor-pointer'
                                                                        />
                                                                        <svg
                                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                          inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                            viewBox='0 0 16 16'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'>
                                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                                        </svg>
                                                                    </div>
                                                                    <span
                                                                        className='cursor-pointer  text-[14px] not-italic font-[550] leading-[20px] tracking-[-0.02px] text-[#1751D0] font-interBold'
                                                                        onClick={() => navigate('/project/3/claim-chart-details')}>
                                                                        {row[column.ref]}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )}
                                                        {column.ref == 'createdBy' && (
                                                            <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                <span className=' flex items-center gap-x-2 text-neutral-700 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                            </div>
                                                        )}
                                                        {column.ref == 'lastUpdate' && (
                                                            <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                <span className='text-neutral-900 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                            </div>
                                                        )}
                                                        {column.ref === 'action' && (
                                                            <div className={`${createColumnClassName(column.ref)} table-row `}>
                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.downloadIcon} alt='' />
                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.editIcon} alt='' />
                                                                <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.trashIcon} alt='' />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </React.Fragment>
                                ))}
                            {true && claimData.length === 0 && <div className='h-[calc(100vh_-_334px)]'>No data available</div>}
                        </div>
                    )}
                </div>
            </div>
            {/* Paginate */}
            <div className={`flex items-center justify-between w-full p-[20px]`}>
                {
                    <div className='text-sm text-gray-600'>
                        Showing <b>{startItem}</b> to <b>{endItem}</b> of <b>{totalItems}</b> Results
                    </div>
                }

                <div className='flex items-center gap-2'>
                    <button onClick={handlePrev} disabled={currentPage === 1} className={`px-4 py-2 text-sm rounded border ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                        Previous
                    </button>

                    <button
                        onClick={handleNext}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 text-sm rounded border ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-gray-50'}`}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
export default ClaimTable;
