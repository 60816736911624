import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createTeamService, getProfileImage, uploadFileToS3 } from "../../services/auth/auth";
import { teamSetup } from "../../schema/validation";
import { regex } from "../../utils/regex";
import { TeamField } from "../../types";
import {
  FILE_MAX_SIZE,
  FILE_SIZE_TEXT,
  FILE_TYPE_TEXT,
  SOMETHING_WENT_WRONG,
  teamNameLengthText,
  teamValidation,
} from "../../utils/constant";
import { nameValidation } from "../../utils/function";
import { selectTeam } from "../../services/user";
import { generateTeamPresignedUrl, updateTeamDetails } from "services/team";

const useTeamSetup = () => {
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState("");
  const [emailText, setEmailText] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [fileData, setFileData] = useState({ file_name: "", content_type: "", category: "" });
  const [imageUrl, setImageUrl] = useState<any>("");
  const [imageValidation, setImageValidation] = useState("");
  const [emailValidation, setEmailValidation] = useState<string | null>(null);
  const [teamNameValidation, setTeamNameValidation] = useState<string | null>(
    null
  );
  const initialMessageDetails = { success: "", error: "" }
  const [msg, setMsg] = useState(initialMessageDetails);
  const [teamFile, setTeamFile] = useState<any>(null);

  const userEmail = localStorage.getItem("userEmail") as string;

  const resetStateToDefaults = () => {
    setTeamName("");
    setEmailText("");
    setEmails([]);
    setEmailValidation(null);
    setTeamNameValidation(null);
    setMsg(initialMessageDetails);
  }

  const handleFileChange = async (event: any) => {
    const fileData = event.target.files[0];
    setTeamFile(fileData)
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (!allowedTypes.includes(fileData.type)) {
      setImageValidation(FILE_TYPE_TEXT)
    } else if (fileData.size > FILE_MAX_SIZE) {
      setImageValidation(FILE_SIZE_TEXT)
    } else {
      setImageValidation("");
      try {
        setFileData({ file_name: fileData.name, content_type: fileData.type, category: 'team-logo' })
        
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log({ res: reader.result })
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(fileData);

      } catch (error) {
        console.log('Error while generating the presigned url =', error);
      }
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTeamName(value);
    if (name === "name") {
      const isValid = nameValidation(value);
      if (!isValid) {
        setTeamNameValidation(teamValidation.teamNameValidation)
      } else if (teamName.length > 50) {
        setTeamNameValidation(teamNameLengthText)
      } else {
        setTeamNameValidation(null);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedText = e.target.value.trim();
    if (trimmedText === "," || trimmedText.includes(',')) {
      return;
    } else {
      setEmailText(trimmedText);
    }

    if (!trimmedText) {
      setEmailValidation(null);
    } else {
      if (!regex.email.test(trimmedText)) {
        setEmailValidation(teamValidation.emailValidationText);
      } else if (userEmail === trimmedText) {
        setEmailValidation(teamValidation.inviteSelfEmailText);
      } else if (emails.includes(trimmedText)) {
        setEmailValidation(teamValidation.emailExistText);
      } else {
        setEmailValidation(null);
      }
    }
  };

  const handleAddEmail = () => {
    if (!emailValidation) {
      if (emailText) {
        setEmails([...emails, emailText]);
        setEmailText("");
        setEmailValidation(null);
      }
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (emailValidation) return;
    teamSetup
      .validate({ teamName, emails })
      .then((data: any) => {
        const payload = {
          team_name: data.teamName,
          emails: data.emails,
          ...(fileData && { logo: fileData.file_name })
        };
        createTeam(payload);
      })
      .catch((err: any) => {
        if (err.path === "teamName") {
          setTeamNameValidation(err.message);
        } else if (err.path === "emails") {
          setEmailValidation(err.message);
        } else {
          setEmailValidation(null);
          setTeamNameValidation(null);
        }
      });
  };

  const createTeam = async (payload: TeamField) => {
    try {
      const teamPayload = payload;
      const response = await createTeamService(payload);

      if (response) {
        await createTeamLogo(response?.data?.teamId, teamPayload)
        const selectedTeamRes: any = await selectTeam(response?.data?.teamId);
        const payload = {
          currentRole: selectedTeamRes.role.name,
          currentTeamId: selectedTeamRes.teamId
        }
        localStorage.setItem('selectedTeam', JSON.stringify(payload));
        localStorage.setItem('add_new_team', "true");
        resetStateToDefaults();
        navigate("/");
      } else {
        setMsg({ ...msg, error: SOMETHING_WENT_WRONG });
      }
    } catch (error: any) {
      console.log("Error while creating team =", error);
      setMsg({ ...msg, error: error.message });
    }
  };

  const createTeamLogo = async (teamId: any, teamPayload: any) => {
    try {
      const { data } = await generateTeamPresignedUrl({ file_name: fileData?.file_name, content_type: fileData?.content_type, category: 'team_profile', team_id: teamId });

      if (data) {
        const payload = {
          url: data.url,
          file: teamFile,
          type: fileData.content_type
        }

        await uploadFileToS3(payload);

        await updateTeamDetails({
          logo: data?.fileName,
          team_id: teamId,
          name: teamPayload?.team_name
        });
      }
    } catch (error) {
      console.log('Error while generating the presigned url =', error);
      return null
    }
  }

  const handleSkip = () => {
    if (!teamName) {
      setTeamNameValidation("Please Enter Team Name");
    } else {
      // skip API call
      createTeam({ team_name: teamName });
      resetStateToDefaults();
    }
  };

  const handleDelete = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
    setEmailValidation(null);
  };

  return {
    handleInputChange,
    handleFormSubmit,
    handleChange,
    handleFileChange,
    handleAddEmail,
    handleDelete,
    handleSkip,
    teamNameValidation,
    userEmail,
    teamName,
    emailText,
    emailValidation,
    emails,
    imageUrl,
    imageValidation,
    msg,
  };
};
export default useTeamSetup;
