import { axiosInstance } from "./axios";
import { TeamMemberListRequestPayload, UpdateTeamFields, otherTeamMemberType, removeUserType, transferOwnerShipPayloadType } from "../types";


export const getOtherTeamMemberList = async (payload: otherTeamMemberType) => {
  try {
    return await axiosInstance.post(`/team/other-members`, payload);
  } catch (error) {
    throw error;
  }
};

export const removeTeamMember = async (payload: removeUserType) => {
  try {
    return await axiosInstance.put(`/team/remove-user`, payload);
  } catch (error) {
    throw error;
  }
};

export const transferOwnerShip = async (payload: transferOwnerShipPayloadType) => {
  try {
    return await axiosInstance.put(`/team/transfer-ownership`, payload);
  } catch (error) {
    throw error;
  }
}

export const generateTeamPresignedUrl = async (fileData: any) => {
  try {
    return await axiosInstance.post('team/create-presigned-url', fileData);
  } catch (error) {
    throw error;
  }
}

export const getTeamLogo = async (payload: any) => {
  try {
    return await axiosInstance.post(`/team/get-team-logo`, payload);
  } catch (error) {
    throw error;
  }
}

export const updateTeamDetails = async (data: UpdateTeamFields) => {
  try {
    return await axiosInstance.put(`team/update-team`, data);
  } catch (error) {
    throw error;
  }
};

export const getUserTeams = async () => {
  try {
    return await axiosInstance.get(`team/get-teams`);
  } catch (error) {
    throw error;
  }
}

export const getTeamMembers = async (data: TeamMemberListRequestPayload) => {
  try {
    return await axiosInstance.post(`team/team-members`, data);
  } catch (error) {
    throw error;
  }
};